<!-- MAIN GIFT CARD OPTIONS SCREEN -->
<div *ngIf="currentView === 'main'" class="gift-card-options-container">
    <div class="title-container">
        <div class="gift-card-title">{{ title }}</div>
        <span class="nown-icon icon-Exit-Large" (click)="close()"></span>
    </div>
    <div class="vertical-spacing-12"></div>
    <div *ngIf="digitalGiftCardEnabled" class="gift-card-btn grey-button" (click)="switchView('digital')" style="display: flex;align-items: center;justify-content: center; text-align: center;">
        {{ 'giftCard.addeGiftCard' | translate }}
    </div>
    <div class="vertical-spacing-12"></div>
    <div class="gift-card-btn grey-button" (click)="switchView('physical')">
        {{ 'giftCard.addPhysicalGiftCard' | translate }}
    </div>
</div>

<!-- ADDING DIGITAL GIFT CARDS -->
<div *ngIf="currentView === 'digital'" class="gift-card-options-container">
    <div class="title-container">
        <div class="gift-card-title">{{ title }}</div>
        <span class="nown-icon icon-Exit-Large" (click)="close()"></span>
    </div>
    <div class="gift-card-description">
        {{ description[subView] }}
    </div>
    <!-- step 1 of digital gift card claiming process: inputting the email that the card was originally sent to -->
    <div *ngIf="subView === 'digital__step_1-email-input'" class="digital-gift-claim">
        <div class="input-container">
            <div class="input-title">
                {{ 'giftCard.emailAddress' | translate }}
            </div>
            <div class="vertical-spacing-12"></div>
            <input type="text" [(ngModel)]="recipientEmail" class="input-box">
        </div>
        <div class="vertical-spacing-12"></div>
        <div class="btn-row">
            <div class="gift-card-btn grey-button" (click)="switchView('main')">
                <span class="nown-icon icon-Arrow-Small-Left"></span>
                {{ 'giftCard.back' | translate }}
            </div>
            <div class="gift-card-btn yellow-button" (click)="sendSecurityCode(recipientEmail)">{{ 'giftCard.continue' | translate }}</div>
        </div>
    </div>

    <!-- step 2 of digital gift card claiming process: inputting security code sent to the email above -->
    <div *ngIf="subView === 'digital__step_2-code-input'" class="digital-gift-claim">
        <div class="input-container">
            <div class="input-title">
                {{ 'giftCard.securityCode' | translate }}
            </div>
            <div class="vertical-spacing-12"></div>
            <input type="text" [(ngModel)]="securityCode" class="input-box">
        </div>
        <div class="vertical-spacing-12"></div>
        <div class="btn-row">
            <div class="gift-card-btn grey-button" (click)="switchSubView('digital__step_1-email-input')">
                <span class="nown-icon icon-Arrow-Small-Left"></span>
                {{ 'giftCard.back' | translate }}
            </div>
            <div class="gift-card-btn yellow-button" (click)="validateGiftCard()">{{ 'giftCard.continue' | translate }}</div>
        </div>
    </div>

    <!-- step 3 of digital gift card claiming process: fetching list of cards sent to this email and prepping for claiming -->
    <div *ngIf="subView === 'digital__step_3-select-cards'" class="digital-gift-claim">
        <div class="vertical-spacing-12"></div>
        <div class="claimable-cards-container">
            <div class="select-all-container card-info-row">
                <input type="checkbox" class="card-checkbox" [(ngModel)]="selectedAll" (change)="selectAll(selectedAll)">
                <div class="select-all-text">{{ 'giftCard.selectAll' | translate }}</div>
            </div>
            <div class="scrollable-container">
                <div class="fade-top"></div>
                <div class="claimable-card" *ngFor="let card of claimableCards">
                    <div class="card-info-row">
                        <input type="checkbox" name="claimable-card" [(ngModel)]="card.selected" (change)="selectCard(card.selected)" class="card-checkbox">
                        <img [src]="card.image_url" alt="" class="claimable-card-img">
                        <div class="card-info-container">
                            <div class="card-info-text card-info-balance">
                                ${{ (card.amount_cents / 100).toFixed(2) }}
                            </div>
                            <div class="card-info-text">
                                {{ 'giftCard.from' | translate }}: {{ card.sender_name }}
                            </div>
                        </div>
                    </div>
                    <div class="card-info-row">
                        <div *ngIf="card.message" class="card-info-text card-info-message">
                            "{{ card.message }}"
                        </div>
                    </div>
                </div>
                <div class="fade-bottom"></div>
            </div>
        </div>
        <div class="vertical-spacing-12"></div>
        <div class="btn-row">
            <div class="gift-card-btn grey-button" (click)="switchSubView('digital__step_1-email-input')">
                <span class="nown-icon icon-Arrow-Small-Left"></span>
                {{ 'giftCard.back' | translate }}
            </div>
            <div class="gift-card-btn yellow-button" (click)="switchSubView('digital__step_4-claim-cards')">
                {{ 'giftCard.continue' | translate }}
            </div>
        </div>
    </div>

    <!-- step 4 of digital gift card claiming process: legal docs to be accepted -->
    <div *ngIf="subView === 'digital__step_4-claim-cards'" class="digital-gift-claim" >
        <div class="legal-container">
            <div class="legal-title">
                {{ 'giftCard.termsAndConditions' | translate }}
            </div>
            <div class="fade-top"></div>
            <div class="legal-text">
                {{ 'giftCardTerms.part1' | translate }} 
                {{ 'giftCardTerms.part2' | translate }} {{ this.brandName }}. 
                {{ 'giftCardTerms.part3' | translate }} 
                {{ 'giftCardTerms.part4' | translate }} 
                {{ 'giftCardTerms.part5' | translate }} 
                {{ 'giftCardTerms.part6' | translate }} {{ this.brandName }} {{ 'giftCardTerms.part7' | translate }}
                {{ 'giftCardTerms.part8' | translate }}<br><br>
                {{ 'giftCardTerms.part9' | translate }} {{ (this.appFid === 'nown' || !this.appFid) ? 'Nōwn' : this.brandName }} {{ 'giftCardTerms.part10' | translate }}<br><br>
                {{ 'giftCardTerms.part11' | translate }}
            </div>
            <div class="fade-bottom"></div>
        </div>
        <div class="vertical-spacing-12"></div>
        <div class="btn-row">
            <div class="gift-card-btn grey-button" (click)="switchSubView('digital__step_3-select-cards')">
                <span class="nown-icon icon-Arrow-Small-Left"></span>
                {{ 'giftCard.back' | translate }}
            </div>
            <div *ngIf="!isClaimingDigitalGift" class="gift-card-btn yellow-button" (click)="claimCards()">
                {{ 'giftCard.accept' | translate }}
            </div>
            <div *ngIf="isClaimingDigitalGift" class="gift-card-btn yellow-button"><span class="animate-spin nown-icon icon-Spinner-Small"></span></div>
        </div>
    </div>
</div>

<!-- ADDING PHYSICAL GIFT CARDS -->
<div *ngIf="currentView === 'physical'" class="gift-card-options-container">
    <div class="title-container">
        <div class="gift-card-title">{{ title }}</div>
        <span class="nown-icon icon-Exit-Large" (click)="close()"></span>
    </div>
    <div class="vertical-spacing-12"></div>
    <div class="physical-gift-card-inputs">
        <div class="input-container">
            <div class="input-title">
                {{ 'giftCard.cardNumber' | translate }}
            </div>
            <div class="vertical-spacing-12"></div>
            <input type="text" [(ngModel)]="giftCardNum" class="input-box">
        </div>
        <div class="vertical-spacing-12"></div>
        <div class="input-container">
            <div class="input-title">
                PIN
            </div>
            <div class="vertical-spacing-12"></div>
            <input type="text" [(ngModel)]="pincode" class="input-box">
        </div>
        <div class="vertical-spacing-12"></div>
        <div class="btn-row">
            <div class="gift-card-btn grey-button" (click)="switchView('main')">
                <span class="nown-icon icon-Arrow-Small-Left"></span>
                {{ 'giftCard.back' | translate }}
            </div>
            <div *ngIf="!isClaimingDigitalGift" class="gift-card-btn yellow-button" (click)="validateGiftCard()">
                {{ 'giftCard.continue' | translate }}
            </div>
            <div *ngIf="isClaimingDigitalGift" class="gift-card-btn yellow-button"><span class="animate-spin nown-icon icon-Spinner-Small"></span></div>
        </div>
    </div>
</div>