import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { OrderService } from 'src/app/services/order/order.service';
import { CheckOutComponent } from '../check-out/check-out.component';
import { OrderTrackingService } from 'src/app/services/order-tracking/order-tracking.service';


@Component({
  selector: 'app-item-modifiers',
  templateUrl: './item-modifiers.component.html',
  styleUrls: ['./item-modifiers.component.less']
})
export class ItemModifiersComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  menuId: string;
  public onClose: Subject<any> = new Subject();
  item: any;
  selectedItems: any[] = new Array<any>();
  addedToOrder = false;
  itemTotal: number;
  remainingChoices = 0;
  location: any;
  brand: any;
  isEdit: boolean;
  modifierElements: any[] = new Array<any>();
  selectedModifierId: string;
  selectorElement: any;
  finishedLoadingModTabChildren = false;
  quantity: number = 1;
  effectiveOverrideLimit: number | null = null;

  constructor(public modalRef: BsModalRef,
              public orderService: OrderService,
              private modalService: BsModalService,
              public orderTrackingService: OrderTrackingService) { }

  ngOnInit(): void {
    this.itemTotal = this.item.amount_cents;
    this.checkForExistingSelections();
    this.calculateTotal();
    this.calculateRemainingChoices();
    this.item.note = "";
    this.quantity = this.item.quantity || 1;
  }

  ngAfterViewInit(): void {
    this.item.children.forEach((modifier) => {
      const modifierElement = document.getElementById(modifier.menu_id);
      this.modifierElements.push(modifierElement);
    });
    this.selectModifier(this.item.children[0]);
    this.selectorElement = document.getElementById('modifierSelector');
  }

  ngAfterViewChecked(): void {
    // Once the children of the first modifier tab finish loading, we don't need to do anything here anymore.
    if (this.finishedLoadingModTabChildren) {
      return;
    }
    // Highlights the first modifier automatically
    const firstModifierTab = document.getElementById(`${this.item.children[0].menu_id}-tab`);
    if (firstModifierTab.offsetWidth > 0) {
      this.selectorElement.style.width = firstModifierTab.offsetWidth + 'px';
      this.selectorElement.style.left = firstModifierTab.offsetLeft + 'px';
      this.finishedLoadingModTabChildren = true;
    }
  }

  ngOnDestroy(): void {
  }

  onModifierScroll(e): void {
    // Works alongside a snap-on scroll type. If the scroll offset gets within the area
    // of a modifier tile, it will automatically snap onto the tile and highlight the corresponding
    // modifier selector tab.
    this.modifierElements.forEach((modifierElement) => {
      if (e.target.scrollLeft < (modifierElement.offsetLeft + (modifierElement.offsetWidth / 2))
        && e.target.scrollLeft > (modifierElement.offsetLeft - (modifierElement.offsetWidth / 2))) {
          if (this.selectedModifierId !== modifierElement.id) {
            this.selectedModifierId = modifierElement.id;
            const correspondingTab = document.getElementById(`${modifierElement.id}-tab`);
            this.selectorElement.style.width = correspondingTab.offsetWidth + 'px';
            this.selectorElement.style.left = correspondingTab.offsetLeft + 'px';
          }
      }
    });
  }

  checkForExistingSelections(): void {
    this.item.children.forEach((modifier) => {
      modifier.children.forEach((option) => {
        if (option.selected) {
          this.selectedItems.push(option);
        }
      });
    });
  }

  selectModifier(modifier: any): void {
    this.item.children.forEach((mod) => mod.selected = false);
    this.selectedModifierId = modifier.menu_id;
    const selectedModifier = document.getElementById(modifier.menu_id);
    selectedModifier.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }


  toNextModifer(modifier: any, modIndex: number): void {
    // if current modifier category is not the last.
    if (modIndex < this.item.children.length - 1) {

      let type = modifier.selection;
      let count = modifier.selectedCount;

      // single selection modifier categories can move to the next modifier page if selected count === min selection
      // multiple selection modifier categories should move to next page only if
      // 1. selected count === max selection 
      // OR 
      // 2. selected count === the maximum amount of modifiers selected on the page.
      // (one of pitaland's pages has max as 10, but only 9 are available to select)
      // otherwise, it should stay on the page.

      let isSingleSelectionMet = (
        type === 'single' && count === modifier.minSelection
      );
      let isMultipleSelectionMet = (
        type === 'multiple' && (count === modifier.maxSelection || count === modifier.children.length || modifier.selectedCount >= this.effectiveOverrideLimit)
      );

      // only move on to next modifier page if either condition is met.
      if (isSingleSelectionMet || isMultipleSelectionMet) {
        this.selectModifier(this.item.children[modIndex + 1]);
      }
    }
  };

  // Recalculate the effective override limit based on all selected items 
  recalculateEffectiveOverrideLimit(): void {
    let newLimit = Number.POSITIVE_INFINITY;

    // Find the smallest maxSelectionOverride among selected override items where selectionOverideEnabled is true
    this.selectedItems.forEach(item => {
      if (item.original?.maxSelectionOverride > -1) {
        newLimit = Math.min(newLimit, item.original.maxSelectionOverride);
      }
    });
    
    this.effectiveOverrideLimit = Number.isFinite(newLimit) ? newLimit : null;

  }

   // This method might be called after recalculateEffectiveOverrideLimit in toggleModifierOption
   updateModifierSelectionUI(): void {
    this.item.children.forEach(modifier => {
      if (modifier.selection === 'multiple' && modifier.selectionOverrideEnabled) {
        const selectedCount = modifier.children.filter(child => child.selected).length;
        // Check against the effective override limit only if it is set
        if (this.effectiveOverrideLimit !== null && selectedCount > this.effectiveOverrideLimit) {
          modifier.children.forEach(option => {
            option.selected = false;
          });
          modifier.selectedCount = 0;
        }
      }
    });
  }

  toggleModifierOption(selectedOption: any, modifier: any, modIndex: number, event: Event): void {
    event.stopPropagation();

    if (modifier.selection === 'single') {
      modifier.selectedCount = 0;
      modifier.children.forEach((option) => {
        if (option.selected && option.nown_menu_id !== selectedOption.nown_menu_id) {
          option.selected = false;
          this.selectedItems.forEach((selectedModifier, index) => {
            if (selectedModifier.nown_menu_id === option.nown_menu_id) {
              this.selectedItems.splice(index, 1);
            }
          });
        }
      });
    }
    
    if (!selectedOption.selected && modifier.selectedCount === modifier.maxSelection) {
      return;
    }

    // Check against the effective override limit only if it is set
    if (!selectedOption.selected 
      && modifier.selectionOverrideEnabled 
      && modifier.selection === 'multiple' 
      && this.effectiveOverrideLimit !== null // Add this check
      && modifier.selectedCount >= this.effectiveOverrideLimit) {
    return;
  }
      
    selectedOption.note = this.item.note;
    selectedOption.selected = !selectedOption.selected;

    if (selectedOption.selected) {
      this.selectedItems.push(selectedOption);
      if (!modifier.selectedCount) {
        modifier.selectedCount = 0;
      }
      modifier.selectedCount++;
      this.toNextModifer(modifier, modIndex);
    } else {
      this.selectedItems.forEach((selectedModifier, index) => {
        if (selectedModifier.nown_menu_id === selectedOption.nown_menu_id) {
          this.selectedItems.splice(index, 1);
        }
      });
      modifier.selectedCount--;
    }
    
    if (modifier.minSelection > 0) {
      this.recalculateEffectiveOverrideLimit();       
      this.updateModifierSelectionUI();
    }
    this.calculateTotal();
    this.calculateRemainingChoices();
  }

  increaseQuantity(): void {
    this.quantity += 1;
    this.item.quantity = this.quantity;
    this.calculateTotal();
  }

  decreaseQuantity(): void {
    if (this.quantity > 1) {
      this.quantity -= 1;
      this.item.quantity = this.quantity;
      this.calculateTotal();
    }
  }

  addToOrder(): void {
    if (this.addedToOrder) {
      return;
    }
    let scrolledToModifier = false;
    let isMissingRequiredModifier = false;
    for (const modifier of this.item.children) {
      if (modifier.minSelection > 0) {
        let selectedCount = 0;
        for (const child of  modifier.children) {
          if (child.selected) {
            selectedCount += 1;
          }
        }
        if (selectedCount < modifier.minSelection) {
          isMissingRequiredModifier = true;
          const requiredModifierTile = document.getElementById(`${modifier.menu_id}-tile`);
          requiredModifierTile.style.border = '1px solid red';
          setTimeout(() => requiredModifierTile.style.border = '1px solid transparent', 700);
          if (!scrolledToModifier) {
            this.selectModifier(modifier);
            scrolledToModifier = true;
          }
        }
      }
    }
    if (isMissingRequiredModifier) {
      return;
    }
    this.addedToOrder = true;
    const newItem = this.cleanItem();
    this.modalRef.hide();
    this.onClose.next(newItem);
    // this.onClose.next(this.item);
  }

  cleanItem(): any {
    const newItem = JSON.parse(JSON.stringify(this.item));
    newItem.children.forEach((modifier) => delete modifier.selected);
    return newItem;
  }

  viewOrder(): void {
    const initialState = {
      location: this.location,
      brand: this.brand
    };
    this.modalService.show(CheckOutComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'check-out-modal'
    });
  }

  calculateTotal(): void {
    if (this.item.price_replaced_by_id_v2) {
      this.itemTotal = 0;
    } else {
      this.itemTotal = this.item.amount_cents;
    }
    this.item.children.forEach((modifier) => {
      modifier.children.forEach((option) => {
        if (option.selected && option.amount_cents) {
          this.itemTotal += option.amount_cents;
        }
      });
    });
    // Multiply the subtotal by the quantity to get the final item total 
    this.itemTotal = this.itemTotal * this.quantity;
  }

  calculateRemainingChoices(): void {
    this.remainingChoices = 0;
    this.item.children.forEach((modifier) => {
      if (modifier.minSelection > 0) {
        this.remainingChoices += modifier.minSelection;
        modifier.children.forEach((option) => {
          if (option.selected) {
            this.remainingChoices--;
          }
        });
      }
    });
  }

  close() {
    this.modalRef.hide();
    this.onClose.next();
  }
}