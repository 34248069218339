<div id="cartView" *ngIf="currentView === 'cart'">
    <div class="check-out-header">
        <div class="full-vw">
            <div class="global-max-width check-out-header-content">
                <div class="left-aligned" (click)="back()">
                    <span class="nown-icon icon-Arrow-Small-Left left-arrow-icon"></span>
                </div>
                <div class="heading">{{ 'checkOut.heresYourOrder' | translate }}</div>
            </div>
        </div>
    </div>
    <div class="full-vw">
        <div class="check-out-content global-max-width">
            <div class="check-out-content-tile order-status-tile" *ngIf="orderTrackingService.isOrderInProgress() || sentPreorder">
                <div class="order-status-container float-item-fit">
                    <div *ngIf="orderTrackingService.getPreorderStatus().status !== 'cancelled'">
                        <div class="progress-bar">
                            <div class="progress-bar-inner" [style.width]="(orderTrackingService.getPreorderStatus().progressIndicatorValue * 100) + '%'"></div>
                        </div>
                    </div>
                    <div class="vertical-spacing-12" *ngIf="orderTrackingService.getPreorderStatus().status !== 'cancelled'"></div>
                    <div class="progress-bar-labels" *ngIf="orderTrackingService.getPreorderStatus().status !== 'cancelled'">
                        <div class="progress-bar-label">
                            {{ 'checkOut.received' | translate }}
                        </div>
                        <div class="progress-bar-label">
                            {{ 'checkOut.preparing' | translate }}
                        </div>
                        <div class="progress-bar-label" *ngIf="orderTrackingService.getPreorderStatus().status === 'acknowledged'">
                            {{ 'checkOut.readyAt' | translate }} {{ orderTrackingService.getEarliestPickupTime() }}
                        </div>
                        <div class="progress-bar-label" *ngIf="orderTrackingService.getPreorderStatus().status != 'acknowledged'">
                            {{ 'checkOut.completed' | translate }}
                        </div>
                    </div>
                    <div class="vertical-spacing-12" *ngIf="orderTrackingService.getPreorderStatus().status !== 'cancelled'"></div>
                    <div class="order-status-text">{{ orderTrackingService.getPreorderStatus().userFriendlyStatus }}</div>
                    <div *ngIf="orderTrackingService.getPreorderId() 
                    && orderTrackingService.getPreorderStatus().status !== 'completed'
                    && orderTrackingService.getPreorderStatus().status !== 'ready'
                    && orderTrackingService.getPreorderStatus().status !== 'cancelled'">
                        <div class="vertical-spacing-12"></div>
                        <div class="grey-button" (click)="cancelOrder()" *ngIf="orderTrackingService.getPreorderStatus().status === 'pending'">
                            {{ 'checkOut.cancelOrder' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="vertical-spacing-12"></div>
            <div class="grid">
              <!-- on tablet/desktop, this will be a 2-column grid layout -->
              <!-- grid-tile-left-column class indicates the tile will show on the left column -->
              <!-- grid-tile-right-column class indicates the tile will show on the right column -->
              <!-- on mobile devices, the following tiles should show in the order shown below -->

                <!-- Cart Confirmation Tile -->
                <div class="check-out-content-tile grid-tile grid-tile-left-column" *ngIf="!orderTrackingService.isOrderInProgress() && cart.length">
                    <div class="receipt-items-container float-item-fit">
                        <div *ngFor="let item of cart; let i = index" (click)="editItem(item, i)">
                            <div class="receipt-item-row">
                                <div class="remove-from-cart-button-container">
                                    <span class="nown-icon icon-Exit-Large cross-icon" (click)="removeFromCart(i)"></span>
                                </div>
                                <div *ngIf="!isPurchasingGiftCard" class="receipt-item-names">
                                    <div class="item-name">
                                        <div class="item-quantity-container" *ngIf="item.quantity && item.quantity > 1">{{ item.quantity + 'x ' }}</div>
                                        <div class="item-name-container">{{ item.name }}</div>
                                    </div>
                                    <div class="receipt-item-modifier-option" *ngFor="let modifier of item.children">
                                        <div class="modifier-option-name" *ngFor="let modifierOption of modifier.children">
                                            <div *ngIf="modifierOption.selected">
                                                {{ modifierOption.name }}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.note.length" class="receipt-item-modifier-option">
                                        <div class="modifier-option-name">
                                            Note: {{ item.note }}
                                        </div> 
                                    </div>
                                </div>
                                <div *ngIf="isPurchasingGiftCard" class="receipt-item-names">
                                    <div class="item-name">{{ item.name }}</div>
                                    <div *ngIf="item.personal_message" class="receipt-gift-card-message">
                                        {{ item.personal_message }}
                                    </div>
                                </div>
                                <div class="receipt-item-subtotal">
                                    ${{ (itemTotals[i].subtotal / 100).toFixed(2) }}
                                </div>
                            </div>
                        </div>
                        <div class="totals-container">
                            <div class="subtotal-and-tax">
                                <div [hidden]="!itemTotals.allTotals.discount">{{ 'checkOut.discount' | translate }}: ${{ (itemTotals.allTotals.discount / 100).toFixed(2) }}</div>
                                <div>{{ 'checkOut.subtotal' | translate }}: ${{ (itemTotals.allTotals.subtotal / 100).toFixed(2) }}</div>
                                <div>{{ 'checkOut.tax' | translate }}: ${{ (itemTotals.allTotals.tax / 100).toFixed(2) }}</div>
                                <div [hidden]="!itemTotals.allTotals.tip">{{ 'checkOut.tip' | translate }}: ${{ (itemTotals.allTotals.tip / 100).toFixed(2) }}</div>
                            </div>
                            <div class="horizontal-spacing-16"></div>
                            <div class="total">
                                {{ 'checkOut.total' | translate }}: ${{ (itemTotals.allTotals.total / 100).toFixed(2) }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Order Info Tile (pickup time & location) -->
                <div class=" check-out-content-tile order-info-container grid-tile grid-tile-right-column" *ngIf="location">
                    <div class="float-item-fit">
                        <div class="heading">
                            <div class="order-time" *ngIf="!orderTrackingService.isOrderInProgress() && !sentPreorder && !sentGiftCard">
                              <span>{{ 'checkOut.pickupTime' | translate }}:</span>
                              <select class="scheduled-order-time" [(ngModel)]="scheduledOrderTime">
                                <option *ngFor="let time of availableTimes" [ngValue]="time">{{ time.timestring }}</option>
                              </select>
                            </div>
                            <div *ngIf="orderTrackingService.isOrderInProgress()">
                                <div class="target-timestamp"
                                *ngIf="orderTrackingService.getPreorderStatus().status === 'print_pending'
                                || orderTrackingService.getPreorderStatus().status === 'pending'">
                                {{ 'checkOut.orderedForPickupAt' | translate }} {{ orderTrackingService.getEarliestPickupTime() }}
                                </div>
                            </div>
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <div>{{ location.node_name }}</div>
                        <div style="font-weight: 300;">{{ location.street_address }}</div>
                        <div class="vertical-spacing-12"></div>
                        <div class="check-out-map-container">
                            <agm-map
                                [mapDraggable]="false"
                                [showDefaultInfoWindow]="false"
                                [streetViewControl]="false"
                                [latitude]="location.latitude"
                                [longitude]="location.longitude"
                                [zoom]="15">
                                <agm-marker [latitude]="location.latitude" [longitude]="location.longitude" [markerDraggable]="false"></agm-marker>
                            </agm-map>
                        </div>
                        <div *ngIf="locationAddressForMaps">
                            <div class="vertical-spacing-12"></div>
                            <a class="grey-button" href="https://google.ca/maps/dir//{{locationAddressForMaps}}" target="_blank">
                                {{ 'checkOut.getDirections' | translate }}
                            </a>
                        </div>
                    </div>
                </div>

                <!-- Gift Card Confirmed Tile -->
                <div class=" gift-card-confirmation grid-tile grid-tile-right-column" *ngIf="sentGiftCard">
                    <div class="float-item-fit">
                        <div class="vertical-spacing-12"></div>
                        <img class="green-checkmark" src="../../../../../assets/images/green-checkmark.jpg" alt="">
                        <div class="vertical-spacing-12"></div>
                        <div class="confirmation-title">{{ 'checkOut.orderConfirmed' | translate }}</div>
                        <div class="vertical-spacing-12"></div>
                        <div class="confirmation-description">
                            {{ giftCardPackage.recipient_name }} {{ 'checkOut.willReceiveOrder' | translate }}
                        </div>
                    </div>
                </div>

                <!-- Receipt Tile -->
                <div class="check-out-content-tile grid-tile grid-tile-left-column" *ngIf="orderTrackingService.isOrderInProgress() || sentPreorder || sentGiftCard">
                    <div class="receipt-items-container float-item-fit">
                        <div *ngFor="let item of orderTrackingService.getOrderItems(); let i = index">
                            <div class="receipt-item-row">
                                <div class="receipt-item-names">
                                    <div class="item-name">{{ item.name }}</div>
                                    <div class="receipt-item-modifier-option" *ngFor="let modifier of item.children">
                                        <div class="modifier-option-name" *ngFor="let modifierOption of modifier.children">
                                            {{ modifierOption.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="receipt-item-subtotal">
                                    ${{ (itemTotals[i].subtotal / 100).toFixed(2) }}
                                </div>
                            </div>
                        </div>
                        <div class="totals-container">
                            <div class="subtotal-and-tax">
                                <div [hidden]="!(itemTotals.allTotals.discount > 0)">{{ 'checkOut.discount' | translate }}: ${{ (itemTotals.allTotals.discount / 100).toFixed(2) }}</div>
                                <div>{{ 'checkOut.subtotal' | translate }}: ${{ (itemTotals.allTotals.subtotal / 100).toFixed(2) }}</div>
                                <div>{{ 'checkOut.tax' | translate }}: ${{ (itemTotals.allTotals.tax / 100).toFixed(2) }}</div>
                                <div [hidden]="!itemTotals.allTotals.tip">{{ 'checkOut.tip' | translate }}: ${{ (itemTotals.allTotals.tip / 100).toFixed(2) }}</div>
                            </div>
                            <div class="horizontal-spacing-16"></div>
                            <div class="total">
                                {{ 'checkOut.total' | translate }} ${{ (itemTotals.allTotals.total / 100).toFixed(2) }}
                            </div>
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <!-- e.g. Paid with 4242****4242, Gift Card -->
                        <div class="tender-details" *ngIf="maskedTenderCardNum || redeemGiftCard">
                            <span *ngIf="orderTrackingService.getPreorderStatus().status !== 'cancelled'">
                                {{ 'checkOut.paidWith' | translate }}
                            </span>
                            <span *ngIf="orderTrackingService.getPreorderStatus().status === 'cancelled'">
                                {{ 'checkOut.cancelled' | translate }}
                            </span>
                            <span class="side-note" *ngIf="maskedTenderCardNum && !isGiftCardOnly">
                                {{ maskedTenderCardNum }}
                            </span>
                            <span class="side-note" *ngIf="redeemGiftCard && maskedTenderCardNum && !isGiftCardOnly">
                                , {{ 'checkOut.giftCard' | translate }}
                            </span>
                            <span *ngIf="redeemGiftCard && !(maskedTenderCardNum && !isGiftCardOnly)">
                                {{ 'checkOut.giftCard' | translate }}
                            </span>
                        </div>
                        <div class="vertical-spacing-12"></div>
                    </div>
                </div>

                <!-- Tip + Phone Number (non GC orders only) -->
                <div class="check-out-content-tile grid-tile grid-tile-left-column" *ngIf="!orderTrackingService.isOrderInProgress() && cart.length && !isPurchasingGiftCard">
                    <div class="add-tip-container float-item-fit">
                        <div class="heading">
                            {{ 'checkOut.addATip' | translate }}
                        </div>
                        <div class="tip-options-container">
                            <div class="tip-option-column">
                                <div class="tip-option-button" (click)="selectTipOption('.10')" 
                                [ngClass]="{'toggled-button-96': tipOptions['.10'].selected, 'grey-button-96': !tipOptions['.10'].selected}">
                                    10%
                                </div>
                                <div class="tip-option-dollar-amount">${{ (tipOptions['.10'].amountCents / 100).toFixed(2) }}</div>
                            </div>
                            <div class="horizontal-spacing-12"></div>
                            <div class="tip-option-column">
                                <div class="tip-option-button" (click)="selectTipOption('.15')" 
                                [ngClass]="{'toggled-button-96': tipOptions['.15'].selected, 'grey-button-96': !tipOptions['.15'].selected}">
                                    15%
                                </div>
                                <div class="tip-option-dollar-amount">${{ (tipOptions['.15'].amountCents / 100).toFixed(2) }}</div>
                            </div>
                            <div class="horizontal-spacing-12"></div>
                            <div class="tip-option-column">
                                <div class="tip-option-button" (click)="selectTipOption('.20')" 
                                [ngClass]="{'toggled-button-96': tipOptions['.20'].selected, 'grey-button-96': !tipOptions['.20'].selected}">
                                    20%
                                </div>
                                <div class="tip-option-dollar-amount">${{ (tipOptions['.20'].amountCents / 100).toFixed(2) }}</div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="getPhoneNumber()" class="float-item-fit phone-number-container">
                      <div class="heading phone-number-label">{{ 'checkOut.phoneNumber' | translate }}</div>
                      <div class="phone-number-container">
                          <div class="phone-number">{{ getPhoneNumber() }}</div>
                          <img class="green-checkmark" src="assets/images/green-checkmark.jpg">
                      </div>
                  </div>
                </div>

                <!-- Gift Card Amounts Tile -->
                <div class="check-out-content-tile grid-tile grid-tile-right-column" *ngIf="!orderTrackingService.isOrderInProgress() && cart.length && isPurchasingGiftCard">
                    <div *ngFor="let giftCard of cart" class="gift-card-container float-item-fit">
                        <div class="heading">
                            {{ 'checkOut.cardWillBeSentTo' | translate }}
                        </div>
                        <div class="subheading">
                            {{ giftCard.recipient_name }} {{ 'checkOut.atTheEmail' | translate }} {{ giftCard.recipient_email }}
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <div class="image-container"
                        [ngStyle]="giftCard.ecard_image_url ? {'background-image': 'url(' + giftCard.ecard_image_url + ')'} : {'background-image': 'none'}">
                            <div class="gift-card-amount">${{ (giftCard.amount_cents / 100).toFixed(2) }}</div>
                        </div>
                    </div>
                </div>

                <!-- Loyalty/App Tile -->
                <div class="check-out-content-tile grid-tile grid-tile-left-column"
                *ngIf="(orderTrackingService.isOrderInProgress() || sentPreorder)"
                [hidden]="orderTrackingService.getPreorderStatus().status === 'cancelled'">
                    <div class="float-item-fit download-app-container">
                        <div class="text-and-star">
                            <div class="heading">
                                {{ 'checkOut.rewards' | translate }}
                            </div>
                            <span class="nown-icon icon-Star yellow-star"></span>
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <div>
                            {{ 'checkOut.downloadAppDescription' | translate }}
                        </div>
                        <div class="vertical-spacing-24"></div>
                        <div class="download-link-row">
                            <div class="app-icon-container">
                                <img *ngIf="relevantAppName === 'Nōwn'" class="app-icon" src="assets/images/nown-app-icon.png">
                                <img *ngIf="relevantAppName === 'Real Fruit Bubble Tea'" class="app-icon" src="assets/images/rfbt-app-icon.png">
                            </div>
                            <div class="horizontal-spacing-16"></div>
                            <a *ngIf="relevantAppLink" class="yellow-button" href="{{relevantAppLink}}" target="_blank">{{ 'checkOut.downloadApp' | translate }}</a>
                            <div class="sidenote" *ngIf="!relevantAppLink">{{ 'checkOut.textedYouALink' | translate }}</div>
                        </div>
                    </div>
                </div>

                <!-- Phone Number Tile (GC orders only) -->
                <div class="check-out-content-tile grid-tile grid-tile-left-column" 
                *ngIf="getPhoneNumber() && !orderTrackingService.isOrderInProgress() && cart.length && isPurchasingGiftCard">
                    <div class="float-item-fit phone-number-container">
                        <div class="heading phone-number-label">{{ 'checkOut.phoneNumber' | translate }}</div>
                        <div class="phone-number-container">
                            <div class="phone-number">{{ getPhoneNumber() }}</div>
                            <img class="green-checkmark" src="assets/images/green-checkmark.jpg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="check-out-footer">
        <!-- unlogged in user -->
        <div class="place-order-button yellow-button global-max-width"
        *ngIf="!authenticationService.isAuthenticated()
        && !orderTrackingService.isOrderInProgress()
        && !sentPreorder && !sentGiftCard"
        (click)="signup()">
        {{ 'checkOut.continue' | translate }}
        </div>
        <!-- regular order -->
        <div class="place-order-button yellow-button global-max-width" 
        *ngIf="!isPurchasingGiftCard 
        && authenticationService.isAuthenticated() 
        && !orderTrackingService.isOrderInProgress() 
        && !sentPreorder && !sentGiftCard"
        (click)="switchView('payment-info')">
        {{ 'checkOut.continue' | translate }}
        </div>
        <!-- buying a gift card -->
        <div class="place-order-button yellow-button global-max-width" 
        *ngIf="isPurchasingGiftCard
        && authenticationService.isAuthenticated() 
        && !orderTrackingService.isOrderInProgress() 
        && !sentPreorder && !sentGiftCard"
        (click)="switchView('payment-info')">
        {{ 'checkOut.payAndSend' | translate }}
        </div>
    </div>

</div>

<div id="pendingOrderView" *ngIf="currentView === 'pending-order'">
    <div class="check-out-header">
        <div class="full-vw">
            <div class="global-max-width check-out-header-content">
                <div *ngIf="location" class="left-aligned" (click)="back()">
                    <span class="nown-icon icon-Arrow-Small-Left left-arrow-icon"></span>
                    <div *ngIf="location.logo_url || brand.logo_url" class="logo-container">
                        <img [src]="(location.logo_url ? location.logo_url : brand.logo_url)">
                    </div>
                </div>
                <div class="heading">{{ 'checkOut.heresYourOrder' | translate }}</div>
            </div>
        </div>
    </div>

    <div class="full-vw">
        <div class="check-out-content global-max-width">
            <div class="float-item-fit pending-order-message-container">
                <div class="pending-order-message">
                    <span class="message-text">{{ 'checkOut.waitingToAccept' | translate }}</span>
                    <span class="message-spinner animate-spin nown-icon icon-Spinner-Small"></span>
                </div>
                <div class="vertical-spacing-24"></div>
                <div class="processing-payment-message">
                    <div class="processing-payment-text" [style.color]="showSpinner ? '#333332' : '#CCCCC8'">
                        {{ 'checkOut.processingYourPayment' | translate }}
                    </div>
                    <div class="horizontal-spacing-12"></div>
                    <div [hidden]="!showSpinner" class="spinner-grey"></div>
                </div>
                <div class="vertical-spacing-24"></div>
                <div *ngIf="orderTrackingService.getPreorderId() 
                && orderTrackingService.getPreorderStatus().status !== 'completed'
                && orderTrackingService.getPreorderStatus().status !== 'ready'
                && orderTrackingService.getPreorderStatus().status !== 'cancelled'">
                    <div class="vertical-spacing-12"></div>
                    <div class="grey-button" (click)="cancelOrder()">
                        {{ 'checkOut.cancelOrder' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="check-out-footer">
        <div class="place-order-button yellow-button global-max-width" (click)="back()">{{ 'checkOut.goBack' | translate }}</div>
    </div>
</div>

<div id="paymentInfoView" *ngIf="currentView === 'payment-info'">
    <div class="check-out-header">
        <div class="full-vw">
            <div class="global-max-width check-out-header-content">
                <div class="left-aligned" (click)="switchView('cart')">
                    <span class="nown-icon icon-Arrow-Small-Left left-arrow-icon"></span>
                </div>
                <div class="heading">{{ 'checkOut.checkout' | translate }}</div>
            </div>
        </div>
    </div>

    <div class="full-vw">
        <div class="check-out-content global-max-width" *ngIf="savedUser">
            <!-- USER - INFORMATION TILE -->
            <div class="float-item-fit payment-information-container saved-user-container">
                <div class="font-size-20">
                    {{ 'checkOut.welcomeBack' | translate }} {{ creditCardInfo.name }}
                </div>
                <div class="vertical-spacing-12"></div>
                <div *ngIf="savedUser.email" class="email-information">
                    <div>
                        {{ 'checkOut.emailReceiptTo' | translate }}
                    </div>
                    <div>
                        {{ maskEmail(savedUser.email) }}
                    </div>
                </div>
                <!-- 
                    *** Commented by Chris Nov 27 2020 ***
                    This is for backwards compatability, as in Web Order V1, emails weren't required.
                    If an existing user from V1 uses V2, they would not be able to place an order without an email.
                    As such, I've added a textbox just for existing users with no emails
                -->
                <div *ngIf="!savedUser.email" class="email-information">
                    <div class="payment-information-label">{{ 'checkOut.emailAddress' | translate }}</div>
                    <div class="vertical-spacing-12"></div>
                    <input class="text-field-2" type="text"
                    (keyup)="checkEmailInput(email)"
                    [ngClass]="{'invalid-input': !emailValidation}"
                    [(ngModel)]="email">
                </div>
            </div>
            <div class="vertical-spacing-12"></div>

            <!-- USER - GIFTCARD TILE -->
            <div *ngIf="!isPurchasingGiftCard" class="float-item-fit payment-information-container saved-cards-container">
                <div class="gift-card-tile-header">
                    <div class="font-size-20">{{ 'checkOut.giftCard' | translate }}</div>
                    <div class="vertical-spacing-12"></div>
                    <div class="add-gift-card-button grey-button" (click)="addGiftCard()">
                        <span class="nown-icon icon-Plus-Small"></span>{{ 'checkOut.add' | translate }}
                    </div>
                </div>
                <div class="saved-cards-list" *ngIf="savedUser && giftCards.length">
                    <div class="vertical-spacing-12"></div>
                    <div class="saved-card" *ngFor="let card of giftCards; let i = index">
                        <div for="card">{{ 'checkOut.giftCard' | translate }}: ${{ (card.currentBalanceCents / 100).toFixed(2) }}</div>
                        <div class="nown-icon icon-Exit-Large" (click)="deleteGiftCard(card, i)"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isPurchasingGiftCard" class="vertical-spacing-12"></div>

            <!-- USER - SAVED CARDS TILE -->
            <div *ngIf="(creditCards.length && !addingNewCreditCard) && !isGiftCardOnly" class="float-item-fit payment-information-container saved-cards-container">
                <div class="font-size-20">{{ 'checkOut.creditCard' | translate }}</div>
                <div class="vertical-spacing-12"></div>
                <div class="saved-cards-list">
                    <div class="saved-card" *ngFor="let card of creditCards; let i = index">
                        <input class="radio-button" [(ngModel)]="selectedCreditCard" type="radio" [attr.id]="card.id" [value]="card">
                        <label for="card">{{ card.cc_type }} {{ card.masked_num }}</label>
                        <div class="nown-icon icon-Exit-Large" (click)="deleteCreditCard(card)"></div>
                    </div>
                    <div class="grey-button" (click)="addCreditCard()">{{ 'checkOut.useDifferentCard' | translate }}</div>
                </div>
            </div>
            <div *ngIf="(creditCards.length && !addingNewCreditCard) && !isGiftCardOnly" class="vertical-spacing-12"></div>

            <!-- USER - NEW CREDIT CARD INFO TILE -->
            <div *ngIf="addingNewCreditCard && !isGiftCardOnly" class="float-item-fit payment-information-container add-credit-card-container">
                <!-- only allow users to go back to saved cards if they actually have saved cards. -->
                <div class="grey-button" *ngIf="creditCards.length" (click)="cancelAddCreditCard()">
                    <span class="nown-icon icon-Arrow-Large-Left"></span> {{ 'checkOut.backToSavedCards' | translate }}
                </div>
                <div class="vertical-spacing-12" *ngIf="creditCards.length"></div>

                <div class="payment-information">
                    <div class="heading">{{ 'checkOut.creditCard' | translate }}</div>
                    <div class="vertical-spacing-12"></div>
                    <div class="payment-information-field-large">
                        <label for="savedUserCreditCard" class="payment-information-label">{{ 'checkOut.cardNumber' | translate }}</label>
                        <div class="credit-card-number-container">
                            <input id="savedUserCreditCard"  type="tel" class="text-field-2" 
                            name="cardnumber" autocomplete="cc-number" ccNumber 
                            placeholder="•••• •••• •••• ••••" 
                            [(ngModel)]="creditCardInfo.number">
                            <div class="lock-icon-container">
                                <img class="lock-icon" src="assets/images/lock.png">
                            </div>
                        </div>
                    </div>
                    <div class="vertical-spacing-12"></div>
                    <div class="payment-information-combined-row">
                        <div class="payment-information-field-small">
                            <label for="savedUserExpDate" class="payment-information-label">{{ 'checkOut.expirationDate' | translate }}</label>
                            <input id="savedUserExpDate" type="text" class="text-field-2"
                            name="exp-date" autocomplete="cc-exp" maxlength="5"
                            inputmode="numeric" pattern="[0-9]*"  
                            placeholder="MM/YY" [(ngModel)]="creditCardInfo.expDate" 
                            (keydown)="preventNonTabKeys($event)" (keyup)="inputNumbersOnly($event, 5, 'expiry-date')">
                        </div>
                        <div class="horizontal-spacing-16"></div>
                        <div class="payment-information-field-small">
                            <label for="savedUserCVC" class="payment-information-label">CVV</label>
                            <input id="savedUserCVC" class="text-field-2" type="text" 
                            inputmode="numeric" pattern="[0-9]*" [(ngModel)]="creditCardInfo.cvv" 
                            (keydown)="preventNonTabKeys($event)" (keyup)="inputNumbersOnly($event, 4, 'cvv')">
                        </div>
                    </div>
                    <div class="vertical-spacing-12"></div>
                    <div class="payment-information-field-small">
                        <label for="savedUserPostalCode" class="payment-information-label">{{ 'checkOut.postalCode' | translate }}</label>
                        <input id="savedUserPostalCode" name="postal" autocomplete="postal-code" 
                        type="text" class="text-field-2" [(ngModel)]="creditCardInfo.postalCode">    
                    </div>
                    <div class="vertical-spacing-24"></div>
                    <div class="save-payment-method-container">
                        <input class="save-payment-checkbox" type="checkbox" name="save-payment-info" [(ngModel)]="toSavePaymentMethod">
                        <label class="save-payment-label" for="save-payment-info">{{ 'checkOut.saveMyPaymentInformation' | translate }}</label>
                    </div>
                    <div *ngIf="toSavePaymentMethod" class="payment-method-saved">
                        {{ 'checkOut.saveMyPaymentInformationDesc' | translate }}
                    </div>
                    <div *ngIf="creditCardError" style="font-size: 12px; color: red;">
                        <div>{{ creditCardError }}</div>
                        <div class="vertical-spacing-12"></div>
                    </div>
                    <div class="vertical-spacing-12"></div>
                    <div class="payment-information-note-container">
                        <div class="lock-icon-container">
                            <img class="lock-icon" src="assets/images/lock.png">
                        </div>
                        <div class="payment-information-note">
                            {{ 'checkOut.paymentInformationProtection' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="addingNewCreditCard && !isGiftCardOnly" class="vertical-spacing-12"></div>

            <!-- CART TOTALS TILE -->
            <div class="float-item-fit payment-information-container cart-totals-container">
                <div class="totals-container">
                    <div class="subtotal-and-tax">
                        <div [hidden]="!itemTotals.allTotals.discount">{{ 'checkOut.discount' | translate }}: -${{ (itemTotals.allTotals.discount / 100).toFixed(2) }}</div>
                        <div>{{ 'checkOut.subtotal' | translate }}: ${{ (itemTotals.allTotals.subtotal / 100).toFixed(2) }}</div>
                        <div>{{ 'checkOut.tax' | translate }}: ${{ (itemTotals.allTotals.tax / 100).toFixed(2) }}</div>
                        <div [hidden]="!itemTotals.allTotals.tip">{{ 'checkOut.tip' | translate }}: ${{ (itemTotals.allTotals.tip / 100).toFixed(2) }}</div>
                    </div>
                    <div class="horizontal-spacing-16"></div>
                    <div class="total">
                        {{ 'checkOut.total' | translate }}: ${{ (itemTotals.allTotals.total / 100).toFixed(2) }}
                    </div>
                </div>
                <div class="tender-calculation-container">
                    <div class="tender-calculation-title">{{ 'checkOut.payingWith' | translate }}:</div>
                    <div class="tender-calculations">
                        <div class="tender-calculation" *ngFor="let tender of tenders">
                            <div *ngIf="tender.key === 'Credit Card'" class="tender-name">{{ 'checkOut.creditCard' | translate }}: </div>
                            <div *ngIf="tender.key === 'Gift Card'" class="tender-name">{{ 'checkOut.giftCard' | translate }}: </div>
                            <div *ngIf="tender.key !== 'Credit Card'" class="tender-name">{{ tender.key }}: </div>
                            <div class="tender-amount">{{ tender.val }}</div>
                        </div>
                    </div>
                </div>
                <div class="vertical-spacing-12"></div>
                <div class="yellow-button" (click)="placeOrder()">
                    <div>{{ 'checkOut.placeOrder' | translate }}</div>
                    <div [hidden]="!isPlacingOrder" class="spinner-grey"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="vertical-spacing-48"></div>
<a href="http://www.nownpos.com" class="powered-by-nown-container">
    <img class="powered-by-nown" src="assets/images/powered_by_nown.png"/>
</a>