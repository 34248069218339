<div class="item-container">
    <div class="item-container-header">
        <div class="back-btn-icon">
            <span class="nown-icon icon-Exit-Large cross-icon" (click)="close()"></span>
            <span class="nown-icon icon-Arrow-Large-Left left-arrow-icon" (click)="close()"></span>
        </div>
        <div class="item-name">{{ item.name }}</div>
    </div>
    <div *ngIf="item.image_url" class="item-details-container">
        <div class="item-details-text-with-image">
            <div class="calories-with-image" *ngIf="item.calories && item.calories !== 0">{{item.calories}} calories</div>
            <div class="item-image-container">
                <div class="item-image">
                    <img [src]="item.image_url">
                </div>
            </div>
            <div class="item-description-with-image">{{ item.description }}</div>
        </div>
    </div>
    <div class="item-details-container" *ngIf="!item.image_url">
        <div class="item-details-text-without-image">
            <div class="calories-without-image" *ngIf="item.calories && item.calories !== 0">{{item.calories}} calories</div> 
            <div class="item-description-without-image">{{ item.description }}</div>       
        </div>
    </div>
    <div class="modifier-tabs-container">
        <div *ngFor="let modifier of item.children" [attr.id]="modifier.menu_id + '-tab'" class="modifier-tab" (click)="selectModifier(modifier)">
            <div>{{ modifier.name }}</div>
        </div>
        <div id="modifierSelector" class="modifier-selector-underline"></div>
    </div>
</div>

<div class="modifiers-container" [style.height]="item.image_url ? 'calc(100vh - 267px - 124px)' : 'calc(100vh - 147px - 124px)'" (scroll)="onModifierScroll($event)">
    <div style="min-width: 16px;"></div>
    <div [attr.id]="modifier.menu_id" *ngFor="let modifier of item.children; let modIndex = index" class="modifier-options-tile-container" (click)="selectModifier(modifier)">
        <div style="width: 100%;" [style.pointer-events]="selectedModifierId === modifier.menu_id ? 'auto' : 'none'">
            <div class="vertical-spacing-12"></div>
            <div [attr.id]="modifier.menu_id + '-tile'" class="modifier-options-tile">
                <div class="modifier-option-name">
                    <div>{{ modifier.name }}</div>
                    <div class="horizontal-spacing-12"></div>
                    <div class="required" *ngIf="modifier.minSelection === 1 && (modifier.maxSelection === 1 || modifier.selection === 'single')">
                        {{ 'itemModifier.required' | translate }}
                    </div>
                    <div class="required" *ngIf="modifier.minSelection >= 0 && modifier.maxSelection > 1 && modifier.minSelection !== modifier.maxSelection">
                        {{ 'itemModifier.choose' | translate }} {{ modifier.minSelection }} {{ 'itemModifier.to' | translate }} {{ modifier.maxSelection }}
                    </div>
                    <div class="required" *ngIf="modifier.minSelection > 1 && modifier.maxSelection > 1 && modifier.minSelection === modifier.maxSelection">
                        {{ 'itemModifier.choose' | translate }} {{ modifier.minSelection }}
                    </div>
                    <div class="required" *ngIf="modifier.minSelection > 0 && modifier.maxSelection < 0 && modifier.selection === 'multiple'">
                        {{ 'itemModifier.chooseAtleast' | translate }} {{ modifier.minSelection }}
                    </div>
                    <div class="required" *ngIf="modifier.minSelection < 0">
                        <div *ngIf="effectiveOverrideLimit !== null && modifier.maxSelection > 0">
                          {{ 'itemModifier.chooseUpTo' | translate }} {{ effectiveOverrideLimit }}
                        </div>
                        <div class="required" *ngIf="effectiveOverrideLimit === null && modifier.maxSelection > 0">
                          {{ 'itemModifier.chooseUpTo' | translate }} {{ modifier.maxSelection }}
                        </div>
                    </div>
                </div>
                <div class="vertical-spacing-12"></div>
                <div class="modifier-options-container">
                    <div *ngFor="let option of modifier.children" class="modifier-option" (click)="toggleModifierOption(option, modifier, modIndex, $event)" [style.background-color]="option.selected ? '#333332' : 'transparent'">
                        <div [style.color]="option.selected ? 'white' : 'black'">
                            <div>{{ option.name }}</div>
                            <div *ngIf="option.amount_cents"><span *ngIf="item.price_replaced_by_id_v2 != modifier.menu_id">+</span>${{ (option.amount_cents / 100).toFixed(2) }}</div>
                            <div *ngIf="option.calories && option.calories !== 0">{{ option.calories }} Calories</div>
                        </div>
                    </div>
                    <div *ngIf="modifier.name === 'Note'" >
                        <input class="modifier-options-note" type="text" [(ngModel)]="item.note" maxlength="250">
                    </div>
                </div>
            </div>
            <div class="vertical-spacing-12"></div>
        </div>
        <div style="min-width: 16px;"></div>
    </div>
</div>

<div class="add-to-order-container">
    <div class="add-to-order-body">
        <div class="added-modifier-option-container">
            <span *ngFor="let modifierOption of selectedItems; let last = last">
                <span>{{ modifierOption.name }}</span>
                <span *ngIf="!last">, </span>
            </span>
        </div>
        <div class="yellow-button-container">
            <div *ngIf="remainingChoices <= 0">
                <div [hidden]="!itemTotal" class="decrease-quantity-button" (click)="decreaseQuantity()">
                    <div class="quantity-button-circle">
                        <div class="quantity-button-text">
                            -
                        </div>
                    </div>
                </div>
            </div>
            <div class="yellow-button" *ngIf="!orderTrackingService.isOrderInProgress()" (click)="addToOrder()">
                <div [hidden]="!itemTotal"> ${{ (itemTotal / 100).toFixed(2) }}</div>
                <div class="horizontal-spacing-12"></div>
                <div *ngIf="remainingChoices <= 0">
                    <div *ngIf="!isEdit">{{ 'Qty:  ' + quantity + ' | '}} {{ 'itemModifier.addToOrder' | translate }}</div>
                    <div *ngIf="isEdit">{{ 'Qty:  ' + quantity + ' | '}} {{ 'itemModifier.confirmChanges' | translate }}</div>
                </div>
                <div *ngIf="remainingChoices > 0">
                    <span *ngIf="remainingChoices == 1">{{ remainingChoices }} {{ 'itemModifier.choiceRemaining' | translate }}</span>
                    <span *ngIf="remainingChoices > 1">{{ remainingChoices }} {{ 'itemModifier.choiceRemaining' | translate }}</span>
                </div>
            </div>
            <div *ngIf="remainingChoices <= 0">
                <div [hidden]="!itemTotal" class="increase-quantity-button" (click)="increaseQuantity()">
                    <div class="quantity-button-circle">
                        <div class="quantity-button-text">
                            +
                        </div>
                    </div>
                </div>
            </div>
            <div class="yellow-button" *ngIf="orderTrackingService.isOrderInProgress()" (click)="viewOrder()">{{ 'itemModifier.orderInProgress' | translate }}</div>
        </div>
    </div>
</div>