import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tile-menu-item',
  templateUrl: './tile-menu-item.component.html',
  styleUrls: ['./tile-menu-item.component.less']
})
export class TileMenuItemComponent implements OnInit {

  @Input()
  img: string;

  @Input()
  name: string;

  @Input()
  price: string;

  @Input()
  calories: number;

  constructor() { }

  ngOnInit(): void {
  }

}
