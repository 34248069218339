import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  constructor(@Inject(DOCUMENT) private document: HTMLDocument) {}

  public setAppFavicon(icon: string): void {
    this.document.getElementById('appFavicon').setAttribute('href', icon);
  }
}
