import { Injectable } from '@angular/core';
import { MapsAPILoader, LatLng } from '@agm/core';
import { MerchantLocation } from 'src/app/models/merchant-location.model';

declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private readonly R = 6371e3; // earth's mean radius in metres
  private readonly sin = Math.sin;
  private readonly cos = Math.cos;
  private readonly acos = Math.acos;
  private readonly π = Math.PI;
  private readonly radiusFilter: number = 6000; // 6km

  private geocoder: google.maps.Geocoder;
  private myPosition: Position;

  constructor(private mapLoader: MapsAPILoader) {
    this.mapLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  public findMyCoordinate(): Promise<Position> {
    return new Promise((resolve) => {
      if (this.myPosition) {
        return resolve(this.myPosition);
      }
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          this.myPosition = new Position(longitude, latitude);
          resolve(this.myPosition);
        }, (error) => {
          console.error(error);
          resolve(new Position(0, 0));
        });
      } else {
        resolve(new Position(0, 0));
      }
    });
  }

  public filterLocationsByBounds(locations: MerchantLocation[], bounds: google.maps.LatLngBounds): Promise<MerchantLocation[]> {
    return new Promise(async (resolve) => {
      if (!locations || locations.length === 0) {
        return resolve([]);
      }

      const center: LatLng = bounds.getCenter();
      const me = await this.findMyCoordinate();
      const lat = me.latitude || center.lat();
      const lng = me.longitude || center.lng();

      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      const minLat = sw.lat();
      const maxLat = ne.lat();
      const minLng = sw.lng();
      const maxLng = ne.lng();

      for (const location of locations) {
        let llat = location.latitude;
        let llng = location.longitude;
        // I think we can assume that we do not have a location
        // in the Gulf of Guinea off the coast of western Africa!!
        if (!llat || llat === 0 || !llng || llng === 0) {
          try {
            const tempPos = await this.findCoordinateFromStreetAddress(location.street_address + ' ' + location.city);
            llat = location.latitude = tempPos.latitude;
            llng = location.longitude = tempPos.longitude;
          } catch (err) {
            console.log(err);
          }
        }
      }

      // https://www.movable-type.co.uk/scripts/latlong-db.html
      // Assuming that all locations have the lat and lng set
      const pointsBoundingBox = locations.filter(location => {
        const llat = location.latitude;
        const llng = location.longitude;
        return (llat > minLat && llat < maxLat) && (llng > minLng && llng < maxLng);
      });

      // add in distance d = acos( sinφ₁⋅sinφ₂ + cosφ₁⋅cosφ₂⋅cosΔλ ) ⋅ R
      for (const p of pointsBoundingBox) {
        p.distance = this.acos(this.sin(p.latitude * this.π / 180) * this.sin(lat * this.π / 180)
          + this.cos(p.latitude * this.π / 180) * this.cos(lat * this.π / 180)
          * this.cos(p.longitude * this.π / 180 - lng * this.π / 180)) * this.R;
      }

      if (lat == 0 && lng == 0) {
        resolve(pointsBoundingBox);
      } else {
        // filter for points with distance from bounding circle centre less than radius, and sort
        const pointsWithinCircle = pointsBoundingBox.sort((a, b) => a.distance - b.distance);
        resolve(pointsWithinCircle);
      }
    });
  }

  public filterLocationsByLatLong(locations: MerchantLocation[], lat: number, lng: number): Promise<MerchantLocation[]> {
    return new Promise(async (resolve) => {
      if (!locations || locations.length === 0) {
        return resolve([]);
      }
      if (!lat || !lng) {
        lat = 0;
        lng = 0;
      }
      const minLat = lat - this.radiusFilter / this.R * 180 / this.π;
      const maxLat = lat + this.radiusFilter / this.R * 180 / this.π;
      const minLng = lng - this.radiusFilter / this.R * 180 / this.π / this.cos(lat * this.π / 180);
      const maxLng = lng + this.radiusFilter / this.R * 180 / this.π / this.cos(lat * this.π / 180);

      for (const location of locations) {
        let llat = location.latitude;
        let llng = location.longitude;
        // I think we can assume that we do not have a location
        // in the Gulf of Guinea off the coast of western Africa!!
        if (!llat || llat === 0 || !llng || llng === 0) {
          try {
            const tempPos = await this.findCoordinateFromStreetAddress(location.street_address + ' ' + location.city);
            llat = location.latitude = tempPos.latitude;
            llng = location.longitude = tempPos.longitude;
          } catch (err) {
            console.log(err);
          }
        }
      }

      // https://www.movable-type.co.uk/scripts/latlong-db.html
      // Assuming that all locations have the lat and lng set
      const pointsBoundingBox = locations.filter(async location => {
        const llat = location.latitude;
        const llng = location.longitude;
        return (llat > minLat && llat < maxLat) && (llng > minLng && llng < maxLng);
      });

      // add in distance d = acos( sinφ₁⋅sinφ₂ + cosφ₁⋅cosφ₂⋅cosΔλ ) ⋅ R
      for (const p of pointsBoundingBox) {
        p.distance = this.acos(this.sin(p.latitude * this.π / 180) * this.sin(lat * this.π / 180)
          + this.cos(p.latitude * this.π / 180) * this.cos(lat * this.π / 180)
          * this.cos(p.longitude * this.π / 180 - lng * this.π / 180)) * this.R;
      }

      if (lat == 0 && lng == 0) {
        resolve(pointsBoundingBox);
      } else {
        // filter for points with distance from bounding circle centre less than radius, and sort
        const pointsWithinCircle = pointsBoundingBox.filter(p => p.distance < this.radiusFilter)
        .sort((a, b) => a.distance - b.distance);
        resolve(pointsWithinCircle);
      }
    });
  }

  public findCoordinateFromStreetAddress(streetAddress: string): Promise<Position> {
    return new Promise((resolve, reject) => {
      if (!this.geocoder || !streetAddress || streetAddress.length === 0) {
        return reject();
      }
      this.geocoder.geocode({address: streetAddress}, (results, status) => {
        if (status !== 'OK' || !results[0] || !results[0].geometry || !results[0].geometry.location) {
          return reject();
        }
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        resolve(new Position(longitude, latitude));
      });
    });
  }
}

export class Position {
  longitude: number;
  latitude: number;

  constructor(longitude: number, latitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
