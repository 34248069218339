import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as io from 'socket.io-client';
import { LucovaGatewayService } from '../lucova-gateway/lucova-gateway.service';
import { PreorderStatus } from 'src/app/models/preoder-status.model';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class OrderTrackingService {
    private socket;
    private preorderStatus: string;
    private orderInProgress = false;
    private orderItems: any[] = new Array<any>();
    private preorderId: string;
    private earliestPickupTime: number;
    private orderRecentlyCompleted: boolean;
    private orderTipCents: number;

    constructor(private gateway: LucovaGatewayService, private translate: TranslateService) {}

    setupSocketConnection(): void {
        const username = localStorage.getItem('user_name');
        if (!username) {
            return;
        }
        this.socket = io(environment.lucovaSocketIOUrl, {
            query: { room: `user-notify.${username}` }
        });

        this.socket.on('message', (dataString: string) => {
            const data = JSON.parse(dataString);
            if (data.type === 'preorder_status') {
                this.preorderStatus = data.payload.status;
                this.earliestPickupTime = data.payload.earliest_ready_time;
                this.preorderId = data.payload._id;
                if (!(data.payload.status === 'completed' || data.payload.status === 'cancelled')) {
                    this.orderInProgress = true;
                } else {
                    if (data.payload.status === 'completed') {
                        this.orderRecentlyCompleted = true;
                        setTimeout(() => {
                            this.orderRecentlyCompleted = false;
                        }, 60000 * 10);
                    }
                    this.orderInProgress = false;
                }
            }
        });
    }

    closeSocketConnection(): void {
        if (this.socket) {
            this.socket.close();
        }
    }

    getPreorderStatus(): PreorderStatus {
        switch (this.preorderStatus) {
            case 'pending':
                return {
                    status: this.preorderStatus,
                    userFriendlyStatus: this.translate.instant('ts.orderSubmittedConfirm'),
                    progressIndicatorValue: null
                };
            case 'print_pending':
                return {
                    status: this.preorderStatus,
                    userFriendlyStatus: this.translate.instant('ts.orderReceivedConfirmation'),
                    progressIndicatorValue: null
                };
            case 'acknowledged':
            case 'preparing':
                return {
                    status: this.preorderStatus,
                    userFriendlyStatus: this.translate.instant('ts.preparingYourOrderRightNow'),
                    progressIndicatorValue: 0.5
                };
            case 'ready':
            case 'completed':
                return {
                    status: this.preorderStatus,
                    userFriendlyStatus: this.translate.instant('ts.yourOrderIsReady'),
                    progressIndicatorValue: 1.0
                };
            case 'cancelled':
                return {
                    status: this.preorderStatus,
                    userFriendlyStatus: this.translate.instant('ts.yourOrderHasBeenCancelled'),
                    progressIndicatorValue: 0.0
                };
            default:
                return {
                    status: this.preorderStatus,
                    userFriendlyStatus: this.translate.instant('ts.loading'),
                    progressIndicatorValue: null
                };
        }
    }

    loadPreorderStatus(): void {
        this.gateway.getPreorderStatus()
            .then((res) => {
                if (res.success) {
                    if (res.preorders.length) {
                        this.preorderStatus = res.preorders[0].status;
                        this.earliestPickupTime = res.preorders[0].estimated_completion;
                        this.preorderId = res.preorders[0]._id;
                        this.orderItems = res.preorders[0].items;
                        this.orderTipCents = res.preorders[0].tip_cents;
                        this.orderInProgress = true;
                    } else {
                        localStorage.removeItem('order_details');
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    isOrderInProgress(): boolean {
        return this.orderInProgress;
    }

    getOrderItems(): Array<any> {
        return this.orderItems;
    }

    getPreorderId(): string {
        return this.preorderId;
    }

    deletePreorderId(): void {
        this.preorderId = undefined;
    }

    getEarliestPickupTime(): string {
        return moment.unix(this.earliestPickupTime).local().format('h:mm A');
    }

    wasOrderRecentlyCompleted(): boolean {
        return this.orderRecentlyCompleted;
    }

    getOrderTipCents(): number {
        return this.orderTipCents;
    }
}
