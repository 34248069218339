import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AlertService } from 'src/app/services/alert/alert.service';
import { LucovaGatewayService } from 'src/app/services/lucova-gateway/lucova-gateway.service';
import { TranslateService } from '@ngx-translate/core';

const View = {
  DIGITAL: 'digital',
  PHYSICAL: 'physical',
  MAIN: 'main'
};

const SubView = {
  DIGITAL_STEP_ONE: 'digital__step_1-email-input',
  DIGITAL_STEP_TWO: 'digital__step_2-code-input',
  DIGITAL_STEP_THREE: 'digital__step_3-select-cards',
  DIGITAL_STEP_FOUR: 'digital__step_4-claim-cards',
};

@Component({
  selector: 'app-add-gift-card',
  templateUrl: './add-gift-card.component.html',
  styleUrls: ['./add-gift-card.component.less']
})
export class AddGiftCardComponent implements OnInit {
  public onClose: Subject<any> = new Subject();
  title: any;
  description = {
    'digital__step_1-email-input': this.translate.instant('ts.enterTheEmailAddress'),
    'digital__step_2-code-input': this.translate.instant('ts.enterTheSecurityCode'),
    'digital__step_3-select-cards': this.translate.instant('ts.whichCard'),
    'digital__step_4-claim-cards': ''
  };
  currentView: string;
  subView: string;

  // this count is mainly used to detect whether or not 'select all' checkbox
  // should be checked/unchecked after selecting/unselecting an individual card.
  // e.g. if 4 cards total, 3 were previously checked, checking the last card
  // should also check the 'select all' checkbox
  // similarly, if all cards are checked,
  // unchecking one should uncheck the 'select all' checkbox.
  selectedCount: number = 0;
  selectedAll: boolean;

  isClaimingDigitalGift: boolean = false;

  // due to backwards compatibility:
  // nodeFid is used for physical gift cards
  // topNodeId is used for digital gift cards
  nodeFid: string;
  topNodeId: string;
  
  // digital gift card vars
  digitalGiftCardEnabled: boolean;
  recipientEmail: string;
  securityCode: string;
  claimableCards: any[];
  brandName: string;
  appFid: string;

  // physical gift card vars
  giftCardNum: string;
  pincode: string;

  constructor(public modalRef: BsModalRef,
              private gateway: LucovaGatewayService,
              public alertService: AlertService,
              private translate:TranslateService) {
  }

  switchView(view: string): void {
    this.currentView = view;

    if (view === View.DIGITAL) {
      this.title = this.translate.instant('ts.addEGiftCard');
      this.switchSubView(SubView.DIGITAL_STEP_ONE);
    } else if (view === View.PHYSICAL) {
      this.title = this.translate.instant('ts.addPhysicalGiftCard');
    } else {
      this.title = this.translate.instant('ts.addGiftCard');
      this.switchSubView('');
    }
  };

  switchSubView(view: string): void {
    this.subView = view;
  };

  // validate gift card
  validateGiftCard(): void {
    if (this.currentView === View.DIGITAL) {
      this.validateDigitalGiftCard(this.securityCode);
    } else if (this.currentView === View.PHYSICAL) {
      this.validatePhysicalGiftCard(this.giftCardNum, this.pincode);
    }
  }

  close() {
    this.modalRef.hide();
    this.onClose.next();
  }

  ngOnInit(): void {
    this.switchView(View.MAIN);
  }

  // send security code to email to validate the gift card.
  sendSecurityCode(email: string): void {
    this.gateway.requestEGiftCardAuthCode(email).then((res) => {
      if (res.success) {
        this.switchSubView(SubView.DIGITAL_STEP_TWO);
      } else {
        this.alertService.showError(
          this.translate.instant('ts.errorSendingVerificationCode'),
          this.translate.instant('ts.pleaseTryAgain')
        );
      }
    });
  };

  // digital gift card validation & getting a list of eCards sent to this email
  validateDigitalGiftCard(code: string): void {
    this.gateway.getClaimableEGiftCards(this.recipientEmail, code, this.topNodeId)
    .then((res) => {
      if (res.success) {
        this.showClaimableDigitalGiftCards(res.cards);
      } else {
        this.alertService.showError(
          this.translate.instant('ts.invalidEmailVerificationCode'),
          this.translate.instant('ts.pleaseTryAgain')
        );
      }
    });
  };

  // show a list of digital gift cards sent to this email
  showClaimableDigitalGiftCards(cards: any[]): void {
    if (cards.length) {
      this.claimableCards = cards;
      this.switchSubView(SubView.DIGITAL_STEP_THREE);
    } else {
      this.alertService.showError(
        this.translate.instant('ts.error'),
        this.translate.instant('ts.noeGiftCardSent')
      );
    }
  };

  // select card checkbox fn
  selectCard(selected: boolean): void {
    selected ? this.selectedCount++ : this.selectedCount--;

    if (!selected && this.selectedAll) {
      // unselect the 'select all' checkbox if 
      // 1. this card is unselected 
      // 2. 'select all' has been previously selected
      this.selectedAll = false;
    } else if (selected && !this.selectedAll) {
      // select the 'select all' checkbox if 
      // 1. after this selected card, all cards are selected
      // 2. 'select all' isn't checked
      if (this.selectedCount === this.claimableCards.length) {
        this.selectedAll = true;
      }
    }
  }

  // select all checkbox fn
  selectAll(selected: boolean): void {
    this.claimableCards.forEach((card) => {
      card.selected = selected;
    });
    selected ? this.selectedCount = this.claimableCards.length : this.selectedCount = 0;
  }

  // execute all promises sequentially
  // this fn is used to claim all cards sequentially
  // as mongodb cannot handle saving these cards concurrently.
  executeSequentially(promiseFactories: any): Promise<any> {
    var result = Promise.resolve();
    promiseFactories.forEach((promiseFactory) => {
      result = result.then(promiseFactory);
    });

    return result;
  }

  // claim all selected cards 
  claimCards(): void {
    this.isClaimingDigitalGift = true;

    let selectedCards = [];

    // directly use all claimable cards if selectedAll is true
    // else filter by selected cards.
    if (this.selectedAll) {
      selectedCards = this.claimableCards;
    } else {
      selectedCards = this.claimableCards.filter((card) => {
        return card.selected;
      });
    }

    // as soon as a promise is created, it begins executing immediately.
    // therefore we use a promiseFactory to return something that creates the promise instead.
    // in this case, this.gateway.claimEGiftCard()
    let promiseFactories = [];
    selectedCards.forEach((card) => {
      var promiseFactory = () => {
        return this.gateway.claimEGiftCard(card.id);
      }
      promiseFactories.push(promiseFactory);
    });

    // this function will claim all cards sequentially.
    // the code inside the then block is called after the last card has been claimed/saved
    this.executeSequentially(promiseFactories).then(() => {
      this.isClaimingDigitalGift = false;
      this.close();
    });
  }

  // physical validation
  validatePhysicalGiftCard(cardNumber: string, pin: string): void {
    this.isClaimingDigitalGift = true;
    this.gateway.registerGiftCard(cardNumber, pin).then((res) => {
      if (res.success) {
        this.isClaimingDigitalGift = false;
        this.close();
      } else {
        this.isClaimingDigitalGift = false;
        this.alertService.showError(
          this.translate.instant('ts.errorRegisteringYourGiftCard'),
          this.translate.instant('ts.checkYourDetails')
        );
      }
    });
  };
}
