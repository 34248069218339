import { Component, OnInit, OnDestroy, ElementRef, ViewChild, NgZone, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LucovaGatewayService } from 'src/app/services/lucova-gateway/lucova-gateway.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { MerchantLocation } from 'src/app/models/merchant-location.model';
import { Image, MerchantBrand, MerchantBrandResponse } from 'src/app/models/merchant-brand.model';
import { AgmMap, MapsAPILoader, LatLngBounds } from '@agm/core';
import { LocationService, Position } from 'src/app/services/location/location.service';
import { Title } from '@angular/platform-browser';
import { FaviconService } from 'src/app/services/favicon/favicon.service';
import { LocationMenuResponse } from 'src/app/models/location-menu.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GiftCardOptionsComponent } from '../common/modals/gift-card-options/gift-card-options.component';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

declare var google: any;

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.less']
})
export class LocationsListComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  locations: MerchantLocation[] = new Array<MerchantLocation>();
  nearbyLocations: MerchantLocation[] = new Array<MerchantLocation>();

  parentNodeId: string;
  brand: MerchantBrand = new MerchantBrand();

  menuReloadTimeoutId: number;
  locationId: string;
  isStoreFront: boolean = !!this.env.getWebOrderUrl();
  scrollStart: boolean;
  scrollEnd: boolean;
  modalOpened = false;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('AgmMap') agmMap: AgmMap;
  map: any = null;

  myLocation: Position = new Position(0, 0);
  loadingMenu = false;
  loadingLocations = false;
  mapsZoomEventListener;

  constructor(private gateway: LucovaGatewayService,
    private env: EnvironmentService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private locationService: LocationService,
    private mapLoader: MapsAPILoader,
    private modalService: BsModalService,
    private titleService: Title,
    private faviconService: FaviconService,
    public alertService: AlertService,
    private translate: TranslateService) {
    if (this.router.getCurrentNavigation().extras
      && this.router.getCurrentNavigation().extras.state) {
      this.brand = this.router.getCurrentNavigation().extras.state.brand;
      this.gateway.setSelectedBrand(this.brand);
    }
  }


  ngOnInit(): void {
    this.parentNodeId = this.route.snapshot.params.node_id;

    if (this.isStoreFront) {
      const webOrderUrl = this.env.getWebOrderUrl();
      const nodeFid = webOrderUrl.split('.')[0];
      this.gateway.getBrandByNodeFid(nodeFid)
        .then((rsp: MerchantBrandResponse) => {
          if (!rsp || !rsp.success || !rsp.organization) {
            return window.location.href = this.env.getDefaultHost();
          }
          this.brand = rsp.organization;
          this.gateway.setSelectedBrand(this.brand);

          this.titleService.setTitle(this.brand.node_name);
          this.faviconService.setAppFavicon(this.brand.logo_url);

          const type = rsp.organization.app_root ? 'app' : 'organization';
          this.loadLocation(this.brand._id, type);
          this.showGiftCardModalMaybe();
        })
        .catch((err) => console.error(err));
    } else {
      if (!this.brand._id) {

        this.gateway.loadBrands().then((brands) => {
          const foundBrand = brands.find((brand) => brand._id === this.parentNodeId);
          if (!foundBrand) {
          }
          this.brand = foundBrand;
          this.gateway.setSelectedBrand(this.brand);
          this.titleService.setTitle(this.brand.node_name);
          this.faviconService.setAppFavicon(this.brand.logo_url);
          this.showGiftCardModalMaybe();
        }).catch((err) => console.error(err));
      }
      // Fall back to the home page
      if (!this.parentNodeId) {
        this.router.navigate(['/']);
        return;
      }
      const type = this.brand && this.brand.app_root ? 'app' : 'organization';
      this.loadLocation(this.parentNodeId, type);
    }
    this.mapLoader.load().then(() => {
      this.initAutoCompleteSearch();
    });
  }

  ngOnDestroy(): void {
    if (this.mapsZoomEventListener) {
      this.mapsZoomEventListener.remove();
    }
  }

  ngAfterViewInit(): void {
    this.agmMap.mapReady.subscribe((map) => {
      this.map = map;
    });
  }

  ngAfterViewChecked(): void {
    const scrollableItemsList = [].slice.call(document.getElementsByClassName('scrollable-items'));
    if (scrollableItemsList.length) {
      scrollableItemsList.forEach((scrollableItems) => {
        this.onScrollableItemsLoad(scrollableItems);
      });
    }
  }

  /**
   * This function loads the gift card checkout modal with the appropriate
   * gift card image if the query parameter {gc=xxx} is present in the url 
   */
  showGiftCardModalMaybe() {
    const giftCardId = this.route.snapshot.queryParamMap.get('gc');
    const isDigitalGiftCardEnabled = this.brand.digital_gift_card_settings.enabled;

    if (!giftCardId || !isDigitalGiftCardEnabled) {
      return;
    }

    const returnUrl = this.route.snapshot.queryParamMap.get('rt_url');
    if (!!returnUrl) {
      this.env.gcChekoutReturnUrl = returnUrl;
    }

    // This flag can be used later on in different parts of the system
    // to determine if a gift card checkout workflow was activated 
    this.env.isMerchantGiftCardCheckoutLink = true;

    const imageObject = this.brand.digital_gift_card_settings.images.find((image) => image.id == giftCardId);

    if (!imageObject) {
      return;
    }

    this.loadGiftCard(imageObject);
  }

  onScrollableItemsLoad(scrollableItems): void {
    // Some devices don't completely reach the end of a scroll. A threshold in pixels is used to compensate for that.
    const triggerThreshold = 32;

    const fadeRight = [].slice.call(scrollableItems.children).find((element) => {
      return element.className === 'fade-right';
    });
    const fadeLeft = [].slice.call(scrollableItems.children).find((element) => {
      return element.className === 'fade-left';
    });
    if (!(fadeRight && fadeLeft)) {
      return;
    }
    if ((scrollableItems.offsetWidth + scrollableItems.scrollLeft) >= scrollableItems.scrollWidth - triggerThreshold) {
      // End of scroll list
      fadeRight.style.opacity = 0;
      fadeRight.style.visibility = 'hidden';
    } else {
      fadeRight.style.opacity = 1;
      fadeRight.style.visibility = 'visible';
    }
    if (scrollableItems.scrollLeft <= 0 + triggerThreshold) {
      // Start of scroll list
      fadeLeft.style.opacity = 0;
      fadeLeft.style.visibility = 'hidden';
    } else {
      fadeLeft.style.opacity = 1;
      fadeLeft.style.visibility = 'visible';
    }
  }

  onScroll(e): void {
    // Some devices don't completely reach the end of a scroll. A threshold in pixels is used to compensate for that.
    const triggerThreshold = 32;

    const fadeRight = [].slice.call(e.target.children).find((element) => {
      return element.className === 'fade-right';
    });
    const fadeLeft = [].slice.call(e.target.children).find((element) => {
      return element.className === 'fade-left';
    });
    if ((e.target.offsetWidth + e.target.scrollLeft) >= e.target.scrollWidth - triggerThreshold) {
      // End of scroll list
      fadeRight.style.opacity = 0;
      fadeRight.style.visibility = 'hidden';
    } else {
      fadeRight.style.opacity = 1;
      fadeRight.style.visibility = 'visible';
    }
    if (e.target.scrollLeft <= 0 + triggerThreshold) {
      // Start of scroll list
      fadeLeft.style.opacity = 0;
      fadeLeft.style.visibility = 'hidden';
    } else {
      fadeLeft.style.opacity = 1;
      fadeLeft.style.visibility = 'visible';
    }
  }

  slide(id: string, direction: string, pixels: number): void {
    // scrolls horizontally depending on the given direction.
    let list = document.getElementById(id);
    list.scrollBy({
      left: direction === 'right' ? pixels : -pixels,
      behavior: 'smooth'
    });
  }

  private initAutoCompleteSearch() {

    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);

    // When the search field is cleared, restore the default filter
    this.searchElementRef.nativeElement.onchange = () => {
      if (this.searchElementRef.nativeElement.value.length === 0) {
        const lat = this.myLocation.latitude;
        const lng = this.myLocation.longitude;
        this.ngZone.run(() => {
          // 10km radius filter
          this.applyLatLngFilter(lat, lng);
        });
      }
    };

    autocomplete.addListener('place_changed', () => {
      // get the place result
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      let lat;
      let lng;

      // verify result
      if (place.geometry === undefined || place.geometry === null) {
        lat = this.myLocation.latitude;
        lng = this.myLocation.longitude;
      } else {
        lat = place.geometry.location.lat();
        lng = place.geometry.location.lng();
      }

      this.ngZone.run(() => {
        // 10km radius filter
        this.applyLatLngFilter(lat, lng);
      });
    });
  }

  selectLocation(location: MerchantLocation): void {
    if (this.loadingMenu) {
      return;
    }
    this.loadingMenu = true;
    this.gateway.getLocationMenu(location.node_id)
      .then((menuResponse: LocationMenuResponse) => {
        this.loadingMenu = false;
        if (!menuResponse.success) {
          return this.onLocationMenuError(menuResponse.error_code + ': ' + menuResponse.message);
        }
        this.router.navigateByUrl(
          `/brand/${this.brand._id}/menu/${location.node_id}`, { state: { brand: this.brand, location, menuResponse } });
      })
      .catch((err) => this.onLocationMenuError(err));
  }

  onLocationMenuError(error): void {
    this.loadingMenu = false;
    console.error(error);
    this.alertService.showError(
      this.translate.instant('ts.error!'),
      this.translate.instant('ts.locationIsCurrentlyUnavailable')
    );
  }

  back(): void {
    this.router.navigate(['']);
  }

  markerClicked(location: MerchantLocation): void {
    const locationCard = document.getElementById(location.node_id);
    locationCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
    locationCard.style.border = '3px solid orange';
    setTimeout(() => locationCard.style.border = '1px solid transparent', 2000);
  }

  // old loadLocation Func
  private loadLocation(nodeId: string, type: string = 'organization'): void {

    this.loadingLocations = true;
    this.gateway.loadLocations(nodeId, type)
      .then((locations: MerchantLocation[]) => {
        this.loadingLocations = false;
        this.onLocation(locations);
      })
      .catch((err) => {
        console.error(err);
        this.loadingLocations = false;
        this.alertService.showError(
          this.translate.instant('ts.error!'),
          this.translate.instant('ts.failedToLoadLocations')
        );
      });
  }

  private onLocation(locations: MerchantLocation[]) {
    this.locations.length = 0;
    this.locations.push(...locations);
    this.nearbyLocations.push(...locations);

    this.locationService.findMyCoordinate().then((position) => {
      this.myLocation = position;
      this.applyLatLngFilter(position.latitude, position.longitude);
    });
  }

  private applyBoundsFilter(bounds): void {
    this.locationService.filterLocationsByBounds(this.locations, bounds)
      .then(filteredLocations => {
        if (!filteredLocations || filteredLocations.length === 0) {
          return;
        }
        this.ngZone.run(() => {
          this.nearbyLocations.length = 0;
          this.nearbyLocations.push(...filteredLocations);
        });
      });
  }

  private applyLatLngFilter(latitude: number, longitude: number): void {
    this.locationService.filterLocationsByLatLong(this.locations, latitude, longitude)
      .then(filteredLocations => {
        if (!filteredLocations || filteredLocations.length === 0) {
          return;
        }
        this.nearbyLocations.length = 0;
        this.nearbyLocations.push(...filteredLocations);
        this.zoomMapToFitPins(filteredLocations);
      });
  }

  private zoomMapToFitPins(pins: MerchantLocation[]) {
    if (!pins || pins.length === 0) {
      return;
    }
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    for (const mm of pins) {
      bounds.extend(new google.maps.LatLng(mm.latitude, mm.longitude));
    }
    this.map.fitBounds(bounds);
    if (!this.mapsZoomEventListener) {
      this.mapsZoomEventListener = new google.maps.event.addListener(this.map, 'idle', () => {
        this.applyBoundsFilter(this.map.getBounds());
      });
    }
  }

  loadGiftCard(img: Image): void {
    this.onGiftCardLoad(img);
  }

  onGiftCardLoad(img: Image): void {
    if (this.modalOpened) {
      return;
    }
    this.modalOpened = true;

    const initialState = {
      brand: this.brand,
      giftCardImage: img.url
    };

    const modalRef = this.modalService.show(GiftCardOptionsComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'gift-card-options-modal'
    });
    modalRef.content.onClose.subscribe(() => {
      this.modalOpened = false;
      this.removeGiftCardQueryParam();
    });

    if (img.id) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { gc: img.id },
        queryParamsHandling: 'merge'
      });
    }
  }

  removeGiftCardQueryParam(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { gc: null },
      queryParamsHandling: 'merge'
    });
  }
}
