<div class="confirm-box">
    <div class="confirm-title">{{ title }}</div>
    <div class="confirm-description" *ngIf="description">{{ description }}</div>
    <div [ngClass]="{'vertical-spacing-12': description, 'vertical-spacing-24': !description}"></div>
    <div class="btn-row">
        <div class="cancel-btn grey-button" (click)="cancel()">
            {{ 'checkOut.cancel' | translate }}
        </div>
        <div class="confirm-btn yellow-button" (click)="confirm()">
            {{ 'checkOut.confirm' | translate }}
        </div>
    </div>
</div>