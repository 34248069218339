import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule, ErrorHandler } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { ErrorHandlerModule } from './error-handler.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RoutingModule } from './routing.module';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AuthenticationService } from '../services/security/authentication.service';
import { EnvironmentService } from '../services/environment/environment.service';
import { LucovaGatewayService } from '../services/lucova-gateway/lucova-gateway.service';
import { CookieService } from 'ngx-cookie-service';
import { OrderService } from '../services/order/order.service';
import { LocationService } from '../services/location/location.service';
import { AlertService } from '../services/alert/alert.service';

import { MainComponent } from '../components/main/main.component';
import { SplashScreenComponent } from '../components/splash-screen/splash-screen.component';
import { FooterComponent } from '../components/footer/footer.component';
import { LocationMenuComponent } from '../components/location-menu/location-menu.component';
import { BrandsListComponent } from '../components/brands-list/brands-list.component';
import { TileMenuItemComponent } from '../components/common/ui-elements/tile-menu-item/tile-menu-item.component';
import { TileGiftCardComponent } from '../components/common/ui-elements/tile-gift-card/tile-gift-card.component';
import { LocationsListComponent } from '../components/locations-list/locations-list.component';
import { ItemModifiersComponent } from '../components/common/modals/item-modifiers/item-modifiers.component';
import { GiftCardOptionsComponent } from '../components/common/modals/gift-card-options/gift-card-options.component';
import { CheckOutComponent } from '../components/common/modals/check-out/check-out.component';
import { SignupComponent } from '../components/common/modals/signup/signup.component';
import { LegalComponent } from '../components/common/modals/legal/legal.component';
import { SearchByNamePipe } from '../filters/search-by-name';
import { environment } from 'src/environments/environment';
import { FaviconService } from '../services/favicon/favicon.service';
import { LoadingScreenComponent } from '../components/common/ui-elements/loading-screen/loading-screen.component';
import { SelectLanguageComponent } from '../components/common/ui-elements/select-language/select-language.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ErrorComponent } from '../components/common/modals/error/error.component';
import { ConfirmComponent } from '../components/common/modals/confirm/confirm.component';
import { AddGiftCardComponent } from '../components/common/modals/add-gift-card/add-gift-card.component';
import { Injector, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { RedirectComponent } from '../components/common/modals/redirect/redirect.component';

 
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const langToSet = localStorage.getItem('NownWebOrderLanguagePreference') || 'en-CA';
    translate.setDefaultLang(langToSet);
    try {
      await translate.use(langToSet).toPromise();
    } catch (err) {
      console.error(`Problem with ${langToSet} language initialization.`);
    }
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    MainComponent,
    SplashScreenComponent,
    FooterComponent,
    BrandsListComponent,
    LocationMenuComponent,
    TileMenuItemComponent,
    TileGiftCardComponent,
    LocationsListComponent,
    ItemModifiersComponent,
    GiftCardOptionsComponent,
    CheckOutComponent,
    SignupComponent,
    ErrorComponent,
    ConfirmComponent,
    AddGiftCardComponent,
    LegalComponent,
    SearchByNamePipe,
    LoadingScreenComponent,
    SelectLanguageComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RoutingModule,
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    CreditCardDirectivesModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerModule },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    EnvironmentService,
    CookieService,
    LucovaGatewayService,
    AuthenticationService,
    OrderService,
    LocationService,
    FaviconService,
    AlertService
  ],
  bootstrap: [MainComponent]
})
export class MainModule { }
