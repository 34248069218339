import { CommonModule } from '@angular/common';
import * as Honeybadger from 'honeybadger-js';
import { NgModule, ErrorHandler } from '@angular/core';
import { EnvironmentService } from '../services/environment/environment.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ErrorHandlerModule implements ErrorHandler {

  constructor(private env: EnvironmentService) {
    if (!this.env.isDevelopment()) {
      Honeybadger.configure({
        apiKey: this.env.getHoneyBadgerApiKey(),
        environment: this.env.getEnvName(),
        revision: this.env.getAppRevision()
      });
    }
  }

  handleError(error) {
    console.log(error);
    // if (this.env.isDevelopment()) {
    //   return console.error(error.originalError || error);
    // }
    // // Check that we actually have a valid honeybadger instance before
    // // we fire off any errors
    // try {
    //   Honeybadger.notify(error.originalError || error);
    // } catch (error) {
    //   console.error(error);
    // }
  }
}
