import { Injectable } from '@angular/core';
import { View } from 'src/app/models/cart.model';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LucovaPreorder } from 'src/app/models/preoder-status.model';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    public sendOrderEvent: Subject<LucovaPreorder> = new Subject();
    private cart: any[] = new Array<any>();

    constructor(
        private alertService: AlertService,
        private translate: TranslateService
    ) {}

    public emptyCart() {
      this.cart = [];
    }

    public setCart(cart: Array<any>) {
        this.cart = cart;
    }

    public getCart(): Array<any> {
        return this.cart;
    }

    public handleCheckoutError(response: any, extraInfo?: {params?: LucovaPreorder}): string {
        let switchViewTo: string = View.CART;

        let title: string = this.translate.instant('ts.error!');
        let description: string = this.translate.instant('checkOut.general.error.msg');
        let proceedCallBack = null;

        switch(response.error_code) {
            case 106: // Card Declined
                switchViewTo = View.PAYMENT_INFO;
                description = response.message;
            break;
            case 3071: // 3DS Carding Challenge
                switchViewTo = View.PAYMENT_INFO;

                title = this.translate.instant('checkOut.3ds.card.challenge.ttl');
                description = this.translate.instant('checkOut.3ds.challenge.msg');
                proceedCallBack = () => {
                    let htmlBlob = new Blob([decodeURIComponent(response.contents)], {type: 'text/html'});
                    let url = URL.createObjectURL(htmlBlob);
                    const redirectOnClose = () => {
                        if (extraInfo.params) {
                            extraInfo.params.card_info = undefined;
                            this.sendOrderEvent.next(extraInfo.params);
                        }
                    }
                    this.alertService.showRedirect(url, redirectOnClose); // redirect to complete 3DS challenge
                };

                this.alertService.showError(title, description, proceedCallBack);
            break;
            case 3072: // 3DS Payment Challenge
                switchViewTo = View.PAYMENT_INFO;

                let htmlBlob = new Blob([decodeURIComponent(response.contents)], {type: 'text/html'});
                let url = URL.createObjectURL(htmlBlob);
                const redirectOnClose = (is3DSSuccess: boolean) => {
                    if (extraInfo.params) {
                        extraInfo.params.is_3ds_auth_completed = is3DSSuccess;
                        extraInfo.params.card_info = undefined;
                        this.sendOrderEvent.next(extraInfo.params);
                    }
                }

                this.alertService.showRedirect(url, redirectOnClose); // redirect to complete 3DS challenge
            break;
            default:
                // do nothing
        }

        return switchViewTo;
    }

}
