import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LucovaGatewayService } from 'src/app/services/lucova-gateway/lucova-gateway.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.less']
})
export class LegalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('page') page: ElementRef;

  public onClose: Subject<any> = new Subject();

  constructor(private modalRef: BsModalRef,
              private gateway: LucovaGatewayService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.gateway.getLegalpage()
    .then((res) => {
      this.processPage(res);
    }).catch((error) => {
      console.log(error);
    });
  }

  processPage(response) {
    console.log(response);
  }

  ngOnDestroy(): void {
  }

  close(): void {
    this.modalRef.hide();
  }
}
