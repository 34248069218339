import { Injectable } from '@angular/core';
import { ErrorComponent } from 'src/app/components/common/modals/error/error.component';
import { ConfirmComponent } from 'src/app/components/common/modals/confirm/confirm.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RedirectComponent } from 'src/app/components/common/modals/redirect/redirect.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private modalService: BsModalService) {}

  // shows a basic error message
  public showError(title: string, description: string, callBack?: Function | null): void {
    const initialState = {
      title: title,
      description: description
    };

    const modalRef = this.modalService.show(ErrorComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'error-modal'
    });

    modalRef.content.onClose.subscribe(() => {
      if (callBack) {
        callBack();
      }
    });
  }

  // shows a confirm message modal
  public showConfirm(title: string, description: string, confirmCallback: any): void {
    const initialState = {
      title: title,
      description: description,
      confirmCallback: confirmCallback
    };

    const modalRef = this.modalService.show(ConfirmComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'confirm-modal'
    });

    modalRef.content.onClose.subscribe(() => {
    });
  }

  // present in-app redirection modal on demand
  public showRedirect(redirectUrl: string, redirectOnClose?: Function) {
    const initialState: {url: string, onClose?: Function} = {
      url: redirectUrl,
      onClose: redirectOnClose
    };

    this.modalService.show(RedirectComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'redirect-modal'
    });
  }

}
