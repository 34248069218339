import { Component, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { SignupComponent } from 'src/app/components/common/modals/signup/signup.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { LucovaGatewayService } from 'src/app/services/lucova-gateway/lucova-gateway.service';
import { OrderService } from 'src/app/services/order/order.service';
import { OrderTrackingService } from 'src/app/services/order-tracking/order-tracking.service';
import { ItemModifiersComponent } from '../item-modifiers/item-modifiers.component';
import { MerchantLocation } from 'src/app/models/merchant-location.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AddGiftCardComponent } from '../add-gift-card/add-gift-card.component';
import { TranslateService } from '@ngx-translate/core';

import { LucovaPreorder, Status } from 'src/app/models/preoder-status.model';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { View } from 'src/app/models/cart.model';

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.less']
})
export class CheckOutComponent implements OnInit, OnDestroy, DoCheck {
  // cart preview screen & scheduled order vars
  cart: any[];
  scheduledOrderTime: any;
  availableTimes: Array<any> = [];

  itemTotals: any = {
    allTotals: {
      discount: 0,
      subtotal: 0,
      tax: 0,
      tip: 0,
      total: 0,
      quantity: 0
    }
  };

  tipOptions: any = {
    '.10': {
      selected: false,
      amountCents: 0
    },
    '.15': {
      selected: false,
      amountCents: 0
    },
    '.20': {
      selected: false,
      amountCents: 0
    }
  };

  // payments screen vars
  savedUser: any;
  addingNewCreditCard = false;
  selectedCreditCard: any;
  toSavePaymentMethod = false;
  tenders: Array<any> = [];
  creditCards: Array<any> = [];
  giftCards: Array<any> = [];
  defaultCardId: string;
  creditCardInfo: any = {
    name: '',
    number: '',
    expDate: '',
    cvv: '',
    postalCode: ''
  };
  creditCardError: string;

  // redeemGiftCard indicates if this order will be redeeming a gift card.
  // isGiftCardOnly indicates if this order only uses a gift card for this transaction.
  redeemGiftCard = false;
  isGiftCardOnly = false;

  // these vars are generally for backwards compatibility
  // where web order didn't require/use emails for signups
  email: string;
  emailValidation = true;
  emailValidationTimer = 0;
  requestingEmailReceipt = false;
  emailReceiptRequested = true;
  emailingReceipt = true;

  isPurchasingGiftCard = false;
  giftCardPackage: any;

  isDeletingCard: boolean = false;

  sentPreorder = false;
  sentGiftCard = false;

  brand: any;
  location: MerchantLocation;
  locationAddressForMaps: string;

  isPlacingOrder = false;

  currentView = View.CART;

  relevantAppLink: string;
  relevantAppName: string;

  oldStatus: string;
  showSpinner: boolean;
  maskedTenderCardNum: string;
  public onClose: Subject<any> = new Subject();

  constructor(private env: EnvironmentService,
              private modalService: BsModalService,
              public authenticationService: AuthenticationService,
              private gateway: LucovaGatewayService,
              public modalRef: BsModalRef,
              public orderService: OrderService,
              public orderTrackingService: OrderTrackingService,
              public alertService: AlertService,
              private translate:TranslateService) {
  }

  preventNonTabKeys(e: any): void {
    if (e.key === 'Tab') {
      return;
    }
    e.preventDefault();
  }

  inputNumbersOnly(e: any, maxLength: number, field: string): void {
    e.preventDefault();
    if ((e.key < '0' || e.key > '9') && e.key !== 'Backspace') {
      return;
    }
    switch (field) {
      case 'card-number':
        if (this.creditCardInfo.number.length >= maxLength && e.key !== 'Backspace') {
          return;
        }
        if (e.key === 'Backspace') {
          this.creditCardInfo.number = this.creditCardInfo.number.slice(0, -1);
        } else {
          this.creditCardInfo.number += e.key;
        }
        break;
      case 'expiry-date':
        if (this.creditCardInfo.expDate.length >= maxLength && e.key !== 'Backspace') {
          return;
        }
        if (this.creditCardInfo.expDate.length === 2 && e.key !== 'Backspace') {
          this.creditCardInfo.expDate += '/';
        }
        if (e.key === 'Backspace') {
          this.creditCardInfo.expDate = this.creditCardInfo.expDate.slice(0, -1);
        } else {
          this.creditCardInfo.expDate += e.key;
        }
        if (this.creditCardInfo.expDate[this.creditCardInfo.expDate.length - 1] === '/') {
          this.creditCardInfo.expDate = this.creditCardInfo.expDate.slice(0, -1);
        }
        break;
      case 'cvv':
        if (this.creditCardInfo.cvv.length >= maxLength && e.key !== 'Backspace') {
          return;
        }
        if (e.key === 'Backspace') {
          this.creditCardInfo.cvv = this.creditCardInfo.cvv.slice(0, -1);
        } else {
          this.creditCardInfo.cvv += e.key;
        }
        break;
    }
  }

  populateAvailableTimes(): void {
    // clear availableTimes
    this.availableTimes.length = 0;

    // Every 15 minute interval between the business hours
    // Minimum: 1 hour from the current time
    // Maximum: 30 minutes before closing time.

    this.availableTimes.push({
      timestring: 'ASAP',
      timestamp: moment().unix()
    });

    let asap = moment().add(1, 'hours');
    let remainder = 15 - (asap.minute() % 15);
    let start = moment(asap).add(remainder, "minutes");
    let end = moment.unix(this.location.current_hours_range.end).subtract(30, 'minutes');

    // do not continue if first scheduled time is over 30 minutes before closing time.
    if (start.unix() >= end.unix()) {
        return;
    }

    this.availableTimes.push({
      timestring: start.format('LT'),
      timestamp: start.unix()
    });

    // loop to iterate and add time every 15 minutes until the iteration's timestamp
    // is no longer within the end time.
    let time;
    while (this.availableTimes) {
      time = start.add(15, 'minutes');
    
      if (time.unix() >= end.unix()) {
        // break out of loop if time is over the end
        break;
      } else {
        this.availableTimes.push({
          timestring: time.format('LT'),
          timestamp: time.unix()
        });
      }
    }
  };

  ngOnInit(): void {
    // if an order is already in progress
    this.cart = this.orderService.getCart();
    if (this.orderTrackingService.isOrderInProgress()) {
      const orderDetails = JSON.parse(localStorage.getItem('order_details'));
      if (orderDetails) {
        this.maskedTenderCardNum = orderDetails.masked_card_number;
        this.location = orderDetails.location;
        this.relevantAppLink = orderDetails.appLink;
        this.relevantAppName = orderDetails.appName;

        // set redeemGiftCard to false by default if user is purchasing a DGC
        this.isPurchasingGiftCard ? this.redeemGiftCard = false : this.redeemGiftCard = orderDetails.redeemGiftCard;
      }
      this.sentPreorder = true;
      this.calculateTotals(this.orderTrackingService.getOrderItems(), true);
    } else {
      this.calculateTotals(this.cart);
    }

    if (this.location) {
        this.locationAddressForMaps = this.location.street_address.replace(' ', '+');
    }

    // only populate available times if not purchasing a gift card.
    if (!this.isPurchasingGiftCard) {
      this.populateAvailableTimes();
      this.scheduledOrderTime = this.availableTimes[0];
    }

    this.authenticationService.checkIfAuthenticated()
    .then((res) => {
      if (res.success) {
        this.savedUser = res.data;
      }
    }).catch((error) => {
      console.log(error);
    });

    this._listenPreorderEvent();
  };

  ngDoCheck(): void {
    if (this.isPurchasingGiftCard) {
      return;
    }

    // only perform the following checks if it's not purchasing a gift card.
    const newStatus = this.orderTrackingService.getPreorderStatus().status;
    if (this.oldStatus === newStatus) {
      return;
    }
    // scheduled order
    // added a check for timestring, because sometimes preorder endpoint will send back a pending status instead of
    // print_pending. This ensures that the cart screen will only show for scheduled orders and not for asap orders
    if (this.scheduledOrderTime.timestring != 'ASAP' && newStatus === Status.PENDING) {
      this.switchView(View.CART);
      this.sendEmailReceipt();
    }
    // asap order
    if (newStatus === Status.PRINT_PENDING) {
      this.switchView(View.PENDING_ORDER);
    }
    // if status changes from print_pending to cancelled or completed
    if (this.oldStatus === Status.PRINT_PENDING) {
      this.showSpinner = true;
      if (newStatus === Status.CANCELLED) {
        return this.switchView(View.CART);
      }
      setTimeout(() => {
        this.showSpinner = false;
        this.switchView(View.CART);
      }, 2000);
    }
    if (newStatus === Status.COMPLETED) {
        this.sendEmailReceipt();
    }
    this.oldStatus = newStatus;
  };

  validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  checkEmailInput(email: string): any {
    this.emailValidationTimer = 0;
    setTimeout(() => {
      if (!this.validateEmail(email)) {
          this.emailValidation = false;
      } else {
            this.emailValidation = true;
      }
    }, 1000);
  };

  private validateEmails(emails: string) {
    if (!emails) {
      return false;
    }
    const emailsSplit = emails.replace(/\s/g, '').split(',');
    let isValid = true;

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (const char of emailsSplit) {
        if (char === '' || ! regex.test(char)) {
            isValid = false;
        }
    }
    return isValid;
  };

  calculateTips(): void {
    for (const tip in this.tipOptions) {
      if (this.tipOptions.hasOwnProperty(tip)) {
        this.tipOptions[tip].amountCents = Number((Number(tip) * this.itemTotals.allTotals.subtotal).toFixed(0));
      }
    }
  }

  calculateTotals(items: Array<any>, isReceipt: boolean = false): void {
    this.itemTotals = {
      allTotals: {
        discount: 0,
        subtotal: 0,
        tax: 0,
        tip: 0,
        total: 0
      }
    };
    items.forEach((item, index) => {
      let itemQuantity = item.quantity || 1;  // Ensure there's a default quantity
      let itemCost = item.amount_cents * itemQuantity;  // Base cost of the item multiplied by its quantity
      if (!this.itemTotals[index]) {
        this.itemTotals[index] = {
          subtotal: 0,
          tax: 0
        };
      }

      if (!item.price_replaced_by_id_v2) {
        this.itemTotals[index].subtotal += itemCost;
        this.itemTotals.allTotals.subtotal += itemCost;

        if (item.discount_cents) {
            this.itemTotals.allTotals.discount += item.discount_cents;
        }

        this.itemTotals[index].tax += Math.round(item.amount_cents * item.tax_rate);
        this.itemTotals.allTotals.tax += Math.round(item.amount_cents * item.tax_rate);
      }

      if (item.children) {
        item.children.forEach((modifier) => {
          if (modifier.children) {
            modifier.children.forEach((option) => {
              /* Receipts are different in a way that it only contains modifier options that have been selected,
              whereas the cart by default will have all modifier options and a flag to determine whether or not
              they have been selected. Therefore we must not skip any iterations if it is a receipt. */
              if (!isReceipt && !option.selected) {
                return;
              }
              
              let optionCost = option.amount_cents * itemQuantity;
              this.itemTotals[index].subtotal += optionCost;
              this.itemTotals[index].tax += Math.round(optionCost * option.tax_rate);
              this.itemTotals.allTotals.subtotal += optionCost;
              this.itemTotals.allTotals.tax += Math.round(optionCost * option.tax_rate);

              if (option.discount_cents) {
                this.itemTotals.allTotals.discount += option.discount_cents;
              }

              this.itemTotals.allTotals.tax += Math.round(option.amount_cents * option.tax_rate);
            });
          }
        });
      }
      
      this.calculateTips();
      this.itemTotals.allTotals.tip = 0;
      if (!isReceipt) {
        for (const tip in this.tipOptions) {
          if (this.tipOptions[tip].selected) {
            this.itemTotals.allTotals.tip = this.tipOptions[tip].amountCents;
            break;
          }
        }
      } else {
        this.itemTotals.allTotals.tip = this.orderTrackingService.getOrderTipCents();
      }
      this.itemTotals.allTotals.total = this.itemTotals.allTotals.subtotal - this.itemTotals.allTotals.discount + this.itemTotals.allTotals.tax + this.itemTotals.allTotals.tip;
    });
  }

  removeFromCart(cartIndex): void {
    this.cart.splice(cartIndex, 1);
    this.orderService.setCart(this.cart);
    this.calculateTotals(this.cart);
    if (!this.cart.length) {
      this.back();
    }
  }

  editItem(item: any, index: number): void {
    if (!(item.children && item.children.length)) {
      return;
    }
    const itemCopy = JSON.parse(JSON.stringify(item));
    const initialState = {
      isEdit: true,
      item: itemCopy,
      quantity: item.quantity || 1
    };
    const modalRef = this.modalService.show(ItemModifiersComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'item-modifiers-modal'
    });
    modalRef.content.onClose.subscribe((result: any) => {
      if (result) {
        this.cart[index] = result;
        this.calculateTotals(this.cart);
      }
    });
  }

  addGiftCard(): void {
    const initialState = {
      appFid: this.gateway.getSelectedBrand().app_fid,
      brandName: this.brand.node_name,
      topNodeId: this.brand.app_node_id,
      nodeFid: this.brand.node_fid,
      digitalGiftCardEnabled: this.brand.digital_gift_card_settings.enabled
    };

    const modalRef = this.modalService.show(AddGiftCardComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'add-gift-card-modal'
    });
    modalRef.content.onClose.subscribe(() => {
      this.populateGiftCards();
    });
  }

  sendGiftCard(): void {
    let creditCard = undefined;

    // only get credit card input if existing user is using a new payment method
    if (this.savedUser && this.addingNewCreditCard) {
      creditCard = this.creditCards.pop();
      if (creditCard && creditCard.card_number) {
        creditCard.card_number = creditCard.card_number.replace(/\s+/g, '');
      }
    }

    // placing a gift card order.
    this.cart.forEach(giftCard => {

        giftCard.receipt_email = this.email;

        this.gateway.sendGiftCard(giftCard, creditCard)
          .then((response) => {
            this.isPlacingOrder = false;
            if (!response.success) {
              this.alertService.showError(
                this.translate.instant('ts.error!'),
                this.translate.instant('ts.failedToCreateEGiftCard')
              )
              if (response.error_code === 106) { // Card Declined
                this.switchView(View.CART);
              }
              return;
            }

            const orderDetails = {
              location: this.location,
              masked_card_number: this.selectedCreditCard ? this.selectedCreditCard.masked_num : `****${creditCard.card_number.substring(creditCard.card_number.length - 4)}`,
              appLink: null,
              appName: null
            };
    
            const appLink = response.app_link;
            if (appLink) {
              if (navigator.platform.indexOf('Android') !== -1 || navigator.platform.indexOf('Linux') !== -1) {
                this.relevantAppLink = appLink.platform.android;
              }
              if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPad') !== -1) {
                this.relevantAppLink = appLink.platform.ios;
              }
              this.relevantAppName = appLink.app_name;
              orderDetails.appLink = this.relevantAppLink;
              orderDetails.appName = this.relevantAppName;
            }
    
            localStorage.setItem('order_details', JSON.stringify(orderDetails));
    
            this.giftCardPackage = this.cart[0];
            this.sentGiftCard = true;
            this.cart.length = 0;
            // send to gift card screen directly.
            this.switchView(View.CART);

          }).catch((error) => {
            this.switchView(View.CART);
            this.isPlacingOrder = false;
            console.log(error);
            this.alertService.showError(
              this.translate.instant('ts.errorPurchasingGiftCard'),
              this.translate.instant('ts.yourCreditCardHasNotBeenCharged')
            )
          });
    });
  }

  /**
   *  this function sets the payment methods and returns a credit card if the user wants to use a new payment method.
   *  note that creditCard can be undefined if the user is using a saved payment method.
  **/
  setPaymentMethods(): Promise<any> {
    // a promise of the following is returned based on conditions:
    // 1. creditCard obj: user using a new payment method
    // 2. response from /user/credit-cards: user is saving the above credit card
    // 3. response from /user/credit-cards/${card.id}: user is switching between saved credit cards
    // 4. undefined - when user is simply using the default saved card
    let promise;
    let creditCard = undefined;
    // only get credit card input if:
    // user using a new payment method
    if (this.savedUser && this.addingNewCreditCard) {
      creditCard = this.creditCards.pop();
      if (creditCard && creditCard.card_number) {
        creditCard.card_number = creditCard.card_number.replace(/\s+/g, '');
      }

      promise = new Promise((resolve) => {
        resolve(creditCard);
      });
    }

    // only save credit card if save checkbox and input for credit card info has been added
    if (this.toSavePaymentMethod && creditCard) {
      promise = this.gateway.savePaymentMethod(creditCard);
    }

    // if a user selects a different card on their saved credit card list,
    // we'll have to perform an update to the default card id.
    // this must be done before the order is sent
    // otherwise the previous default card will be charged.
    if (this.selectedCreditCard && this.savedUser) {
      if (this.selectedCreditCard.id != this.savedUser.default_card_id) {
        promise = this.gateway.setDefaultPaymentMethod(this.selectedCreditCard);
      }
    }

    return promise || new Promise((resolve) => resolve(undefined));
  };

  /**
   * possible scenarios for Gift Cards (GC) and Credit Cards (CC):
   *
   * has saved GCs, no CCs saved
   * 1. saved GCs can cover transaction amount
   * 2. saved GCs cannot cover transaction amount, needs to add a CC
   * 3. saved GCs can cover, but user refuses redemption. need to add a CC
   *
   * has saved CCs, no GCs saved
   * 1. saved CCs covers transaction amount
   * 2. claims GCs to cover transaction amount
   * 3. claims GCs, but not enough to cover transaction amount, saved CC covers remaining
   *
   * has no GCs, no CCs saved
   * 1. creates new CC to cover transaction amount
   * 2. claims GCs to cover transaction amount
   * 3. claims GCs, but not enough to cover transaction amount, needs to add a CC
   *
   * has saved GCs and CCs
   * 1. saved GCs can cover transaction amount
   * 2. saved GCs cannot cover transaction amount, saved CCs covers remaining
   * 3. saved GCs cannot cover transaction amount, user wants to use a new CC
   * 4. saved GCs can cover, but user refuses redemption. saved CCs cover remaining
   *
  */

  // mock preorders are sent to calculate
  // the amount that will be charged for each tender type
  sendMockPreorder(): void {
    // only send mock preorder if the user ISN'T purchasing a gift card.
    // if user is purchasing gift card, always use credit card.
    if (this.isPurchasingGiftCard) {
      const cardInfo = {
        key: 'Credit Card',
        val: '$' + (this.itemTotals.allTotals.total / 100).toFixed(2)
      }

      this.tenders = [];
      this.tenders.push(cardInfo);
      return;
    }

    const newCart = this.cleanItems();
    let params: LucovaPreorder = {
      items: newCart,
      tip_cents: this.itemTotals.allTotals.tip,
      target_timestamp: this.scheduledOrderTime.timestamp,
      redeem_gift_card: this.redeemGiftCard,
      mock: true
    };

    // empty tenders array for recalculation
    this.tenders = [];
    this.gateway.sendPreorder(params).then((res) => {
      if (res.success) {
        this.tenders = res.preorder.line_items.filter((tender) => {
          // we do not need to display 'tax' as a separate tender type
          if (tender.key != 'Tax') {
            return tender;
          }
        });

        // re-populate itemTotals tax with calculations from calculation server.
        // this is necessary as this.itemTotals were done on the frontend.
        // this is a discrepency approved by riley, where calculations on the cart preview screen (incorrect)
        // may differ from the payments screen (the correct calculation from mocking a preorder).
        // reason we have a frontend calculation on cart screen is due to the fact that mock preorders
        // cannot be sent without a logged in user (signup/login isn't prompted until after cart screen)

        let tax = res.preorder.line_items.find(tender => tender.key === 'Tax');

        // if the added items are not taxable, line_items will not return a tax field.
        // in this scenario, we can simply set tax field to 0 to avoid an undefined.val issue.
        if (tax) {
          // remove dollar sign here.
          let calculatedTax = tax.val.split('').splice(1, tax.val.length).join('');
          this.itemTotals.allTotals.tax = (Number.parseFloat(calculatedTax) * 100);
        } else {
          this.itemTotals.allTotals.tax = 0;
        }

        this.itemTotals.allTotals.total = this.itemTotals.allTotals.subtotal - this.itemTotals.allTotals.discount + this.itemTotals.allTotals.tax + this.itemTotals.allTotals.tip;


        // hide the credit card tiles if gift cards can cover the whole transaction
        let creditCard = this.tenders.find(tender => tender.key === 'Credit Card');
        let giftCard = this.tenders.find(tender => tender.key === 'Gift Card');
        
        if (giftCard && !creditCard) {
          this.isGiftCardOnly = true;
        } else {
          this.isGiftCardOnly = false;

          // in the case there are no GCs or GCs cannot cover the entire transaction
          // and the user doesn't have a saved credit card, they will
          // need to add one.
          if (!this.creditCards.length && !this.giftCards.length) {
            this.addingNewCreditCard = true;
          } else {
            this.addingNewCreditCard = false;
          }
        }
      }
    });
  };

  private _listenPreorderEvent(): void {
    this.orderService.sendOrderEvent
    // listen to event untill component closes and automically unsubscribe onclose
    // this remove scope for having multiple subsribers on this component
    .pipe(takeUntil(this.onClose))
    .subscribe((params: LucovaPreorder) => {
      this.isPlacingOrder = true;

      this.gateway.sendPreorder(params).then((response) => {
        let creditCard = params.card_info;

        // error handling
        if (!response.success) {
          let switchViewTo: string = this.orderService.handleCheckoutError(response, {params: params});
          this.switchView(switchViewTo);
          this.isPlacingOrder = false;

          return;
        }

        // masked card num may or may not exist (if user uses only gift card and had no credit cards saved.)
        if (this.selectedCreditCard) {
          this.maskedTenderCardNum = this.selectedCreditCard.masked_num;
        } else if (creditCard) {
          this.maskedTenderCardNum = `****${creditCard.card_number.substring(creditCard.card_number.length - 4)}`;
        }

        const orderDetails = {
          location: this.location,
          masked_card_number: this.maskedTenderCardNum,
          redeemGiftCard: this.redeemGiftCard,
          appLink: null,
          appName: null
        };

        const appLink = response.app_link;
        if (appLink) {
          if (navigator.platform.indexOf('Android') !== -1 || navigator.platform.indexOf('Linux') !== -1) {
            this.relevantAppLink = appLink.platform.android;
          }
          if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPad') !== -1) {
            this.relevantAppLink = appLink.platform.ios;
          }
          this.relevantAppName = appLink.app_name;
          orderDetails.appLink = this.relevantAppLink;
          orderDetails.appName = this.relevantAppName;
        }

        localStorage.setItem('order_details', JSON.stringify(orderDetails));

        this.cart.length = 0;
        this.sentPreorder = true;
        this.orderTrackingService.loadPreorderStatus();
      }).catch(() => {
        this.switchView(View.CART);
        this.isPlacingOrder = false;
        this.alertService.showError(
          this.translate.instant('ts.failedToPlaceYourOrder'),
          this.translate.instant('ts.pleaseTryAgain')
        )
      });

    });
  }

  sendPreorder(): void {
    const newCart = this.cleanItems();
    
    this.setPaymentMethods().then((res) => {
      let creditCard = undefined;
      // note that for existing users with saved credit cards, creditCard will be undefined.
      // this is OK because payments server can directly use the default card saved.
      // when using a new credit card, creditCard param must be passed in.
      let params: LucovaPreorder = {
        items: newCart,
        card_info: creditCard,
        tip_cents: this.itemTotals.allTotals.tip,
        target_timestamp: this.scheduledOrderTime.timestamp,
        redeem_gift_card: this.redeemGiftCard,
        mock: false
      };

      // if res isn't undefined and contains a card_number
      // this means it is simply a new credit card (unsaved)
      if (res && res.card_number) {
        params.card_info = res;
      }

      // error handling while saving card 
      if (res && res.error_code) {
        let switchViewTo: string = this.orderService.handleCheckoutError(res, {params: params});
        this.switchView(switchViewTo);
        this.isPlacingOrder = false;

        return;
      }

      this.orderService.sendOrderEvent.next(params);
    });
  };

  cancelOrder(): void {
    this.gateway.cancelPreorder(this.orderTrackingService.getPreorderId())
      .then((res) => {
        if (!res.success) {
          this.alertService.showError(
            this.translate.instant('ts.failedToCancelYourOrder'),
            this.translate.instant('ts.pleaseTryAgain')
          )
          return;
        }

        this.orderTrackingService.deletePreorderId();
        this.switchView(View.CART);
      }).catch((error) => {
        console.log(error);
        this.alertService.showError(
          this.translate.instant('ts.failedToCancelYourOrder'),
          this.translate.instant('ts.pleaseTryAgain')
        );
      });
  };

  cleanItems(): any {
    const newCart = JSON.parse(JSON.stringify(this.cart));
    newCart.forEach((item) => {
      const newModifiers = [];
      if (!item.children) {
        return;
      }
      item.children.forEach((modifier) => {
        if (!modifier.children) {
          return;
        }
        delete modifier.selected;
        const newModifierOptions = [];
        if (modifier.children) {
          modifier.children.forEach((option) => {
            if (option.selected) {
              delete option.selected;
              newModifierOptions.push(option);
            }
          });
          modifier.children.length = 0;
        }
        if (newModifierOptions.length) {
          modifier.children.push(...newModifierOptions);
        }
        if (modifier.children && modifier.children.length) {
          newModifiers.push(modifier);
        }
      });
      item.children.length = 0;
      if (newModifiers.length) {
        item.children.push(...newModifiers);
      }
    });
    return newCart;
  };

  signup(): void {
    const modalRef = this.modalService.show(SignupComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'signup-modal'
    });
    modalRef.content.onClose.subscribe((result: any) => {
      // possibility that the user was a new signup.
      // in this case, it would have no data.
      this.savedUser = result;
      // this is necessary to verify no scheduled orders have been placed.
      this.orderTrackingService.loadPreorderStatus();
      this.switchView(View.PAYMENT_INFO);
    });
  }

  addCreditCard(): void {
    // placeholder function
    this.addingNewCreditCard = true;

    // clear selected credit card
    this.selectedCreditCard = undefined;
  }

  cancelAddCreditCard(): void {
    // clear credit card info.
    this.creditCardInfo = {
      // capitalize first name
      name: this.savedUser.first_name ? (this.savedUser.first_name.charAt(0).toUpperCase() + this.savedUser.first_name.slice(1)) : '',
      number: '',
      expDate: '',
      cvv: '',
      postalCode: ''
    };

    this.addingNewCreditCard = false;

    // re-select the default card on the user's profile
    this.selectedCreditCard = this.creditCards.find((card) => {
      return card.id === this.defaultCardId;
    });
  }

  deleteCreditCard(card: any): void {
    // ensure that a preorder is not already pending.
    // ensure that a card is not in progess of being deleted.
    let status = this.orderTrackingService.getPreorderStatus().status;
    if (this.isDeletingCard || status === Status.PENDING || status === Status.PRINT_PENDING || status === Status.ACKNOWLEDGED) {
      return;
    }

    this.isDeletingCard = true;

    var confirmCallback = () => {
      this.gateway.deletePaymentMethod(card, false).then((res) => {
        if (res.success) {
          this.isDeletingCard = false;
          var index = this.creditCards.indexOf(card);

          if (index > -1) {
            this.creditCards.splice(index, 1);
          }

          // if no credit cards remain and no gift cards can be used to redeem, the user needs to add a new card.
          if (!this.creditCards.length && !this.giftCards.length) {
            this.addingNewCreditCard = true;
          }

          // not an error, we are simply using the error modal to display this message.
          this.alertService.showError(
            this.translate.instant('ts.thisCardHasBeenDeleted'),
            this.translate.instant('ts.notAvailableForUse')
          );
        }
      })
    }

    this.alertService.showConfirm(
      this.translate.instant('ts.areYouSureCardDelete'),
      this.translate.instant('ts.haveToEnterCardAgain'),
      confirmCallback);
  }

  deleteGiftCard(card: any, index: number): void {
    // ensure that a preorder is not already pending.
    // ensure that a card is not in progess of being deleted.
    let status = this.orderTrackingService.getPreorderStatus().status;
    if (this.isDeletingCard || status === Status.PENDING || status === Status.PRINT_PENDING || status === Status.ACKNOWLEDGED) {
      return;
    }

    this.isDeletingCard = true;
    // if the card has a balance, we simply remove it visually from the list
    // and set redeem gift card to false.
    // else if card no longer has a balance, we delete it off the user's profile
    let confirmCallback;
    if (card.currentBalanceCents) {
      confirmCallback = () => {
        this.isDeletingCard = false;
        // only set redeemGiftCard to false if no more GCs contain a balance
        this.giftCards.splice(index, 1);
        if (this.giftCards.length) {
          // as long as a single saved GC contains a balance, set redeemGiftCard to true
          let containsBalance = this.giftCards.some((card) => card.currentBalanceCents);
          if (containsBalance) {
            this.redeemGiftCard = true;
          } else {
            this.redeemGiftCard = false;
          }
        } else {
          this.redeemGiftCard = false;
        }

        this.sendMockPreorder();
      };

      this.alertService.showConfirm(
        this.translate.instant('ts.areYouSureRedeem'),
        ``,
        confirmCallback);
    } else {
      confirmCallback = () => {
        this.gateway.deletePaymentMethod(card, true).then((res) => {
          if (res.success) {
            this.isDeletingCard = false;
            // repopulate gift cards and get a new tender calculation via mock preorder
            this.populateGiftCards();
            this.sendMockPreorder();
  
            // not an error, we are simply using the error modal to display this message.
            this.alertService.showError(
              this.translate.instant('ts.thisCardHasBeenDeleted'),
              this.translate.instant('ts.notAvailableForUse')
            );
          }
        })
      };

      this.alertService.showConfirm(
        this.translate.instant('ts.areYouSureCardDelete'),
        '',
        confirmCallback);
    }
  }

  placeOrder(): void {
    this.creditCardError = '';

    // this is for backwards compatability when web order
    // users didn't have emails attached to their accounts.
    // use the savedUser's email if exists
    if (this.savedUser && this.savedUser.email) {
      this.email = this.savedUser.email;
    }

    // check credit card input if user is using a new payment method
    if (this.savedUser && this.addingNewCreditCard) {
      this.creditCardInfo.email = this.email;
      for (const key in this.creditCardInfo) {
        if (!this.creditCardInfo[key]) {
          this.creditCardError = this.translate.instant('ts.fillInMissingInformation');
          return;
        }
      }
  
      const expDate = this.creditCardInfo.expDate.split('/');
  
      if (!(expDate && expDate.length && expDate.length === 2)) {
        this.creditCardError = this.translate.instant('ts.invalidExpirationDate');
        return;
      }
  
      const creditCard = {
        owner_name: this.creditCardInfo.name,
        card_number: this.creditCardInfo.number,
        exp_month: expDate[0],
        exp_year: expDate[1],
        cvv: this.creditCardInfo.cvv,
        postal_code: this.creditCardInfo.postalCode,
        card_brand: 'cc'
      };
      this.creditCards.push(creditCard);
    }

    // send user back to sign up screen in the edge case that they haven't been signed up yet.
    this.authenticationService.checkIfAuthenticated().then((res) => {
      if (!res.success) {
        this.signup();
        return;
      }
    });

    if (this.isPlacingOrder || this.orderTrackingService.isOrderInProgress() || this.sentPreorder) {
      this.switchView(View.CART);
      return;
    }

    this.isPlacingOrder = true;

    if (this.isPurchasingGiftCard) {
      this.sendGiftCard();
    } else {
      this.sendPreorder();
    }
  }

  selectTipOption(percentString: string): void {
    if (this.tipOptions[percentString].selected) {
      this.tipOptions[percentString].selected = !this.tipOptions[percentString].selected;
    } else {
      for (const tip in this.tipOptions) {
        if (this.tipOptions.hasOwnProperty(tip)) {
          this.tipOptions[tip].selected = false;
        }
      }
      this.tipOptions[percentString].selected = true;
    }
    this.calculateTotals(this.cart);
  }

  maskEmail(email: string): string {
    if (!email) {
      return;
    }

    let maskedEmail = email.replace(/^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );

    return maskedEmail;
  }

  // populates gift cards array
  // sets redeemGiftCard to true if at least 1 gift card is saved and contains a balance
  // this endpoint fetches gift card info from nown.
  // we use this to fetch gift card balances as well as hide them if it includes gift cards from other brands
  async populateGiftCards(): Promise<void> {
    // empty the gift cards array first.
    // we do this as we rely on this.gateway.getGiftCardBalances and the subsequent filtering
    // to determine the true list instead of manipulating the array.
    this.giftCards = [];
    let result = await this.gateway.getGiftCardBalances();
    // result is an array, containing gift card info.
    if (result.length) {
      result.forEach((card) => {
        if (card.organizationNodeId === this.brand._id) {
          card.masked_num = card.code.slice(card.code.length - 4);
          this.giftCards.push(card);
        }
      });

      // we make sure at least one saved gift card has a balance.
      // if no saved gift cards have a balance, we do not need to mark redeemGiftCard as true.
      if (this.giftCards.length) {
        this.redeemGiftCard = this.giftCards.some((card) => card.currentBalanceCents);
      } else {
        this.redeemGiftCard = false;
      }

      // re-calculate split tenders if gift cards are to be redeemed
      if (this.redeemGiftCard) {
        this.sendMockPreorder();
      }
    }
  }

  // populates credit cards array
  populateCreditCards(allCards: Array<any>): void {
    // populate all credit cards.
    allCards.forEach((card) => {
      if (card.card_brand != 'nown_gift') {
        this.creditCards.push(card);
      }
    });
  };

  // get all cards and populates the appropriate card arrays
  // calls populateCreditCards() & populateGiftCards()
  async getPaymentMethods(): Promise<void> {
    let result = await this.gateway.getPaymentMethod();

    if (result.success) {
      // all cards (including gift cards) are sent in the credit_cards array.
      this.populateCreditCards(result.credit_cards);
      this.populateGiftCards();
      this.selectedCreditCard = this.creditCards.find((card) => card.id === result.default_card_id);
      this.defaultCardId = result.default_card_id;

      if (this.isPurchasingGiftCard && !this.creditCards.length) {
        this.addingNewCreditCard = true;
      } else {
        this.addingNewCreditCard = false;
      }
    }
  }

  switchView(view: string): void {
    if (view !== View.PAYMENT_INFO && view !== View.CART && view !== View.PENDING_ORDER) {
      return;
    }
    if (this.currentView === View.PAYMENT_INFO) {
      this.creditCardInfo = {
        name: '',
        number: '',
        email: '',
        expDate: '',
        cvv: '',
        postalCode: ''
      };
      this.creditCards = [];
      this.giftCards = [];
    }

    // populate payment-info screen if an user exists
    if (view === View.PAYMENT_INFO && this.savedUser) {
      // get all payment methods (includes gift cards and credit cards)
      this.getPaymentMethods();

      // web order users do not currently have an email attached.
      // this is because web order never required an email on signup
      // this should start working again once payments server support
      // updating emails for users
      this.creditCardInfo = {
        // capitalize first letter.
        name: this.savedUser.first_name ? (this.savedUser.first_name.charAt(0).toUpperCase() + this.savedUser.first_name.slice(1)) : '',
        number: '',
        email: this.savedUser.email || '',
        expDate: '',
        cvv: '',
        postalCode: ''
      }

      this.sendMockPreorder();
    }

    this.currentView = view;
  }

  getPhoneNumber(): string {
    return localStorage.getItem('phone_number');
  }

  ngOnDestroy(): void {
  }

  back() {
    this.env.attemptReturnToReferrer().then(() => {
      this.modalRef.hide();
      this.onClose.next();
      });
  }

  sendEmailReceipt() {
    const validEmail = this.validateEmails(this.email);
    if (!validEmail || this.emailingReceipt) {
      return;
    }
    this.emailingReceipt = true;
    this.gateway.updatePreorderWithEmail(this.email, this.orderTrackingService.getPreorderId())
      .then((res) => {
        this.emailingReceipt = false;
        if (!res.success) {
          this.alertService.showError(
            this.translate.instant('ts.error!'),
            this.translate.instant('ts.failedToSendEmailReceipt')
          );
          return;
        }
        this.emailReceiptRequested = true;
      })
      .catch((error) => {
        this.emailingReceipt = false;
        this.emailReceiptRequested = false;
        this.alertService.showError(
          this.translate.instant('ts.error!'),
          this.translate.instant('ts.failedToSendEmailReceipt')
        );
        console.log(error);
      });
  }
}
