import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LucovaGatewayService } from 'src/app/services/lucova-gateway/lucova-gateway.service';
import { LocationMenuResponse, Menu, MenuItem, MenuChild } from 'src/app/models/location-menu.model';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { MerchantLocation } from 'src/app/models/merchant-location.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ItemModifiersComponent } from '../common/modals/item-modifiers/item-modifiers.component';
import { CheckOutComponent } from '../common/modals/check-out/check-out.component';
import { OrderService } from 'src/app/services/order/order.service';
import { OrderTrackingService } from 'src/app/services/order-tracking/order-tracking.service';
import { MerchantBrand, MerchantBrandResponse } from 'src/app/models/merchant-brand.model';
import { LocationService } from 'src/app/services/location/location.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-location-menu',
  templateUrl: './location-menu.component.html',
  styleUrls: ['./location-menu.component.less']
})
export class LocationMenuComponent implements OnInit, OnDestroy, AfterViewChecked {

  menu: Menu;
  menuExpiry: number = null;
  locationTopItems: MenuItem[] = new Array<MenuItem>();
  location: MerchantLocation;
  expandedItem: MenuChild;
  menuReloadTimeoutId: number;

  locationId: string;
  brandId: string;

  scrollStart: boolean;
  scrollEnd: boolean;
  cart: any[] = new Array<any>();
  modalOpened = false;
  brand: any;
  addedToCart = false;
  searchText: string;
  isUsingSearch: boolean;

  constructor(private route: ActivatedRoute,
              private gateway: LucovaGatewayService,
              private env: EnvironmentService,
              private authService: AuthenticationService,
              private modalService: BsModalService,
              public orderService: OrderService,
              public orderTrackingService: OrderTrackingService,
              private router: Router,
              private locationService: LocationService,
              private pathLocation: Location,
              public alertService: AlertService,
              private translate: TranslateService) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.brand = this.router.getCurrentNavigation().extras.state.brand;
      this.location = this.router.getCurrentNavigation().extras.state.location;

      const menuResponse: LocationMenuResponse = this.router.getCurrentNavigation().extras.state.menuResponse;
      if (menuResponse) {
        this.onLocationMenuLoad(menuResponse);
      }
    }
  }

  ngAfterViewChecked(): void {
    const scrollableItemsList = [].slice.call(document.getElementsByClassName('scrollable-items'));
    if (scrollableItemsList.length) {
      scrollableItemsList.forEach((scrollableItems) => {
        this.onScrollableItemsLoad(scrollableItems);
      });
    }
  }

  onScrollableItemsLoad(scrollableItems): void {
    // Some devices don't completely reach the end of a scroll. A threshold in pixels is used to compensate for that.
    const triggerThreshold = 32;

    const fadeRight = [].slice.call(scrollableItems.children).find((element) => {
      return element.className === 'fade-right';
    });
    const fadeLeft = [].slice.call(scrollableItems.children).find((element) => {
      return element.className === 'fade-left';
    });
    if (!(fadeRight && fadeLeft)) {
      return;
    }
    if ((scrollableItems.offsetWidth + scrollableItems.scrollLeft) >= scrollableItems.scrollWidth - triggerThreshold) {
      // End of scroll list
      fadeRight.style.opacity = 0;
      fadeRight.style.visibility = 'hidden';
    } else {
      fadeRight.style.opacity = 1;
      fadeRight.style.visibility = 'visible';
    }
    if (scrollableItems.scrollLeft <= 0 + triggerThreshold) {
      // Start of scroll list
      fadeLeft.style.opacity = 0;
      fadeLeft.style.visibility = 'hidden';
    } else {
      fadeLeft.style.opacity = 1;
      fadeLeft.style.visibility = 'visible';
    }
  }

  onScroll(e): void {
    // Some devices don't completely reach the end of a scroll. A threshold in pixels is used to compensate for that.
    const triggerThreshold = 32;

    const fadeRight = [].slice.call(e.target.children).find((element) => {
      return element.className === 'fade-right';
    });
    const fadeLeft = [].slice.call(e.target.children).find((element) => {
      return element.className === 'fade-left';
    });
    if ((e.target.offsetWidth + e.target.scrollLeft) >= e.target.scrollWidth - triggerThreshold) {
      // End of scroll list
      fadeRight.style.opacity = 0;
      fadeRight.style.visibility = 'hidden';
    } else {
      fadeRight.style.opacity = 1;
      fadeRight.style.visibility = 'visible';
    }
    if (e.target.scrollLeft <= 0 + triggerThreshold) {
      // Start of scroll list
      fadeLeft.style.opacity = 0;
      fadeLeft.style.visibility = 'hidden';
    } else {
      fadeLeft.style.opacity = 1;
      fadeLeft.style.visibility = 'visible';
    }
  }

  slide(id: any, direction: string, pixels: number): void {
    // scrolls horizontally depending on the given direction.
    const list = document.getElementById(id + '-list');
    list.scrollBy({
        left: direction === 'right' ? pixels : -pixels,
        behavior: 'smooth'
    });
  }

  ngOnInit(): void {
    this.locationId = this.route.snapshot.params.location_id;
    this.brandId = this.route.snapshot.params.node_id;

    if (!this.menu) { // We may already have the menu from the router
      this.loadLocationMenu();
    }
    this.loadBrand();
  }

  loadLocation(brand: MerchantBrand): void {
    const type = brand.app_root ? 'app' : 'organization';
    this.gateway.loadLocations(this.brandId, type)
      .then((locations: MerchantLocation[]) => {
        const foundLocation = locations.find((location) => location.node_id === this.locationId);
        this.location = foundLocation;
        this.ensureLocationHasCoordinates(this.location);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  ensureLocationHasCoordinates(location: MerchantLocation): void {
    if ((location.longitude === 0 || location.latitude === 0) && location.street_address) {
      this.locationService.findCoordinateFromStreetAddress(location.street_address + ' ' + location.city).then((position) => {
        location.longitude = position.longitude;
        location.latitude = position.latitude;
      });
    }
  }

  loadBrand(): void {
    if (!this.brand) {
      if (this.isStoreFront()) {
        const webOrderUrl = this.env.getWebOrderUrl();
        const nodeFid = webOrderUrl.split('.')[0];
        this.gateway.getBrandByNodeFid(nodeFid)
          .then((rsp: MerchantBrandResponse) => {
            if (!rsp || !rsp.success || !rsp.organization) {
              return;
            }
            this.brand = rsp.organization;
            this.gateway.setSelectedBrand(this.brand);
            this.loadLocation(this.brand);
          })
          .catch((err) => console.error(err));
      } else {
        this.gateway.loadBrands().then((brands) => {
          const foundBrand = brands.find((brand) => brand._id === this.brandId);
          if (!foundBrand) {
            return;
          }
          this.brand = foundBrand;
          this.gateway.setSelectedBrand(this.brand);
          this.loadLocation(this.brand);
        }).catch((err) => console.error(err));
      }
    }
  }

  ngOnDestroy(): void {
    this.cancelMenuReloader(this.menuReloadTimeoutId);
  }

  loadLocationMenu(): void {
    this.gateway.getLocationMenu(this.locationId)
      .then((res) => this.onLocationMenuLoad(res))
      .catch((err) => this.onLocationMenuError(err));
  }

  onLocationMenuLoad(locationMenuResponse: LocationMenuResponse): void {
    if (!locationMenuResponse.success) {
      return this.onLocationMenuError(locationMenuResponse);
    }
    this.menu = locationMenuResponse.menu;
    this.menuExpiry = locationMenuResponse.expires;

    this.locationTopItems.length = 0;
    this.locationTopItems.push(...locationMenuResponse.locationTop3 || []);

    this.activateMenuReloader(this.menuExpiry);
  }

  onLocationMenuError(error): void {
    console.error(error);
    this.alertService.showError(
      this.translate.instant('ts.failedToLoadMenu'),
      this.translate.instant('ts.pleaseTryAgainLater')
    );
    this.pathLocation.back();
  }

  activateMenuReloader(nextReloadTime: number): void {
    const timeout = nextReloadTime - Math.floor(Date.now() / 1000);
    this.menuReloadTimeoutId = window.setTimeout(() => {
      // this.loadLocationMenu();
    }, timeout);
  }

  cancelMenuReloader(timeoutId: number): void {
    window.clearTimeout(timeoutId);
  }

  showUserSpecificMenu(): boolean {
    return this.authService.isAuthenticated();
  }

  isStoreFront(): boolean {
    return !!this.env.getWebOrderUrl();
  }

  loadItem(itemId, itemv2Id): void {
    if (this.location.open_hours_str === 'Currently Closed') {
      this.alertService.showError(
        this.translate.instant('ts.thisLocationIsCurrentlyClosed'),
        this.translate.instant('ts.pleaseTryAgainLater')
      )
      return;
    }

    this.gateway.getMenuItem(itemId, itemv2Id, this.locationId)
      .then((res) => {
        this.onItemLoad(res.item);
      }).catch((error) => {
        this.alertService.showError(
          this.translate.instant('ts.failedToGetMenuItem'),
          this.translate.instant('ts.pleaseTryAgain')
        )
        console.log(error);
      });
  }

  onItemLoad(item: any): void {
    if (this.modalOpened) {
      return;
    }
    if (item && !item.children) {
      item.children = [{name:"Note", minSelection:-1, maxSelection:-1, type: "modifier_group", selection: "single", menu_id: "000000_00000", children: []}]
    } else {
      item.children.push({name:"Note", minSelection:-1, maxSelection:-1, type: "modifier_group", selection: "single", menu_id: "000000_00000", children: []});
    }
    this.modalOpened = true;
    const initialState = {
      item,
      location: this.location,
      brand: this.brand
    };

    const modalRef = this.modalService.show(ItemModifiersComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'item-modifiers-modal'
    });
    modalRef.content.onClose.subscribe((result: any) => {
      this.modalOpened = false;
      if (result) {
        this.addToCart(result);
      }
    });
  }

  editItem(item: any, index: number): void {
    if (!(item.children && item.children.length)) {
      return;
    }
    const itemCopy = JSON.parse(JSON.stringify(item));
    const initialState = {
      isEdit: true,
      item: itemCopy
    };
    const modalRef = this.modalService.show(ItemModifiersComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'item-modifiers-modal'
    });
    modalRef.content.onClose.subscribe((result: any) => {
      if (result) {
        this.cart[index] = result;
      }
    });
  }

  addToCart(item: any): void {

    this.cart.push(item);
    this.orderService.setCart(this.cart);
    this.addedToCart = true;
    setTimeout(() => {
      this.addedToCart = false;
    }, 2000);
  }

  checkOut(): void {
    this.modalOpened = true;
    const initialState = {
      brand: this.brand,
      location: this.location
    };
    const modalRef = this.modalService.show(CheckOutComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'check-out-modal'
    });

    modalRef.content.onClose.subscribe(() => {
      this.modalOpened = false;
    });
  }

  toggleModifierVisibility(item: MenuChild): void {
    if (!item._id) {
      // Categories do not have an id
      item._id = Date.now().toString();
    }
    if (!item.children || item.children.length === 0) {
      return console.log('No children');
    }
    // Close previously opened modifier
    if (this.expandedItem && this.expandedItem.modifierVisible && this.expandedItem._id !== item._id) {
      this.expandedItem.modifierVisible = false;
    }
    this.expandedItem = item;
    item.modifierVisible = !item.modifierVisible;
  }

  searchClicked(): void {
    this.isUsingSearch = true;
    // Without the timeout the textbox will not be focused.
    setTimeout(() => {
      document.getElementById('searchTextbox').focus();
    }, 0);
  }

  searchFocusOut(): void {
    if (!this.searchText) {
      this.isUsingSearch = false;
    }
  }

  back(): void {
    if (this.isStoreFront()) {
      this.router.navigate(['/locations/']);
      return;
    }
    this.router.navigate(['/brand/', this.brand._id]);
  }
}
