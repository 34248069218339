<app-loading-screen *ngIf="!(brands && brands.length)"></app-loading-screen>

<div class="full-vw">
    <div *ngIf="brands && brands.length" class="brands-list-body global-max-width">
        <div class="vertical-spacing-24"></div>
    
        <!-- Header -->
        <div class="header">
            <div class="title-container">
                <div class="title">{{ 'brandsList.webOrder.title' | translate }}</div>
            </div>
            <select-language></select-language>
        </div>
        <div class="vertical-spacing-24"></div>
    
        <!-- Full list of brands -->
        <div class="row-vertical-layout">
            <div class="heading">
                {{ 'brandsList.allBrands.title' | translate }}
            </div>
        </div>
        <div [hidden]="!brands || brands.length == 0">
            <div class="locations-list-container">
                <div class="float-item-2" (click)="selectBrand(brand)"
                [ngStyle]="brand.card_image_url ? {'background-image': 'url(' + brand.card_image_url + ')'} : {'background-image': 'none'}"
                *ngFor="let brand of brands; let i = index">
                    <div class="brand-name" *ngIf="!brand.card_image_url">{{ brand.node_name }}</div>
                </div>
            </div>
        </div>

        <div class="vertical-spacing-48"></div>
        <a href="http://www.nownpos.com" class="powered-by-nown-container">
            <img class="powered-by-nown" src="assets/images/powered_by_nown.png"/>
        </a>
    </div>
</div>