import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {
  public onClose: Subject<any> = new Subject();
  title: string;
  description: string;

  constructor(public modalRef: BsModalRef) {
  }

  close() {
    this.modalRef.hide();
    this.onClose.next();
  }

  ngOnInit(): void {
  }
}
