import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationMenuComponent } from '../components/location-menu/location-menu.component';
import { BrandsListComponent } from '../components/brands-list/brands-list.component';
import { LocationsListComponent } from '../components/locations-list/locations-list.component';

import { CommonModule } from '@angular/common';

const routes: Routes = [
  { path: '', component: BrandsListComponent },
  { path: 'locations', component: LocationsListComponent },
  { path: 'brand/:node_id', component: LocationsListComponent },
  { path: 'brand/:node_id/menu/:location_id', component: LocationMenuComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule]
})
export class RoutingModule { }
