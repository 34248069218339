import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.less'],
  host: {class: 'redirect-component'}
})
export class RedirectComponent implements OnInit, OnDestroy {
  private readonly debounceInterval = 500;
  private isAlreadyDebounced = false;
  private registeredTimeouts = [];
  private is3DSSuccess = false;

  private crossOriginMessageListener: EventListener = (e: MessageEvent) => {
    const isSuccess = e.data && e.data.includes('success');
    const isLucovaRedirect = e.origin.includes(environment.lucovaGatewayHost);

    this.is3DSSuccess = isSuccess;
    if (isLucovaRedirect && isSuccess) {
      this.registeredTimeouts.push(setTimeout(() => {
        this.close();
      }, 3000));
    }
  };

  private _debounceTimeout(interval: number): void {
    this.isAlreadyDebounced = true;

    this.registeredTimeouts.push(setTimeout(() => {
      this.isAlreadyDebounced = false;
    }, interval));
  };

  url: string;
  onClose?: Function;

  trustedUrl: SafeResourceUrl;

  constructor(
    public modalRef: BsModalRef,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.crossOriginMessageListener);
    this.registeredTimeouts.forEach((timeoutId) => clearTimeout(timeoutId));
  }

  ngOnInit(): void {
    this.trustedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
    window.addEventListener('message', this.crossOriginMessageListener);
  }

  close(): void {
    if (this.isAlreadyDebounced) {
      return;
    }

    this._debounceTimeout(this.debounceInterval);
    this.modalRef.hide();

    if (this.onClose) {
      this.onClose(this.is3DSSuccess);
    }
  }

}
