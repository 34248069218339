import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { CheckOutComponent } from '../check-out/check-out.component';
import { OrderService } from 'src/app/services/order/order.service';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
  selector: 'app-gift-cards',
  templateUrl: './gift-card-options.component.html',
  styleUrls: ['./gift-card-options.component.less']
})
export class GiftCardOptionsComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  public onClose: Subject<any> = new Subject();
  selectorElement: any;
  finishedLoadingOptionTabChildren = false;
  optionElements: any[] = new Array<any>();
  buttonText: string;

  giftCardImage: string;
  
  location: any;

  options: any[] = new Array<any>();
  messages: any[] = new Array<any>();
  recipient: any;

  addedToOrder = false;
  selectedOptionId: number;
  selectedMessage: string;
  selectedCustomMessage: boolean;
  selectedCurrency: any;
  selectedAmount: number;
  showCustomAmount = false;
  emailValidation = true;
  emailValidationTimer: any;
  amountValidation = true;

  currencies: any[] = new Array<any>();
  cart: any[] = new Array<any>();
  brand: any;
  giftCardSettings: any;
  addedToCart = false;
  modalOpened = false;

  
  constructor(public env: EnvironmentService,
              public modalRef: BsModalRef,
              private modalService: BsModalService,
              public orderService: OrderService,
              private translate:TranslateService) { }

  ngOnInit(): void {
    this.buttonText = this.translate.instant('ts.continue');

    this.giftCardSettings = this.brand.digital_gift_card_settings;

    // predetermined pages
    this.options = [
        {
        id: 1,
        name: this.translate.instant('ts.about')
        },
        {
        id: 2,
        name: this.translate.instant('ts.to')
        },
        {
        id: 3,
        name: this.translate.instant('ts.message')
        },
        {
        id: 4,
        name: this.translate.instant('ts.amount')
        }
    ];

    // recipient info
    this.recipient = {
        name: '',
        email: ''
    }

    // message suggestions from digital gift card settings
    this.messages = this.giftCardSettings.messageSuggestions.map((messageSuggestion, index) => {
        let message = {
            id: index,
            message: messageSuggestion,
            selected: false
        };

        return message;
    });

    // add some default messages if no message suggestions were set up.
    if (!this.messages.length) {
      this.messages = [
        { 
          id: 0,
          message: this.translate.instant('ts.wishYouAHappyBirthday'),
          selected: false
        },
        { 
          id: 1,
          message: this.translate.instant('ts.thankYouVeryMuch'),
          selected: false
        },
        { 
          id: 2,
          message: this.translate.instant('ts.congratulationsAndBestWishes'),
          selected: false
        }
      ]
    }

    let supportedCurrencies = this.giftCardSettings.supportedCurrencies;
    supportedCurrencies.forEach(supportedCurrency => {
        supportedCurrency.selected = false;
        this.currencies.push(supportedCurrency);
    });

    // if only one currency, select it automatically
    if (this.currencies.length === 1) {
        this.selectCurrency(this.currencies[0]);
    }
  }


  ngAfterViewInit(): void {
    this.options.forEach((option) => {
      const optionElement = document.getElementById(option.id);
      this.optionElements.push(optionElement);
    });
    this.selectOption(1);
    this.selectorElement = document.getElementById('optionSelector');
  }

  ngAfterViewChecked(): void {
    //  of the first tab finish loading, we don't need to do anything here anymore.
    if (this.finishedLoadingOptionTabChildren) {
      return;
    }
    // Highlights the first tab automatically
    const firstOptionTab = document.getElementById('1-tab');
    if (firstOptionTab.offsetWidth > 0) {
      this.selectorElement.style.width = firstOptionTab.offsetWidth + 'px';
      this.selectorElement.style.left = firstOptionTab.offsetLeft + 'px';
      this.finishedLoadingOptionTabChildren = true;
    }
  }

  ngOnDestroy(){
  }

  onOptionScroll(e): void {
    // Works alongside a snap-on scroll type. If the scroll offset gets within the area
    // of a gift card option tile, it will automatically snap onto the tile and highlight the corresponding
    // gift card option selector tab.
    this.optionElements.forEach((optionElement) => {
        if (e.target.scrollLeft < (optionElement.offsetLeft + (optionElement.offsetWidth / 2))
        && e.target.scrollLeft > (optionElement.offsetLeft - (optionElement.offsetWidth / 2))) {
          if (this.selectedOptionId !== parseInt(optionElement.id)) {
              this.selectedOptionId = parseInt(optionElement.id);
              this.renderButtonText();
            const correspondingTab = document.getElementById(`${optionElement.id}-tab`);
            this.selectorElement.style.width = correspondingTab.offsetWidth + 'px';
            this.selectorElement.style.left = correspondingTab.offsetLeft + 'px';
          }
      }
    });
  }

  renderButtonText(): void {
    let text = '';

    if (this.selectedOptionId <= 2) {
        text = this.translate.instant('ts.continue');
    } else if (this.selectedOptionId === 3) {
        text = this.translate.instant('ts.skip');
    } else if (this.selectedOptionId === 4) {
        text = this.translate.instant('ts.addToCart');
    }

    this.buttonText = text;
  }

  selectOption(id: any): void {
    this.selectedOptionId = id;
    const selectedOption = document.getElementById(id);
    selectedOption.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }

  nextPage(): void {
    if (this.selectedOptionId != this.options.length) {
      this.selectOption(this.selectedOptionId + 1);
    } else {
        this.checkOut();
    }
  }

  resetSelectedMessage(): void {
    this.messages.forEach(message => {
        message.selected = false;
    });

    this.selectedMessage = '';
  }

  selectCustomMessage(): void {
      this.selectedCustomMessage = true;
      this.resetSelectedMessage();
  }

  selectMessage(id: any): void {
    // unselects custom message if previously selected
    this.selectedCustomMessage = false;

    if (this.messages[id].selected) {
      this.resetSelectedMessage();
      return;
    }

    this.resetSelectedMessage();
    let foundMessage = this.messages.find((message) => {
        return message.id === id;
    });

    foundMessage.selected = true;
    this.selectedMessage = foundMessage.message;

    // scroll custom message tile into view
    // slight timeout due to *ngIf
    if (id === this.messages.length) {
      setTimeout(() => {
        const customMessageTile = document.getElementById('customMessageTile');
        customMessageTile.scrollIntoView({ behavior: 'smooth', block: 'center' });    
      }, 50);
    }
  }

  resetSelectedCurrency(): void {
    this.currencies.forEach(currency => {
        currency.selected = false;
    })
  }

  selectCurrency(selectedCurrency: any): void {
    this.resetSelectedCurrency();

    let foundCurrency = this.currencies.find((currency) => {
        return currency.currencyCode === selectedCurrency.currencyCode;
    });

    if (foundCurrency) {
      foundCurrency.selected = true;
      this.selectedCurrency = foundCurrency;
    }
  }

  buildDiscountString(discount: any): string {
    let discountString = 'Get '
    + discount.discountPercentage 
    + '% off gifts between $'
    + (discount.minAmountCents / 100).toFixed(2)
    + ' and $'
    + (discount.maxAmountCents / 100).toFixed(2);
    return discountString;
  }

  buildDiscountedPriceString(suggestedAmount: any, percentage: any): string {
      let discountedPriceString = 'Pay only $';
      let discountPercentage = 1 - (percentage / 100);
      discountedPriceString += ((suggestedAmount * discountPercentage) / 100).toFixed(2);
      return discountedPriceString;
  }

  buildLimitString(currency: any): string {
    let discountString = 'Please enter an amount between $'
    + (currency.minAmountCents / 100).toFixed(2)
    + ' and $'
    + (currency.maxAmountCents / 100).toFixed(2) 
    + '.';
    return discountString;
  }

  selectAmount(amount: any): void {
    this.selectedAmount = 0;
    if (amount === 'custom') {
        this.showCustomAmount = true;
        // scroll custom amount tile into view
        // slight timeout due to *ngIf
        setTimeout(() => {
            const customAmountTile = document.getElementById('customAmountTile');
            customAmountTile.scrollIntoView({ behavior: 'smooth', block: 'center' });    
        }, 50);
    } else {
        this.showCustomAmount = false;
        this.selectedAmount = amount;
    }
  }

  buildGiftCardItem(): object {
    let item = {
        top_node_id: this.brand.app_node_id,
        node_id: this.brand._id,
        name: 'Digital Gift Card',
        recipient_email: this.recipient.email || '',
        recipient_name: this.recipient.name || '',
        ecard_image_url: this.giftCardImage,
        currency_code: this.selectedCurrency.currencyCode,
        tax_rate: 0,
        amount_cents: null,
        discount_cents: null,
        transaction_uuid: uuidv4(),
        personal_message: this.selectedMessage
    };

    // converting selectedAmount to cents if custom amount type.
    if (this.showCustomAmount) {
      item.amount_cents = this.selectedAmount * 100;
    } else {
      item.amount_cents = this.selectedAmount;
    }

    let discountObj = this.selectedCurrency.discount;

    if (item.amount_cents >= discountObj.minAmountCents 
    && item.amount_cents <= discountObj.maxAmountCents) {
      let discountPercentage = (discountObj.discountPercentage / 100);
      item.discount_cents = item.amount_cents * discountPercentage;
    }

    return item;
  }

  isRequiredFieldsFilled(): boolean {
    if (this.selectedOptionId != 4) {
        return false;
    }

    let recipientValidation = false;
    
    // ensures a name & email is entered
    if (this.recipient) {
        if (this.recipient.name.length || this.recipient.email.length) {
            recipientValidation = true;
        }
    }

    // ensures a currency & amount is selected
    if (this.selectedCurrency) {
        let amountCents = 0;

        if (this.showCustomAmount) {
            // converts custom amount to cents
            amountCents = this.selectedAmount * 100;
        } else {
            amountCents = this.selectedAmount;
        }

        // ensures amount is within min/max limits
        if (amountCents >= this.selectedCurrency.minAmountCents 
        && amountCents <= this.selectedCurrency.maxAmountCents) {
            this.amountValidation = true;
        } else {
            this.amountValidation = false;
        }
    } 

    return !(recipientValidation && this.amountValidation);
  }

  validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkEmailInput(email: string): any {
      this.emailValidationTimer = 0;
      setTimeout(() => {
        if (!this.validateEmail(email)) {
            this.emailValidation = false;
        } else {
            this.emailValidation = true;
        }
    }, 1000);
  } 

  checkOut(): void {

    if (!this.validateEmail(this.recipient.email)) {
        this.selectOption(2);
        this.emailValidation = false;
        return;
    } else {
        this.emailValidation = true;
    }

    // emptying the cart first.
    // this is to catch the case where menu items were added to the cart
    // then the user goes back to buy a gift card.
    // when the user reaches the order confirmed page
    // this ensures that the items previously added to cart will not show up.
    this.orderService.emptyCart();

    let item = this.buildGiftCardItem();
    this.cart.push(item);
    this.orderService.setCart(this.cart);
    this.addedToCart = true;
    setTimeout(() => {
      this.addedToCart = false;
    }, 2000);

    this.modalOpened = true;
    const initialState = {
      isPurchasingGiftCard: true,
      location: this.location,
      brand: this.brand
    };
    const modalRef = this.modalService.show(CheckOutComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false,
      class: 'check-out-modal'
    });
    modalRef.content.onClose.subscribe(() => {
      this.modalOpened = false;
    });

    this.close(true);
  }

  close(isCheckout: boolean) {
    if (isCheckout) {
      this.modalRef.hide();
      this.onClose.next();
    } else {
      this.env.attemptReturnToReferrer().then(() => {
        this.modalRef.hide();
        this.onClose.next();
      });
    }
  }
}
