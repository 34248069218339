<div class="header-language-switcher">
    <select class="form-control" [(ngModel)]="currentLanguage" (change)="setTransLanguage()">
        <option *ngFor="let language of availableLanguages" 
        [value]="language" [selected]="language === currentLanguage">
        <span *ngIf="language === 'fr-CA'">Français</span>
        <span *ngIf="language === 'en-CA'">English</span>
        </option>
    </select>
    <div class="header-language-switcher-arrow">
        <span class="nown-icon icon-Arrow-Large-Down"></span>
    </div>
</div>
