import { envName } from './env-name';

export const environment = {
  defaultHost: 'https://benown.app',
  lucovaGatewayHost: 'https://api.lucova.com',
  appServerHost: 'https://app.nownpos.com',
  sendDiagnostic: true,
  honeybadgerApiKey: '',
  appRevision: '',
  envName: envName.PRODUCTION,
  production: true,
  lucovaSocketIOUrl: 'https://socketio.lucovalabs.com',
  googleApiKey: 'AIzaSyC1tZzCG1ITEa5I-XX8qC4N0VCkTBPP-as'
};
