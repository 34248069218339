<div class="redirect-container">
    <div class="redirect-container__header">
        <div class="close-container" (click)="close()">
            <span class="nown-icon icon-Exit-Large cross-icon"></span>
        </div>
    </div>
    <div class="iframe-container">
        <iframe class="iframe" [src]="trustedUrl" frameborder="0"></iframe>
    </div>
</div>
