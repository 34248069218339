import { Component, OnInit } from '@angular/core';
import { OrderTrackingService } from 'src/app/services/order-tracking/order-tracking.service';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {

  constructor(private orderTrackingService: OrderTrackingService,
              private authService: AuthenticationService,
              private translateService: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en-CA');
    this.translateService.addLangs(['en-CA', 'fr-CA']);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use('en-CA');
  }




  ngOnInit(): void {
    this.authService.checkIfAuthenticated()
      .then((res) => {
        if (res.success) {
          this.orderTrackingService.loadPreorderStatus();
          this.orderTrackingService.setupSocketConnection();
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  onActivate(): void {
    window.scroll(0, 0);
  }
}
