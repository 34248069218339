import { environment } from '../../../environments/environment';
import { envName } from '../../../environments/env-name';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrowserData } from 'src/app/models/client-browser-related.model';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private merchantStoreCookieKey = 'merchantstore';
  public isMerchantGiftCardCheckoutLink = false;
  public gcChekoutReturnUrl = undefined;
  private browserData: BrowserData = {
    accept_header: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
    ip_address: '0.0.0.0',
    java_enabled: false,
    javascript_enabled: true,
    language: 'en-US',
    color_depth: 0,
    screen_height: 0,
    screen_width: 0,
    time_zone: 0,
    user_agent: ''
  };
  private clientPublicIPv4: string = '0.0.0.0';

  constructor(private cookieService: CookieService, private http: HttpClient) {
    // set client IP here
    this.getClientIPv4().subscribe((response: string) => {
      this.clientPublicIPv4 = response; // ip address is sent as text response
    });
  }

  isProduction(): boolean {
    return environment.envName === envName.PRODUCTION;
  }

  isStaging(): boolean {
    return environment.envName === envName.STAGING;
  }

  isDevelopment(): boolean {
    return environment.envName === envName.DEVELOPMENT;
  }

  getLucovaGatewayHost(): string {
    return environment.lucovaGatewayHost;
  }

  getHoneyBadgerApiKey(): any {
    return environment.honeybadgerApiKey;
  }

  getEnvName(): string {
    return environment.envName;
  }

  getAppRevision(): string {
    return environment.appRevision;
  }

  getWebOrderUrl(): string {
    if (window.parent != window) {
      return document.location.hostname;
    }
    return this.cookieService.get(this.merchantStoreCookieKey);
  }

  getDefaultHost(): string {
    return environment.defaultHost;
  }

  getAppServerUrl(): string {
    return environment.appServerHost;
  }

  getLucovaSocketIOUrl(): string {
    return environment.lucovaSocketIOUrl;
  }

  attemptReturnToReferrer(): Promise<boolean> {
    return new Promise((resolve, _reject) => {

      if (!this.isMerchantGiftCardCheckoutLink || !document.referrer) {
        return resolve(false);
      }

      // Todo: show close confirmation dialogue
      const redirectUrl = this.gcChekoutReturnUrl ? this.gcChekoutReturnUrl : document.referrer;
      window.location.href = redirectUrl;
    });
  }

  getClientIPv4(): Observable<string> {
    const url = 'https://api.ipify.org'; // yields text in body. Getting block by adBlockers, if ?format is included

    return this.http.get(url, {responseType: 'text'});
  }

  getBrowserData(): BrowserData {
    let now: Date = new Date();

    if (navigator) {
      this.browserData.java_enabled = navigator.javaEnabled();
      this.browserData.language = navigator.language;
      this.browserData.user_agent = navigator.userAgent;
    }

    this.browserData.time_zone = now.getTimezoneOffset();

    this.browserData.screen_height = screen.height;
    this.browserData.screen_width = screen.width;
    this.browserData.color_depth = screen.colorDepth;

    this.browserData.ip_address = this.clientPublicIPv4;

    return this.browserData;
  }

}
