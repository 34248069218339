export class MerchantBrand {
    _id: string;
    node_name: string;
    app_ids: Array<string>;
    card_image_url: string;
    logo_url: string;
    image_url: string;
    node_fid: string;
    app_root: boolean;
    app_node_id: string;
    app_fid: string;
    digital_gift_card_settings: DigitalGiftCardSettings;
}

export interface DigitalGiftCardSettings {
    enabled: boolean;
    images: Array<Image>;
    supportedCurrencies: Array<SupportedCurrency>;
}

export interface Image {
    id: string;
    name: string;
    url: string;
}

export interface SupportedCurrency {
    currencyCode: string;
    minAmountCents: number;
    maxAmountCents: number;
    suggestedDenominationsCents: Array<number>;
    discount: Discount;
}

export interface Discount {
    minAmountCents: number;
    maxAmountCents: number;
    discountPercentage: number;
}

export interface MerchantBrandResponse {
    success: boolean;
    organization: MerchantBrand;
}
