import { Component, OnInit, OnDestroy, AfterViewChecked, AfterViewInit} from '@angular/core';
import { LucovaGatewayService } from 'src/app/services/lucova-gateway/lucova-gateway.service';
import { Router } from '@angular/router';
import { MerchantBrand } from 'src/app/models/merchant-brand.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LegalComponent } from '../common/modals/legal/legal.component';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Title } from '@angular/platform-browser';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.less']
})
export class BrandsListComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  brands: MerchantBrand[] = new Array<MerchantBrand>();
  scrollableTopItems: any;

  constructor(private gateway: LucovaGatewayService,
              private router: Router,
              private modalService: BsModalService,
              private env: EnvironmentService,
              private alertService: AlertService,
              private titleService: Title,
              private translate:TranslateService) {
    this.titleService.setTitle('Nōwn Web Order');
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
    const scrollableItemsList = [].slice.call(document.getElementsByClassName('scrollable-items'));
    if (scrollableItemsList.length) {
      scrollableItemsList.forEach((scrollableItems) => {
        this.onScrollableItemsLoad(scrollableItems);
      });
    }
  }

  onScrollableItemsLoad(scrollableItems): void {
    // Some devices don't completely reach the end of a scroll. A threshold in pixels is used to compensate for that.
    const triggerThreshold = 32;

    const fadeRight = [].slice.call(scrollableItems.children).find((element) => {
      return element.className === 'fade-right';
    });
    const fadeLeft = [].slice.call(scrollableItems.children).find((element) => {
      return element.className === 'fade-left';
    });
    if (!(fadeRight && fadeLeft)) {
      return;
    }
    if ((scrollableItems.offsetWidth + scrollableItems.scrollLeft) >= scrollableItems.scrollWidth - triggerThreshold) {
      // End of scroll list
      fadeRight.style.opacity = 0;
      fadeRight.style.visibility = 'hidden';
    } else {
      fadeRight.style.opacity = 1;
      fadeRight.style.visibility = 'visible';
    }
    if (scrollableItems.scrollLeft <= 0 + triggerThreshold) {
      // Start of scroll list
      fadeLeft.style.opacity = 0;
      fadeLeft.style.visibility = 'hidden';
    } else {
      fadeLeft.style.opacity = 1;
      fadeLeft.style.visibility = 'visible';
    }
  }

  onScroll(e): void {
    // Some devices don't completely reach the end of a scroll. A threshold in pixels is used to compensate for that.
    const triggerThreshold = 32;

    const fadeRight = [].slice.call(e.target.children).find((element) => {
      return element.className === 'fade-right';
    });
    const fadeLeft = [].slice.call(e.target.children).find((element) => {
      return element.className === 'fade-left';
    });
    if ((e.target.offsetWidth + e.target.scrollLeft) >= e.target.scrollWidth - triggerThreshold) {
      // End of scroll list
      fadeRight.style.opacity = 0;
      fadeRight.style.visibility = 'hidden';
    } else {
      fadeRight.style.opacity = 1;
      fadeRight.style.visibility = 'visible';
    }
    if (e.target.scrollLeft <= 0 + triggerThreshold) {
      // Start of scroll list
      fadeLeft.style.opacity = 0;
      fadeLeft.style.visibility = 'hidden';
    } else {
      fadeLeft.style.opacity = 1;
      fadeLeft.style.visibility = 'visible';
    }
  }

  ngOnInit(): void {
    if (this.isStoreFront()) {
      this.router.navigate(['/locations']);
      return;
    }

    this.gateway.loadBrands()
      .then((brands: MerchantBrand[]) => {
        this.brands.length = 0;
        this.brands.push(...brands);
      })
      .catch((err) => {
        console.error(err);

        this.alertService.showError(
          this.translate.instant('ts.faildToLoadAllBrands'),
          this.translate.instant('ts.tryAgainLater')
        )
      });
  }

  ngOnDestroy(): void {
  }

  isStoreFront(): boolean {
    return !!this.env.getWebOrderUrl();
  }

  selectBrand(brand: MerchantBrand): void {
    this.router.navigate(['/brand/', brand._id], { state: { brand }});
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  showLegalPage(): void {
    this.modalService.show(LegalComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'item-modifiers-modal'
    });
  }
}
