import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.less']
})
export class SelectLanguageComponent implements OnInit {
  availableLanguages = this.translateService.getLangs();
  currentLanguage = this.translateService.currentLang;

  constructor(private translateService: TranslateService) { }

  setTransLanguage(): void {
    this.translateService.use(this.currentLanguage);
    this.translateService.setDefaultLang(this.currentLanguage);
    localStorage.setItem('NownWebOrderLanguagePreference', this.currentLanguage)
  }

  // Select the first section of user browser language to cover all versions of a language (fr-CA, fr-FR, ...)
  getShortLanguage = (language) => {
    if (language && language.indexOf('-') !== -1) {
      return language.split('-')[0];
    } else if (language && language.indexOf('_') !== -1) {
      return language.split('_')[0];
    } else {
      return 'en'
    }
  }

  setUserBrowserLanguage(): void {
    let userBrowserLanguage;
    const storedLanguagePreference = localStorage.getItem('NownWebOrderLanguagePreference') || null;
  
    if (storedLanguagePreference) {
      this.currentLanguage = storedLanguagePreference;
      this.translateService.use(this.currentLanguage);
    } else {
      // Detect user browser languages and use the first one as their default language
      userBrowserLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
      // Set the session storage key-value to store user prefered language
      this.getShortLanguage(userBrowserLanguage).toLowerCase() === 'fr' ? localStorage.setItem('NownWebOrderLanguagePreference', 'fr-CA') : localStorage.setItem('NownWebOrderLanguagePreference', 'en-CA');
    }

    const shortUserBrowserLanguage = this.getShortLanguage(userBrowserLanguage);

    // Right now English and French are the only 2 languages that we support and if the user language preference is
    // anything but French we fall back to English we should edit this if statement as we start supporting new languages.
    if (shortUserBrowserLanguage && shortUserBrowserLanguage.toLowerCase() === 'fr') {
      this.currentLanguage = 'fr-CA';
      this.translateService.setDefaultLang(this.currentLanguage);
      this.translateService.use(this.currentLanguage);
    }
  }

  ngOnInit(): void {
    this.setUserBrowserLanguage();
  }

}
