<div class="float-item menu-item-tile">
    <div class="image-container" loading="lazy" [style.background]="'url(' + img + ')'" *ngIf="img">
    </div>
    <div class="menu-item-text">
        <div class="item-name">{{ name }}</div>
        <div class="item-info">
            <div class="item-price">${{ price }}</div>
            <div class="item-calories" *ngIf="calories && calories !== 0">{{calories}} calories</div>  
        </div>
    </div>
</div>