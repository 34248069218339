<app-loading-screen *ngIf="!(location && menu)"></app-loading-screen>

<!-- Location Image -->
<div *ngIf="location && menu">
    <div *ngIf="location.image_url" class="location--header-image" 
    [ngStyle]="location.image_url ? {'background-image': 'url(' + location.image_url + ')'} : {'background-image': 'none'}"></div>
    <div *ngIf="!location.image_url" class="location--header-no-image"></div>

    <div class="full-vw">
        <div class="location-menu-body global-max-width">
    
            <div class="location-menu-header">
                <div class="button-container-row">
                    <div class="button-container-left">
                        <span class="nown-icon icon-Arrow-Small-Left left-arrow-icon-white" (click)="back()"></span>
                        <div class="white-button-212 large-back-button" (click)="back()">
                            <span class="nown-icon icon-Arrow-Small-Left left-arrow-icon"></span>
                            <div>{{ 'locationMenu.switchLocations' | translate }}</div>
                        </div>
                    </div>
                    <div class="button-container-right">
                        <span [hidden]="isUsingSearch" class="nown-icon icon-Search-Small search-icon" (click)="searchClicked()"></span>
                        <input [hidden]="!isUsingSearch" id="searchTextbox" class="search-textbox" type="text" placeholder="{{ 'placeHolder.searchForAnItem' | translate }}" [(ngModel)]="searchText" (focusout)="searchFocusOut()">
                    </div>
                </div>
            </div>
            
            <div class="location-menu-content">
                
                <!-- Location Details -->
                <div class="location-details-container-row">
                    <div *ngIf="location" class="location-details-container">
                        <div class="location--details-left" [style.justify-content]="(location.logo_url || brand.logo_url) ? 'unset' : 'center'">
                            <div *ngIf="location.logo_url || brand.logo_url" class="logo-container">
                                <img [src]="(location.logo_url ? location.logo_url : brand.logo_url )">
                            </div>
                            <div *ngIf="location.logo_url" class="horizontal-spacing-16"></div>
                            <div [style.text-align]="(location.logo_url || brand.logo_url) ? 'unset' : 'center'">
                                <div class="greeting">{{ 'locationMenu.welcomeTo' | translate }}</div>
                                <div class="location-name">{{ location.node_name }}</div>
                            </div>
                        </div>
                        <div class="location--details-right">
                            <div class="location-open-hours">
                                <div class="icon-container">
                                    <span class="nown-icon icon-Clock"></span>
                                </div>
                                <div>{{ location.open_hours_str }}</div>
                            </div>
                            <div class="vertical-spacing-13"></div>
                            <div class="location-address">
                                <div class="icon-container">
                                    <span class="nown-icon icon-Location"></span>
                                </div>
                                <div>{{ location.street_address}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="vertical-spacing-12"></div>
    
                <div [hidden]="searchText" *ngIf="locationTopItems && locationTopItems.length">
                    <div class="row-vertical-layout">
                        <div class="heading">{{ 'locationMenu.tendingNow' | translate }}</div>
                        <div class="description">{{ 'locationMenu.popularItems' | translate }} {{ location.node_name }}</div>
                    </div>
                    <div class="vertical-spacing-12"></div>
                    <div class="scrollable-items-container">
                        <div id="trending-list" class="scrollable-items" (scroll)="onScroll($event)">
                            <div class="fade-left">
                                <div class="left-arrow" (click)="slide('trending', 'left', 196)">
                                    <span class="nown-icon icon-Arrow-Large-Left"></span>
                                </div>
                            </div>
                            <div class="fade-right">
                                <div class="right-arrow" (click)="slide('trending', 'right', 196)">
                                    <span class="nown-icon icon-Arrow-Large-Right"></span>
                                </div>
                            </div>
                            <div class="item-tiles-container">
                                <div *ngFor="let item of locationTopItems" (click)="loadItem(item.original.locationServicePeriodMenuId, item.nown_menu_id_v2)">
                                    <app-tile-menu-item [img]="item.image_url" [name]="item.name" [price]="(item.amount_cents / 100).toFixed(2)" ></app-tile-menu-item>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="vertical-spacing-12"></div>
                </div>
            
                <div *ngFor="let item of menu.children">
                    <div *ngIf="(item.children | searchByName:searchText).length">
                        <div class="row-vertical-layout">
                            <div class="heading">{{ item.name }}</div>
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <div class="scrollable-items-container">
                            <div [attr.id]="item.menu_id + '-list'" class="scrollable-items" (scroll)="onScroll($event)">
                                <div class="fade-left">
                                    <div class="left-arrow" (click)="slide(item.menu_id, 'left', 196)">
                                        <span class="nown-icon icon-Arrow-Large-Left"></span>
                                    </div>
                                </div>
                                <div class="fade-right">
                                    <div class="right-arrow" (click)="slide(item.menu_id, 'right', 196)">
                                        <span class="nown-icon icon-Arrow-Large-Right"></span>
                                    </div>
                                </div>
                                <div class="item-tiles-container">
                                    <div *ngFor="let childItem of item.children | searchByName:searchText">
                                        <app-tile-menu-item 
                                            [img]="childItem.image_url"
                                            [name]="childItem.name" 
                                            [calories]="childItem.calories" 
                                            [price]="(childItem.amount_cents / 100).toFixed(2)" 
                                            (click)="loadItem(childItem.original.locationServicePeriodMenuId, childItem.nown_menu_id_v2)">
                                        </app-tile-menu-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vertical-spacing-12"></div>
                    </div>
                </div>

                <div class="footer global-max-width mobile-footer">
                    <div class="checkout-button yellow-button" *ngIf="cart.length && !orderTrackingService.isOrderInProgress()" (click)="checkOut()">
                        {{ 'locationMenu.viewCart' | translate }} ({{ cart.length }})
                    </div>
                    <div class="checkout-button yellow-button" *ngIf="orderTrackingService.isOrderInProgress()" (click)="checkOut()">
                        {{ 'locationMenu.orderInProgress' | translate }}
                    </div>
                </div>
                <div class="vertical-spacing-48"></div>
                <a href="http://www.nownpos.com" class="powered-by-nown-container">
                    <img class="powered-by-nown" src="assets/images/powered_by_nown.png"/>
                </a>
            </div>
        </div>
        <div class="added-to-order-notification float-item-fit" [style.opacity]="addedToCart ? '1' : '0'">
            <div class="notification-text">
                {{ 'locationMenu.addedToOrder' | translate }}
            </div>
            <img class="green-checkmark" src="assets/images/green-checkmark.jpg">
        </div>
    </div>
            
    <!-- Wide screen cart preview -->
    <div class="cart-footer wide-screen-footer" *ngIf="cart.length && !orderTrackingService.isOrderInProgress()">
        <div class="cart-preview-text">
            <div class="order-text">
                {{ 'locationMenu.yourOrder' | translate }}
            </div>
            <div class="text-16-light">
                {{ cart.length }} {{ 'locationMenu.item' | translate }}{{ cart.length > 1 ? 's': '' }}
            </div>
        </div>
        <div class="cart-preview-items">
            <div class="horizontal-spacing-12"></div>
            <div class="cart-preview-item" *ngFor="let item of cart; let i = index"
            (click)="editItem(item, i)"
            [ngClass]="{'cart-preview-item-image': item.image_url}"
            [ngStyle]="item.image_url ? {'background-image': 'url(' + item.image_url + ')'} : {'background-image': 'none'}">
                <div class="item-preview" *ngIf="!item.image_url">{{ item.name }}</div>
            </div>
            <div class="horizontal-spacing-12"></div>
        </div>
        <div class="horizontal-spacing-12"></div>
        <div class="checkout-container">
          <div class="checkout-button yellow-button" *ngIf="cart.length && !orderTrackingService.isOrderInProgress()" (click)="checkOut()">
              <span class="nown-icon icon-Bag-Large" style="margin-right: 6px; font-size: 20px;"></span>
              {{ 'locationMenu.viewCart' | translate }}
          </div>
        </div>
    </div>
    <div class="cart-footer wide-screen-footer" *ngIf="orderTrackingService.isOrderInProgress()">
        <div class="cart-preview-text">
            <div class="order-text">{{ 'locationMenu.yourOrder' | translate }}</div>
            <div class="text-16-light">{{ orderTrackingService.getOrderItems().length }} {{ 'locationMenu.item' | translate }}{{ orderTrackingService.getOrderItems().length > 1 ? 's': '' }}</div>
        </div>
        <div class="cart-preview-items">
            <div class="horizontal-spacing-12"></div>
            <div class="cart-preview-item" 
            *ngFor="let item of orderTrackingService.getOrderItems()"
            [ngClass]="{'cart-preview-item-image': item.image_url}"
            [ngStyle]="item.image_url ? {'background-image': 'url(' + item.image_url + ')'} : {'background-image': 'none'}">
                <div class="item-preview" *ngIf="!item.image_url">{{ item.name }}</div>
            </div>
            <div class="horizontal-spacing-12"></div>
        </div>
        <div class="horizontal-spacing-12"></div>
        <div class="checkout-container">
            <div class="checkout-button yellow-button" (click)="checkOut()">
                {{ 'locationMenu.orderInProgress' | translate }}
            </div>
        </div>
    </div>

    <div class="float--container"></div>
</div>
