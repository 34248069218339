import { MerchantLocation, MerchantLocationResponse } from 'src/app/models/merchant-location.model';
import { EnvironmentService } from '../environment/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocationMenuResponse } from 'src/app/models/location-menu.model';
import { MerchantBrand, MerchantBrandResponse } from 'src/app/models/merchant-brand.model';
import { LucovaPreorder, PreorderSource } from 'src/app/models/preoder-status.model';

@Injectable({
  providedIn: 'root'
})
export class LucovaGatewayService {
  private brands: MerchantBrand[] = new Array<MerchantBrand>();
  private selectedBrand: MerchantBrand = new MerchantBrand();

  constructor(private env: EnvironmentService, private http: HttpClient) { }

  public getSelectedBrand(): MerchantBrand {
    return this.selectedBrand;
  }
  public setSelectedBrand(brand: MerchantBrand): void {
    this.selectedBrand = brand;
  }

  public loadBrands(useCache = true): Promise<MerchantBrand[]> {
    return new Promise<MerchantBrand[]>((resolve, reject) => {
      if (useCache && this.brands.length) {
        return resolve(this.brands);
      }
      this.getBrands().subscribe((res: Array<MerchantBrand>) => {
        this.brands.push(...res);
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }

  public getBrandByNodeFid(nodeFid: string): Promise<MerchantBrandResponse> {
    const options = {
      params: {
        node_fid: nodeFid
      },
      headers: this._getHeader()
    };
    const url = `${this.env.getLucovaGatewayHost()}/202004/organization`;
    return this.http.get<MerchantBrandResponse>(url, options).toPromise();
  }

  public loadLocations(locationId, nodeType: string = 'organization'): Promise<MerchantLocation[]> {
    return new Promise<MerchantLocation[]>((resolve, reject) => {
      const params = {
        top_node_id: locationId,
        node_type: nodeType
      };

      this.getLocation(params).subscribe((res: MerchantLocationResponse) => {
        if (!res.success) {
          return reject({ code: res.error_code, message: res.message });
        }
        resolve(res.locations);
      }, (err) => reject(err));
    });
  }

  public getLocation(params): Observable<MerchantLocationResponse> {
    const options = {
      params,
      headers: this._getHeader()
    };
    const url = `${this.env.getLucovaGatewayHost()}/v2/locations`;
    return this.http.get<MerchantLocationResponse>(url, options);
  }

  public getBrands(): any {
    const url = `${this.env.getLucovaGatewayHost()}/202004/organizations`;
    return this.http.get(url);
  }

  public getLocationMenu(nodeId: string): Promise<LocationMenuResponse> {
    const options = {
      params: {
        node_id: nodeId
      },
      headers: this._getHeader()
    };
    const url = `${this.env.getLucovaGatewayHost()}/202004/menu`;
    return this.http.get<LocationMenuResponse>(url, options).toPromise();
  }

  public getMenuItem(itemId: string, itemv2Id: string, nodeId: string): Promise<any> {
    var options = {};
    if (itemv2Id)
    {
        options = {
          params: {
            nown_menu_id_v2: itemv2Id,
            node_id: nodeId
          },
          headers: this._getHeader()
        };
    }
    else
    {
        options = {
          params: {
            nown_menu_id: itemId,
            node_id: nodeId
          },
          headers: this._getHeader()
        };
    }
    const url = `${this.env.getLucovaGatewayHost()}/202004/menu-item`;
    return this.http.get<MerchantLocationResponse>(url, options).toPromise();
  }

  private _getHeader() {
    // basicAuth determines which user gets updated via user_name
    const basicAuth = btoa(localStorage.getItem('user_name') + ':' + localStorage.getItem('lucova_auth_token'));
    return {
      'LUCOVA-APP-FID': 'nown',
      'LUCOVA-VERSION-CODE': '1',
      'LUCOVA-WEB-ORDER-VERSION': '1',
      Authorization: `Basic ${basicAuth}`,
      'Content-Type': 'application/json',
    };
  }

  private _getCampxHeader() {
    // basicAuth determines which user gets updated via email
    const basicAuth = btoa(localStorage.getItem('email') + ':' + localStorage.getItem('auth_token'));
    return {
      'LUCOVA-APP-FID': this.getSelectedBrand().app_fid || 'nown',
      'LUCOVA-VERSION-CODE': '1',
      'LUCOVA-WEB-ORDER-VERSION': '1',
      Authorization: `Basic ${basicAuth}`,
      'Content-Type': 'application/json',
    };
  }

  public requestAccessCodeByPhone(phoneNumber: string): Promise<any> {
    const url = `${this.env.getAppServerUrl()}/user/request-access-code`;

    const options = {
      headers: this._getCampxHeader()
    }

    const body = {
      phone_number: '+1' + phoneNumber
    };
    return this.http.post<any>(url, body, options).toPromise();
  }

  public validateAccessCodeByPhone(phoneNumber: string, authToken:string): Promise<any> {
    const url = `${this.env.getAppServerUrl()}/user/validate-access-code`;

    const options = {
      headers: this._getCampxHeader()
    }

    const body = {
      phone_number: '+1' + phoneNumber,
      auth_token: authToken
    };
    return this.http.post<any>(url, body, options).toPromise();
  }

  public requestAccessCodeByEmail(email: string): Promise<any> {
    const url = `${this.env.getAppServerUrl()}/user/request-access-code`;

    const options = {
      headers: this._getCampxHeader()
    }

    const body = {
      email: email
    };
    return this.http.post<any>(url, body, options).toPromise();
  }

  public validateAccessCodeByEmail(email: string, authToken:string): Promise<any> {
    const url = `${this.env.getAppServerUrl()}/user/validate-access-code`;

    const options = {
      headers: this._getCampxHeader()
    }

    const body = {
      email: email,
      auth_token: authToken
    };
    return this.http.post<any>(url, body, options).toPromise();
  }

  public completeSignup(params: any): Promise<any> {
    const url = `${this.env.getAppServerUrl()}/v2/user`;

    const options = {
      headers: this._getCampxHeader()
    }

    const body = {
      type: 'internal',
      phone_number: '+1' + params.phoneNumber,
      email: params.email,
      reg_token: params.regToken,
    };
    return this.http.post<any>(url, body, options).toPromise();
  }

  public updateName(name: string): Promise<any> {
    const url = `${this.env.getAppServerUrl()}/user`;

  
    const options = {
      headers: this._getCampxHeader()
    }

    const body = {
      full_name: name
    }

    return this.http.put<any>(url, body, options).toPromise();
  }

  public updateNameOnLucova(name: string): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user`;

    const options = {
      headers: this._getHeader()
    }

    const body = {
      first_name: name
    };

    return this.http.put<any>(url, body, options).toPromise();
  }

  public getUser(): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user`;
    const options = {
      headers: this._getHeader()
    };
    return this.http.get<any>(url, options).toPromise();
  }

  public getPaymentMethod(): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/credit-cards`;
    const options = {
      headers: this._getHeader()
    };

    return this.http.get<any>(url, options).toPromise();
  }

  public savePaymentMethod(card: any): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/credit-cards`;
    const options = {
      headers: this._getHeader()
    };

    // send browser_data by default
    card.browser_data = this.env.getBrowserData();

    return this.http.post<any>(url, card, options).toPromise();
  }

  public setDefaultPaymentMethod(card: any): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/credit-cards/${card.id}`;
    const options = {
      headers: this._getHeader()
    };
    const body = {
      set_default: true
    };

    return this.http.put<any>(url, body, options).toPromise();
  }

  public deletePaymentMethod(card: any, isGiftCard: boolean): Promise<any> {
    // credit/debit cards uses id, gift cards uses lucovaId
    let id = isGiftCard ? card.lucovaId : card.id;
    const url = `${this.env.getLucovaGatewayHost()}/user/credit-cards/${id}`;
    const options = {
      headers: this._getHeader()
    };

    return this.http.delete<any>(url, options).toPromise();
  }

  public updatePreorderWithEmail(emailAddress: string, preorderId: string): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/preorder`;
    const options = {
      headers: this._getHeader()
    };
    const body = {
      preorder_id: preorderId,
      email: emailAddress
    };

    return this.http.put<any>(url, body, options).toPromise();
  }

  // if unable to send a preorder with gift card as payment method, it's possible that the nown_gift gateway credentials may be incorrect
  // e.g. if staging has production credentials
  // https://github.com/Lucovainc/lucova-payment-server/issues/736
  public sendPreorder(params: LucovaPreorder): Promise<any> {
    // params = { newCart, cardInfo, tipCents, targetTimestamp, redeemGiftCard };
    // cardInfo and targetTimestamp may be undefined
    const url = `${this.env.getLucovaGatewayHost()}/user/202004/preorder`;
    const options = {
      headers: this._getHeader()
    };
    const body = {
      preorder_source: PreorderSource.WEB_ORDER,
      items: params.items,
      tip_cents: params.tip_cents,
      mock: params.mock,
      card_info: undefined,
      target_timestamp: undefined,
      redeem_gift_card: params.redeem_gift_card,
      is_3ds_auth_completed: params.is_3ds_auth_completed || false,
      browser_data: this.env.getBrowserData()
    };

    if (params.card_info) {
      body.card_info = params.card_info;
    }

    if (params.target_timestamp) {
      body.target_timestamp = params.target_timestamp;
    }

    return this.http.post<any>(url, body, options).toPromise();
  }

  public cancelPreorder(preorderId: string): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/preorder`;
    const options = {
      headers: this._getHeader()
    };
    const body = {
      preorder_id: preorderId,
      status: 'cancelled',
    };

    return this.http.put<any>(url, body, options).toPromise();
  }

  public getPreorderStatus(): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/preorder`;
    const options = {
      headers: this._getHeader()
    };
    return this.http.get<any>(url, options).toPromise();
  }

  public getLegalpage(): Promise<any> {
    const url = `${this.env.getAppServerUrl()}/info`;

    const options = {
      headers: {
        Accept: 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(url, options).toPromise();
  }

  public getGiftCardBalances(): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/202008/gift-card-balances`;
    const options = {
      headers: this._getHeader()
    };

    return this.http.get<any>(url, options).toPromise();
  }

  public sendGiftCard(giftCard: any, cardInfo: any = null): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/e-gift-card`;
    const options = {
      headers: this._getHeader()
    };

    const body = {
      top_node_id: giftCard.top_node_id,
      node_id: giftCard.node_id,
      receipt_email: giftCard.receipt_email,
      recipient_email: giftCard.recipient_email,
      recipient_name: giftCard.recipient_name,
      ecard_image_url: giftCard.ecard_image_url,
      currency_code: giftCard.currency_code,
      amount_cents: giftCard.amount_cents,
      transaction_uuid: giftCard.transaction_uuid,
      personal_message: giftCard.personal_message,
      card_info: undefined
    };

    if (cardInfo) {
      body.card_info = cardInfo;
    }

    return this.http.post<any>(url, body, options).toPromise();
  }

  // register physical gift card
  // if unable to add a gift card, it's possible that the nown_gift gateway credentials may be incorrect
  // e.g. if staging has production credentials
  // https://github.com/Lucovainc/lucova-payment-server/issues/736
  public registerGiftCard(cardNumber: string, pin: string): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/card`;
    const options = {
      headers: this._getHeader()
    }

    const body = {
      card_brand: 'nown_gift',
      card_number: cardNumber,
      card_number_namespace: 'nown',
      pin: pin
    };

    return this.http.post<any>(url, body, options).toPromise();
  }

  // step 1 to claim digital gift cards sent to the provided email
  public requestEGiftCardAuthCode(email: string): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/email-verification`;
    const body = {
      email: email
    };
    const options = {
      headers: this._getHeader()
    };
    return this.http.post<any>(url, body, options).toPromise();
  }

  // step 2 to claim digital gift cards sent to the provided email
  public getClaimableEGiftCards(email: string, code: string, topNodeId: string): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/e-gift-card`;

    const options = {
      params: {
        email: email,
        validation_code: code,
        top_node_id: topNodeId
      },
      headers: this._getHeader()
    };

    return this.http.get<any>(url, options).toPromise();
  }

  // claiming a specific digital gift card via gift card id.
  public claimEGiftCard(eGiftCardId: string): Promise<any> {
    const url = `${this.env.getLucovaGatewayHost()}/user/e-gift-card/claim`;

    const body = {
      id: eGiftCardId
    };
    const options = {
      headers: this._getHeader()
    };
    return this.http.post<any>(url, body, options).toPromise();
  }
}
