<app-loading-screen *ngIf="loadingLocations"></app-loading-screen>

<!-- Organization Image -->
<div *ngIf="brand.image_url" class="location--header-image" 
[ngStyle]="brand.image_url ? {'background-image': 'url(' + brand.image_url + ')'} : {'background-image': 'none'}"></div>
<div *ngIf="!brand.image_url" class="location--header-no-image"></div>

<div class="full-vw">
    <div class="locations-list-body global-max-width">

        <!-- Organization Logo -->
        <div *ngIf="brand.logo_url" class="logo-section">
            <div class="logo-container">
                <img [src]="brand.logo_url">
            </div>
        </div>

        <div class="locations-list-header">
            <div class="button-container-row">
                <div class="button-container-left" *ngIf="!isStoreFront">
                    <span class="nown-icon icon-Arrow-Small-Left left-arrow-icon-white" (click)="back()"></span>
                    <div class="white-button-212 large-back-button" (click)="back()">
                        <span class="nown-icon icon-Arrow-Small-Left left-arrow-icon"></span>
                        <div>{{ 'locationList.switchBrands' | translate }}</div>
                    </div>
                </div>
                <select-language></select-language>
            </div>
        </div>

        <div class="location--body">
            <!-- Everything else that appears to float on the page -->
            
            <div class="float--container">
                <!-- Location Details -->
                <div class="location--details" [ngClass]="{'location--details-logo':brand.logo_url, 'location--details-no-logo': !brand.logo_url}">
                    <div [ngClass]="{'location--details-title': !brand.logo_url}">
                        <div class="text-16-light">{{ 'locationsList.welcomeHeader.title' | translate }}</div>
                        <div class="brand-name">{{ brand.node_name }}</div>
                    </div>
                    <select-language></select-language>
                </div>
                <div class="row-vertical-layout" 
                *ngIf="brand.digital_gift_card_settings && brand.digital_gift_card_settings.enabled && brand.digital_gift_card_settings.images.length">
                    <div class="heading">{{ 'locationList.sendAGiftCard' | translate }}</div>
                </div>
                <div class="vertical-spacing-12" 
                *ngIf="brand.digital_gift_card_settings && brand.digital_gift_card_settings.enabled && brand.digital_gift_card_settings.images.length"></div>

                <div class="scrollable-items-container reduced-height"
                *ngIf="brand.digital_gift_card_settings && brand.digital_gift_card_settings.enabled && brand.digital_gift_card_settings.images.length">
                    <div id="giftCardsList" class="scrollable-items reduced-height" (scroll)="onScroll($event)">
                        <div class="fade-left">
                            <div class="left-arrow" (click)="slide('giftCardsList', 'left', 218)">
                                <span class="nown-icon icon-Arrow-Large-Left"></span>
                            </div>
                        </div>
                        <div class="fade-right">
                            <div class="right-arrow" (click)="slide('giftCardsList', 'right', 218)">
                                <span class="nown-icon icon-Arrow-Large-Right"></span>
                            </div>
                        </div>
                        <div class="item-tiles-container">
                            <div *ngFor="let giftCard of brand.digital_gift_card_settings.images" (click)="loadGiftCard(giftCard)">
                                <app-tile-gift-card [brandLogo]="brand.logo_url" [img]="giftCard.url"></app-tile-gift-card>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="vertical-spacing-24" 
                *ngIf="brand.digital_gift_card_settings && brand.digital_gift_card_settings.enabled && brand.digital_gift_card_settings.images.length"></div>

                <div class="row-vertical-layout">
                    <div class="heading">{{ 'locationList.locations' | translate }}</div>
                </div>

                <div class="vertical-spacing-24"></div>

                <div class="map-and-location-tiles-container">
                    <div class="locations-container">
                        <div>
                            <div>{{ 'locationList.enterYourAddress' | translate }}</div>
                            <div class="vertical-spacing-12"></div>
                            <input #search (keydown.enter)="$event.preventDefault()" placeholder="{{ 'placeHolder.searchNearestLocation' | translate }}" autocorrect="off" autocapitalize="off" spellcheck="off" class="text-field-2" style="width: 100%;">
                        </div>

                        <div class="vertical-spacing-24"></div>

                        <div class="float-item-fit--no-padding location-tile" *ngFor="let location of nearbyLocations" [attr.id]="location.node_id">
                            <div class="location-content">
                                <div class="image-container"
                                [ngStyle]="location.image_url ? {'background-image': 'url(' + location.image_url + ')'} : {'background-image': 'none'}"></div>
                                <div class="location-body">
                                    <div class="location-info">
                                        <div>{{ location.node_name }}</div>
                                        <div class="location-open-hours" *ngIf="location.is_open">{{ location.open_hours_str }}</div>
                                        <div class="location-open-hours" *ngIf="!location.is_open">{{ 'locationList.currentlyClosed' | translate }}</div>
                                    </div>
                                    <div class="grey-button" *ngIf="location.is_open" (click)="selectLocation(location)">{{ 'locationList.orderHere' | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="locations-map-container">
                        <agm-map
                            #AgmMap
                            [latitude]="myLocation.latitude"
                            [longitude]="myLocation.longitude"
                            [mapDraggable]="true"
                            [showDefaultInfoWindow]="false"
                            [streetViewControl]="false"
                            [zoom]="15"
                            [maxZoom]="15">
                            <agm-marker
                                *ngFor="let location of nearbyLocations"
                                [latitude]="location.latitude"
                                [longitude]="location.longitude"
                                [markerDraggable]="false"
                                [title]="location.node_name"
                                (markerClick)="markerClicked(location)"></agm-marker>
                        </agm-map>
                    </div>
                </div>
                <div class="vertical-spacing-48"></div>
                <a href="http://www.nownpos.com" class="powered-by-nown-container">
                    <img class="powered-by-nown" src="assets/images/powered_by_nown.png"/>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="rounded-background"></div>