import { Injectable } from '@angular/core';
import { LucovaGatewayService } from '../lucova-gateway/lucova-gateway.service';
import { OrderTrackingService } from '../order-tracking/order-tracking.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private authenticated = false;
  private savedUser: any;

  constructor(private gateway: LucovaGatewayService, private orderTrackingService: OrderTrackingService) {}

  public isAuthenticated(): boolean {
    return this.authenticated;
  }

  public setAuthenticated(authenticated: boolean): void {
    this.authenticated = authenticated;
  }

  public checkIfAuthenticated(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.gateway.getUser()
      .then((res) => {
        if (res.success) {
          this.setAuthenticated(true);
          this.savedUser = res.data;
          this.autoLogout();
        } else {
          this.authenticated = false;
        }
        resolve(res);
      }).catch((error) => {
        console.log(error);
        this.authenticated = false;
        reject(error);
      });
    });
  }

  public getUser() : any {
    return this.savedUser;
  };

  public autoLogout(minutes: number = 30): void {
    setTimeout(() => {
      if (this.orderTrackingService.wasOrderRecentlyCompleted() || this.orderTrackingService.isOrderInProgress()) {
        return this.autoLogout();
      }
      localStorage.clear();
      this.checkIfAuthenticated();
    }, 60000 * minutes);
  }
}

