import { BrowserData } from "./client-browser-related.model";

export interface PreorderStatus {
    status: string;
    userFriendlyStatus: string;
    progressIndicatorValue: number;
}

export const Status = {
  PENDING: 'pending',
  PRINT_PENDING: 'print_pending',
  ACKNOWLEDGED: 'acknowledged',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
};

export const PreorderSource = {
  WEB_ORDER: 'web_order'
};

export interface LucovaPreorder {
  preorder_source?: string,
  items: any,
  tip_cents: number,
  mock: boolean,
  card_info?: any,
  target_timestamp: number,
  redeem_gift_card: boolean,
  is_3ds_auth_completed?: boolean,
  browser_data?: BrowserData
};
