<div class="signup-header">
    <div class="full-vw">
        <div class="global-max-width signup-header-content">
            <div class="left-arrow-icon nown-icon icon-Arrow-Small-Left" (click)="close()"></div>
            <div class="heading">{{ 'signUp.checkout' | translate }}</div>
        </div>
    </div>
</div>

<div class="sign-up-content">
    <div class="float-item-fit" *ngIf="!isPhoneValidated">
        <!-- step 1: log in / sign up via phone number & validation code -->
        <div class="sign-up-details" *ngIf="!isAuthCodeSent && !isNewUser">
            <div>{{ 'signUp.phoneNumber' | translate }}</div>
            <div class="vertical-spacing-12"></div>
            <input type="text" class="text-field-1" inputmode="numeric" pattern="[0-9]*" [(ngModel)]="phoneNumber" (keydown)="$event.preventDefault()" (keyup)="inputNumbersOnly($event, 10, 'phone-number')">
            <div class="vertical-spacing-12"></div>
            <div class="note">{{ 'signUp.varificationCodeDesc' | translate }}</div>
            <div class="vertical-spacing-12"></div>
            <div class="yellow-button" (click)="requestAccessCodeByPhone()">{{ 'signUp.continue' | translate }}</div>
        </div>
        <div class="sign-up-details" *ngIf="isAuthCodeSent && !isNewUser">
            <div>{{ 'signUp.verificationCode' | translate }}</div>
            <div class="vertical-spacing-12"></div>
            <input type="text" class="text-field-1" inputmode="numeric" pattern="[0-9]*" [(ngModel)]="pin" (keydown)="$event.preventDefault()" (keyup)="inputNumbersOnly($event, 6, 'pin')">
            <div class="vertical-spacing-12"></div>
            <div class="note">{{ 'signUp.enterCode' | translate }} {{ phoneNumber }}.</div>
            <div class="vertical-spacing-12"></div>
            <div class="yellow-button" (click)="validateAccessCodeByPhone()">{{ 'signUp.continue' | translate }}</div>
        </div>
        <!-- step 2: if new user, finish signing up with email & name -->
        <div class="personal-information" *ngIf="isAuthCodeSent && isNewUser">
            <div class="heading">{{ 'signUp.yourInformation' | translate }}</div>
            <div class="vertical-spacing-12"></div>
            <!-- name -->
            <div class="personal-information-label">{{ 'signUp.name' | translate }}</div>
            <input type="text" class="text-field-2" style="width: 312px" [(ngModel)]="name">
            <div class="vertical-spacing-12"></div>
            <!-- email -->
            <div class="personal-information-label">{{ 'signUp.email' | translate }}</div>
            <input class="text-field-2" style="width: 312px" type="text"
            (keyup)="checkEmailInput(email)"
            [ngClass]="{'invalid-input': !emailValidation}"
            [(ngModel)]="email">
            <div class="vertical-spacing-12"></div>
            <div *ngIf="!emailValidation" style="color: #FF0000; font-size: 12px;" class="required">
                {{ 'signUp.invalidEmailFormat' | translate }}
            </div>
            <div class="vertical-spacing-12"></div>
            <div class="yellow-button" (click)="completeSignup()">
                {{ 'signUp.continue' | translate }}
            </div>
        </div>
    </div>
    <a href="http://www.nownpos.com" class="powered-by-nown-container">
        <img class="powered-by-nown" src="assets/images/powered_by_nown.png"/>
    </a>
</div>
