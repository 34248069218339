<div class="gift-card-options-container">
    <div class="gift-card-container-header">
        <div class="back-btn-icon">
            <span class="nown-icon icon-Exit-Large cross-icon" (click)="close(false)"></span>
            <span class="nown-icon icon-Arrow-Large-Left left-arrow-icon" (click)="close(false)"></span>
        </div>
    <div class="gift-card-name">{{ 'giftCard.giftCards' | translate }}</div>
    </div>
    <div class="gift-card-details-container">
        <img src="{{ giftCardImage }}" alt="" class="gift-card-image-container">
    </div>
    <div class="gift-card-option-tabs-container">
        <div class="option-tab" id="1-tab"
        (click)="selectOption(1)">
            <div>{{ 'giftCard.about' | translate }}</div>
        </div>
        <div class="option-tab" id="2-tab"
        (click)="selectOption(2)">
            <div>{{ 'giftCard.to' | translate }}:</div>
        </div>
        <div class="option-tab" id="3-tab"
        (click)="selectOption(3)">
            <div>{{ 'giftCard.message' | translate }}</div>
        </div>
        <div class="option-tab" id="4-tab"
        (click)="selectOption(4)">
            <div>{{ 'giftCard.amount' | translate }}</div>
        </div>
        <div id="optionSelector" class="option-selector-underline"></div>
    </div>
</div>

<div class="option-container"
[style.height]="'calc(100vh - 267px - 124px)'"
(scroll)="onOptionScroll($event)">
    <div style="min-width: 16px;"></div>
    <div  id="1" class="option-tile-container" 
    (click)="selectOption(1)">
        <div style="width: 100%;">
            <div class="vertical-spacing-12"></div>
            <div class="option-tile min-height-tile">
                <div class="option-name">
                    <div>{{ 'giftCard.lookingForPerfectGift' | translate }}</div>
                </div>
                <div class="vertical-spacing-12"></div>
                <div class="option-description">
                    {{ 'giftCard.sendGiftCardDesc' | translate }}
                    <br><br>
                    {{ 'giftCard.sendGiftCardDesc2' | translate }} {{ brand.node_name }} {{ 'giftCard.location' | translate }}.
                </div>
            </div>
            <div class="vertical-spacing-12"></div>
        </div>
        <div style="min-width: 16px;"></div>
    </div>
    <div  id="2" class="option-tile-container" 
    (click)="selectOption(2)">
        <div style="width: 100%; padding-bottom: 60px; height: 600px;">
            <div class="vertical-spacing-12"></div>
            <div class="option-tile min-height-tile">
                <div class="option-name">
                    <div>{{ 'giftCard.to' | translate }}:</div>
                </div>
                <div class="required">
                    *{{ 'giftCard.required' | translate }}
                </div>
                <div class="vertical-spacing-12"></div>
                <div class="contact-container">
                    <div class="contact-name">
                        <div class="contact-name-label">
                            {{ 'giftCard.recipientName' | translate }}
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <input [(ngModel)]="recipient.name" type="" class="input-box contact-name-input">
                    </div>
                    <div class="vertical-spacing-12"></div>
                    <div class="contact-email">
                        <div class="contact-email-label">
                            {{ 'giftCard.email' | translate }}
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <div class="contact-email-input-container">
                            <input
                            (keyup)="checkEmailInput(recipient.email)"
                            [ngClass]="{'invalid-input': !emailValidation}"
                            [(ngModel)]="recipient.email" 
                            class="input-box contact-email-input">
                        </div>
                        <div class="vertical-spacing-12"></div>
                        <div *ngIf="!emailValidation" class="required">
                            {{ 'giftCard.invalidEmailFormat' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="vertical-spacing-12"></div>
        </div>
        <div style="min-width: 16px;"></div>
    </div>
    <div  id="3" class="option-tile-container" 
    (click)="selectOption(3)">
        <div style="width: 100%; padding-bottom: 60px;"
        [style.height]="selectedCustomMessage ? '680px' : '600px'">
            <div class="vertical-spacing-12"></div>
            <div class="option-tile">
                <div class="option-name">
                    <div>{{ 'giftCard.whatWouldYouLikeToSay' | translate }}</div>
                </div>
                <div class="vertical-spacing-12"></div>
                <div class="gift-card-messages-container">
                    <div class="input-box gift-card-message"
                    (click)="selectMessage(i)"
                    *ngFor="let message of messages; let i = index" 
                    [attr.data-index]="i"
                    [ngClass]="{'selected-message': messages[i].selected}">
                        {{ message.message }}
                    </div>
                    <div class="input-box gift-card-message"
                    (click)="selectCustomMessage()"
                    [ngClass]="{'selected-message': selectedCustomMessage}">
                    {{ 'giftCard.customMessage' | translate }}
                    </div>
                </div>
            </div>
            <div class="vertical-spacing-12"></div>
            <div class="option-tile min-height-tile"
            id="customMessageTile"
            *ngIf="selectedCustomMessage">
                <div class="option-name">{{ 'giftCard.customMessage' | translate }}:</div>
                <div class="vertical-spacing-12"></div>
                <input [(ngModel)]="selectedMessage" type="text" class="input-box gift-card-message-input">
                <div class="vertical-spacing-6"></div>
            </div>
            <div style="height: 165px"></div>
        </div>
        <div style="min-width: 16px;"></div>
    </div>
    <div  id="4" class="option-tile-container" 
    (click)="selectOption(4)">
        <div style="width: 100%;"
        [style.height]="showCustomAmount ? '860px' : '680px'">
            <div class="option-tile min-height-tile"
            *ngIf="currencies.length > 1">
                <div class="option-name">
                    <div>{{ 'giftCard.whatCurrency' | translate }}</div>
                </div>
                <div class="required">
                    *{{ 'giftCard.required' | translate }}
                </div>
                <div class="vertical-spacing-12"></div>
                <div class="currency-container">
                    <div class="currency"
                    *ngFor="let currency of currencies"
                    (click)="selectCurrency(currency)"
                    [ngClass]="{'selected-currency': currency.selected}">
                        {{ currency.currencyCode }}
                    </div>
                </div>
            </div>
            <div class="vertical-spacing-12" *ngIf="currencies.length > 1"></div>
            <div *ngIf="selectedCurrency && selectedCurrency.discount.discountPercentage > 0" class="option-tile min-height-tile">
                {{ buildDiscountString(selectedCurrency.discount) }}
            </div>
            <div *ngIf="selectedCurrency" class="vertical-spacing-12"></div>
            <div *ngIf="selectedCurrency" class="option-tile min-height-tile">                
                <div class="option-name">
                    {{ 'giftCard.chooseAmount' | translate }}:
                </div>
                <div class="required">
                    *{{ 'giftCard.required' | translate }}
                </div>
                <div class="vertical-spacing-12"></div>
                <div class="amounts-container">
                    <div class="suggested-amount"
                    *ngFor="let suggestedAmount of selectedCurrency.suggestedDenominationsCents"
                    (click)="selectAmount(suggestedAmount)"
                    [ngClass]="{'selected-amount': selectedAmount === suggestedAmount && !showCustomAmount}">
                        ${{ (suggestedAmount / 100).toFixed(2) }}
                        <div class="discounted-price"
                        *ngIf="suggestedAmount >= selectedCurrency.discount.minAmountCents && suggestedAmount <= selectedCurrency.discount.maxAmountCents">
                            {{ buildDiscountedPriceString(suggestedAmount, selectedCurrency.discount.discountPercentage) }}
                            <div class="discounted-indicator"></div>
                        </div>
                    </div>
                    <div class="custom-amount"
                    (click)="selectAmount('custom')"
                    [ngClass]="{'selected-amount': showCustomAmount}">
                    {{ 'giftCard.customAmount' | translate }}
                    </div>
                </div>
            </div>
            <div class="vertical-spacing-12"></div>
            <div class="option-tile min-height-tile"
            id="customAmountTile"
            *ngIf="showCustomAmount">
                <div class="option-name">{{ 'giftCard.customAmount' | translate }}:</div>
                <div class="option-description">
                    {{ buildLimitString(selectedCurrency) }}
                </div>
                <div class="vertical-spacing-12"></div>
                <input [(ngModel)]="selectedAmount" 
                [ngClass]="{'invalid-input': !amountValidation}"
                type="number" class="input-box gift-card-message-input">
                <div *ngIf="!amountValidation" class="required">
                    {{ 'giftCard.amountNotWithinRange' | translate }}
                </div>
                <div class="vertical-spacing-6"></div>
            </div>

        </div>
        <div style="min-width: 16px;"></div>
    </div>
</div>

<div class="continue-container">
    <div class="continue-body">
        <div class="reminder-text" 
        [ngClass]="{'visibility-shown': selectedOptionId === 4 && (!selectedAmount || !recipient.name || !recipient.email)}">
        {{ 'giftCard.recipientInformationRequired' | translate }}
        </div>
        <div class="yellow-button-container">
            <button 
            [disabled]="isRequiredFieldsFilled()"
            class="yellow-button" (click)="nextPage()">
                {{ buttonText }}
            </button>
        </div>
    </div>
</div>