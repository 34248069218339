import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.less']
})
export class ConfirmComponent implements OnInit {
  public onClose: Subject<any> = new Subject();
  title: string;
  description: string;
  confirmCallback: any;
  cancelCallback?: any;

  constructor(public modalRef: BsModalRef) {
  }

  cancel() {
    this.modalRef.hide();
    this.onClose.next();
    if (this.cancelCallback) {
      this.cancelCallback();
    }
  }

  confirm() {
    this.confirmCallback();
    this.modalRef.hide();
    this.onClose.next();
  }

  ngOnInit(): void {
  }
}