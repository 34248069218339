import { Component, OnInit, OnDestroy } from '@angular/core';
import { LucovaGatewayService } from 'src/app/services/lucova-gateway/lucova-gateway.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { Subject } from 'rxjs';
import { OrderTrackingService } from 'src/app/services/order-tracking/order-tracking.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less']
})
export class SignupComponent implements OnInit, OnDestroy {
  name: string;
  phoneNumber = '';
  email: string;
  emailValidation = true;
  emailValidationTimer = 0;

  pin = '';
  isAuthCodeSent = false;
  isPhoneValidated = false;
  isSignupCompleted = false;

  isNewUser = false;

  public onClose: Subject<any> = new Subject();

  constructor(private modalRef: BsModalRef,
              private gateway: LucovaGatewayService,
              private authenticationService: AuthenticationService,
              private orderTrackingService: OrderTrackingService,
              public alertService: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  inputNumbersOnly(e: any, maxLength: number, field: string): void {
    e.preventDefault();
    if ((e.key < '0' || e.key > '9') && e.key !== 'Backspace' && e.keyCode !== 13) {
      return;
    }

    switch (field) {
      case 'phone-number':
        if (this.phoneNumber.length >= maxLength && e.key !== 'Backspace' && e.keyCode !== 13) {
          return;
        }
        if (e.keyCode === 13) {
          this.requestAccessCodeByPhone();
          return;
        }
        if (e.key === 'Backspace') {
          this.phoneNumber = this.phoneNumber.slice(0, -1);
        } else {
          this.phoneNumber += e.key;
        }
        break;
      case 'pin':
        if (this.pin.length >= maxLength && e.key !== 'Backspace' && e.keyCode !== 13) {
          return;
        }
        if (e.keyCode === 13) {
          this.validateAccessCodeByPhone();
          return;
        }
        if (e.key === 'Backspace') {
          this.pin = this.pin.slice(0, -1);
        } else {
          this.pin += e.key;
        }
        break;
      }
  }

  /*
    *** Commented By Chris, December 3rd 2020 ***
    Signup workflow via campx
    1. request an access code by phone number through '/request-access-code'
    2. receive the access code via text message
    3. validate the access code through '/validate-access-code'
    4. if successful, /validate-access-code should provide a reg_token
    5. send type, phone_number, email and reg_token to '/v2/user' to complete sign up
    6. update the user's name

    Login workflow via campx
    1. request an access code by phone number through '/request-access-code'
    2. receive the access code via text message
    3. validate the access code through '/validate-access-code'
    4. if successful, /validate-access-code should provide details on the user, including user_name and auth_token
    5. save the required info
  */

  requestAccessCodeByPhone(): void {
    this.gateway.requestAccessCodeByPhone(this.phoneNumber)
    .then((res) => {
      if (res.success) {
        this.isAuthCodeSent = true;
      } else if (!res.success) {
        this.isAuthCodeSent = false;
        this.alertService.showError(
          this.translate.instant('ts.errorSubmittingYourPhoneNumber'),
          this.translate.instant('ts.pleaseTryAgain')
        );
      }
    }).catch((error) => {
      console.log(error);
      this.isAuthCodeSent = false;
      this.alertService.showError(
        this.translate.instant('ts.errorSubmittingYourPhoneNumber'),
        this.translate.instant('ts.pleaseTryAgain')
      );
    });
  }

  validateAccessCodeByPhone(): void {
    this.gateway.validateAccessCodeByPhone(this.phoneNumber, this.pin)
    .then((res) => {
      if (res.success) {
        this.onValidateSuccess(res);
      } else {
        this.authenticationService.setAuthenticated(false);
        this.alertService.showError(
          this.translate.instant('ts.errorValidatingYourCode'),
          this.translate.instant('ts.pleaseTryAgain')
        );  
      }
    }).catch((error) => {
      console.log(error);
      this.authenticationService.setAuthenticated(false);
      this.alertService.showError(
        this.translate.instant('ts.errorValidatingYourCode'),
        this.translate.instant('ts.pleaseTryAgain')
      );
    });
  }

  validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // email validation
  checkEmailInput(email: string): any {
    this.emailValidationTimer = 0;
    setTimeout(() => {
      if (!this.validateEmail(email)) {
          this.emailValidation = false;
      } else {
            this.emailValidation = true;
      }
    }, 1000);
  };

  // complete signup process through campx
  completeSignup(): void {
    let params = {
      email: this.email,
      phoneNumber: this.phoneNumber,
      regToken: localStorage.getItem('reg_token')
    }

    this.gateway.completeSignup(params)
    .then((res) => {
      if (res.success) {
        this.onSignupCompleted(res);
      } else {
        this.authenticationService.setAuthenticated(false);
        this.alertService.showError(
          this.translate.instant('ts.errorSigningUp'),
          this.translate.instant('ts.pleaseTryAgain')
        );
      }
    });
  };

  onValidateSuccess(res: any): void {
    // new user sign ups will always return a reg_token
    if (res.reg_token) {
      this.isNewUser = true;
      localStorage.setItem('reg_token', res.reg_token);
      localStorage.setItem('phone_number', this.phoneNumber);
      return;
    }

    // existing users will have a lucova_user_name
    if (res.lucova_user_name) {
      localStorage.setItem('auth_token', res.auth_token);
      localStorage.setItem('lucova_auth_token', res.lucova_auth_token);
      localStorage.setItem('user_name', res.lucova_user_name);
      localStorage.setItem('phone_number', res.phone_number);
      localStorage.setItem('email', res.email);
    }

    this.orderTrackingService.setupSocketConnection();
    this.authenticationService.checkIfAuthenticated()
    .then((res) => {
      this.isPhoneValidated = true;
      // close signup/login & send back a user object if exists.
      this.close();
      if (res.success) {
        this.onClose.next(res.data);
      } else {
        this.alertService.showError(
          this.translate.instant('ts.invalidVerificationCode'),
          this.translate.instant('ts.pleaseTryAgain')
        );
      }
    });
  }

  onSignupCompleted(res: any): void {
    // layer of check to ensure signup has been completed 
    if (res.lucova_user_name) {
      localStorage.setItem('auth_token', res.auth_token);
      localStorage.setItem('lucova_auth_token', res.lucova_auth_token);
      localStorage.setItem('user_name', res.lucova_user_name);
      localStorage.setItem('phone_number', res.phone_number);
      localStorage.setItem('email', res.email);

      this.orderTrackingService.setupSocketConnection();
      this.updateName();
    } else {
      this.alertService.showError(
        this.translate.instant('ts.errorSigningUp'),
        this.translate.instant('ts.pleaseTryAgain')
      );
    }
  }

  // update name on both lucova and campx.
  updateName(): void {
    this.gateway.updateName(this.name)
    .then((res) => {
      if (res.success) {
        this.gateway.updateNameOnLucova(this.name).then(() => {
          this.getUser();
        });
      }
    });
  }

  // gets user and sends data back to wherever signup modal is called.
  getUser(): void {
    this.gateway.getUser().then((res) => {
      if (res.success) {
        this.close();
        this.onClose.next(res.data);
      }
    });
  }

  close(): void {
    this.modalRef.hide();
  }
}
